nav.side-menu {
    background-color: #263238;
}
.side-menu {
    width: 240px;
    position: fixed;
    top: 0;
    left: 0;
    background: #fff;
    height: 100%;
    overflow: auto;
    z-index: 60;
    padding: 90px 0 110px;
}

.side-menu {
    padding-top: 60px;
}
.nav-list>li>a {
    color: #c4c4c4;
    font-size: 14px;
    border-bottom: 1px solid #585858;
    background-color: #374248;
}
.nav-list>li>a:hover {
    color: #fff;
    font-weight: 600;
}
.nav-menu {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}
.nav-menu>li {
    position: relative;
    display: block;
}
#column_left {
	background-color: #263238;
}

.nav-list li a {
	text-decoration: none;
	display: block;
	padding: 10px 0px;
	cursor: pointer;
	/* border-bottom: 1px solid #515151 !important; */
	color: #9d9d9d;
}

.nav-list > li > a {
	color: #C4C4C4;
	font-size: 14px;
    border: 1px solid #585858;
    background-color: #374248;
}

.nav-list > li > a:hover{
    background-color: #444444;
    color: #eee;
    font-weight: 700;
    border-left: 6px solid #677379;
}
.nav-list > li.active > a{
    background-color: #6f6f6f;
    color: #fff;
    font-weight: 600;
    border-left: 6px solid #256586;
}
.nav-list > li.active{
    background-color: #444444;
}
.side-menu-nav-list ul a{
    padding-left: 10px !important;
    font-size: 13px;
}
.side-menu-nav-list ul li ul li a{
    padding-left: 10px !important;
    background-color: #252525;
    font-size: 12px !important;
    border-left: 6px solid #252525;
}
.side-menu .fa{
    text-align: center;
    width: 1.25em;
}
.side-menu .fa:hover{
    color: #fff;
    font-weight: 700;
}
.side-menu i{
    font-size: .85rem;
    margin-right: .25rem;
}
.site-header .user-menu.dropdown .dropdown-toggle:after {
    border-top: 5px solid;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}
.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-right: .25rem;
    margin-left: .25rem;
    vertical-align: middle;
    content: "";
    border-top: 5px solid;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    position: relative;
    top: -1px;
}
.pull-right {
    float: right!important;
}
.nav-list>li>a {
    color: #c4c4c4;
    font-size: 14px;
    border: 1px solid #585858;
    background-color: #374e5a;
    border-left: 6px solid #374e5a;
}
.nav-list>li>ul>li>a {
    color: #c4c4c4;
    font-size: 13px;
    border: 1px solid #585858;
    background-color: #353b3e;
    border-left: 6px solid #353b3e;
}
.nav-list>li>ul>li>a>i {
    font-size: 0.65rem;
    margin-right: .25rem;
}
#column_left ul>li>ul{
    padding-left: 6px;
    background-color: #374e5a;
}
#column_left ul>li>ul>li>ul{
    background-color: #353b3e;
}
