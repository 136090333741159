@charset "UTF-8";
.label {
    display: inline-block;
    padding: .25em .4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    -webkit-border-radius: .25rem;
    border-radius: .25rem
}

.label:empty {
    display: none
}

.btn .label {
    position: relative;
    top: -1px
}

a.label:focus,
a.label:hover {
    color: #fff;
    text-decoration: none;
    cursor: pointer
}

.label-pill {
    padding-right: .6em;
    padding-left: .6em;
    -webkit-border-radius: 10rem;
    border-radius: 10rem
}

.label-default {
    background-color: #818a91
}

.label-default[href]:focus,
.label-default[href]:hover {
    background-color: #687077
}

.label-primary {
    background-color: #0275d8
}

.label-primary[href]:focus,
.label-primary[href]:hover {
    background-color: #025aa5
}

.label-success {
    background-color: #5cb85c
}

.label-success[href]:focus,
.label-success[href]:hover {
    background-color: #449d44
}

.label-info {
    background-color: #5bc0de
}

.label-info[href]:focus,
.label-info[href]:hover {
    background-color: #31b0d5
}

.label-warning {
    background-color: #f0ad4e
}

.label-warning[href]:focus,
.label-warning[href]:hover {
    background-color: #ec971f
}

.label-danger {
    background-color: #d9534f
}

.label-danger[href]:focus,
.label-danger[href]:hover {
    background-color: #c9302c
}
.assigned
{
 background-color:#f36e07 !important;
 padding:5px 6px;
  border-bottom: none !important;
  margin-top:5px;
}
.assigned:focus, .assigned:hover
{
 background-color:#d86003 !important;
}
.cancel
{
 background-color:#fa424a !important;
 padding:5px 6px;
border-bottom: none !important;
  margin-top:5px;
}
.cancel:focus, .cancel:hover
{
 background-color:#e33038 !important;
}
.unassigned
{
 background-color:#3084fa !important;
 padding:5px 6px;
  border-bottom: none !important;
  margin-top:5px;
}

.unassigned:focus, .unassigned:hover
{
 background-color:#2071e2 !important;
}
.greenn
{
 padding:5px 6px;
border-bottom: none !important;
  margin-top:5px;
}
.requested
{
 background-color: #cb0000 !important;
 padding:5px 6px;
  border-bottom: none !important;
  margin-top:5px;
}
 .requested:focus, .requested:hover
{
 background-color:#ad1414 !important;
}
.enroute
{
 background-color: #008000 !important;
 padding:5px 6px;
  border-bottom: none !important;
  margin-top:5px;
}
 .enroute:focus, .enroute:hover
{
 background-color:#186d18 !important;
}
.hooked
{
 background-color: #8a2be2 !important;
 padding:5px 6px;
  border-bottom: none !important;
  margin-top:5px;
}
 .hooked:focus, .hooked:hover
{
 background-color:#7724c5 !important;
}
.req
{
 font-size:16.5px;
 font-weight:500;
}
.bottom
{
 float:right;
 padding:30px 60px;
}

.breadcrumb {
    padding: .75rem 1rem;
    margin-bottom: 1rem;
    list-style: none;
    background-color: #eceeef;
    -webkit-border-radius: .25rem;
    border-radius: .25rem
}

.breadcrumb::after {
    display: table;
    clear: both;
    content: ""
}

.breadcrumb>li {
    float: left
}

.breadcrumb>li+li::before {
    padding-right: .5rem;
    padding-left: .5rem;
    color: #818a91;
    content: "/"
}

.breadcrumb>.active {
    color: #818a91
}

.pagination {
    display: inline-block;
    padding-left: 0;
    margin-top: 1rem;
    margin-bottom: 1rem;
    -webkit-border-radius: .25rem;
    border-radius: .25rem
}

.page-item {
    display: inline
}

.page-item:first-child .page-link {
    margin-left: 0;
    -webkit-border-top-left-radius: .25rem;
    border-top-left-radius: .25rem;
    -webkit-border-bottom-left-radius: .25rem;
    border-bottom-left-radius: .25rem
}

.page-item:last-child .page-link {
    -webkit-border-top-right-radius: .25rem;
    border-top-right-radius: .25rem;
    -webkit-border-bottom-right-radius: .25rem;
    border-bottom-right-radius: .25rem
}

.page-item.active .page-link,
.page-item.active .page-link:focus,
.page-item.active .page-link:hover {
    z-index: 2;
    color: #fff;
    cursor: default;
    background-color: #0275d8;
    border-color: #0275d8
}

.page-item.disabled .page-link,
.page-item.disabled .page-link:focus,
.page-item.disabled .page-link:hover {
    color: #818a91;
    cursor: not-allowed;
    background-color: #fff;
    border-color: #ddd
}

.page-link {
    position: relative;
    float: left;
    padding: .5rem .75rem;
    margin-left: -1px;
    line-height: 1.5;
    color: #0275d8;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #ddd
}

#nprogress {
    pointer-events: none;
  }
  
  #nprogress .bar {
    background: #29d;
  
    position: fixed;
    z-index: 1031;
    top: 0;
    left: 0;
  
    width: 100%;
    height: 2px;
  }
  
  /* Fancy blur effect */
  #nprogress .peg {
    display: block;
    position: absolute;
    right: 0px;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px #29d, 0 0 5px #29d;
    opacity: 1.0;
  
    -webkit-transform: rotate(3deg) translate(0px, -4px);
        -ms-transform: rotate(3deg) translate(0px, -4px);
            transform: rotate(3deg) translate(0px, -4px);
  }
  
  /* Remove these to get rid of the spinner */
  #nprogress .spinner {
    display: block;
    position: fixed;
    z-index: 1031;
    top: 50%;
    right: 50%;
  }
  
  #nprogress .spinner-icon {
    width: 50px;
    height: 50px;
    box-sizing: border-box;
  
    border: solid 2px transparent;
    border-top-color: #29d;
    border-left-color: #29d;
    border-radius: 50%;
  
    -webkit-animation: nprogress-spinner 400ms linear infinite;
            animation: nprogress-spinner 400ms linear infinite;
  }
  
  .nprogress-custom-parent {
    overflow: hidden;
    position: relative;
  }
  
  .nprogress-custom-parent #nprogress .spinner,
  .nprogress-custom-parent #nprogress .bar {
    position: absolute;
  }
  
  @-webkit-keyframes nprogress-spinner {
    0%   { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  @keyframes nprogress-spinner {
    0%   { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

.page-link:focus,
.page-link:hover {
    color: #014c8c;
    background-color: #eceeef;
    border-color: #ddd
}

.pagination-lg .page-link {
    padding: .75rem 1.5rem;
    font-size: 1.25rem;
    line-height: 1.333333
}

.pagination-lg .page-item:first-child .page-link {
    -webkit-border-top-left-radius: .3rem;
    border-top-left-radius: .3rem;
    -webkit-border-bottom-left-radius: .3rem;
    border-bottom-left-radius: .3rem
}

.pagination-lg .page-item:last-child .page-link {
    -webkit-border-top-right-radius: .3rem;
    border-top-right-radius: .3rem;
    -webkit-border-bottom-right-radius: .3rem;
    border-bottom-right-radius: .3rem
}

.pagination-sm .page-link {
    padding: .275rem .75rem;
    font-size: .875rem;
    line-height: 1.5
}

.pagination-sm .page-item:first-child .page-link {
    -webkit-border-top-left-radius: .2rem;
    border-top-left-radius: .2rem;
    -webkit-border-bottom-left-radius: .2rem;
    border-bottom-left-radius: .2rem
}

.pagination-sm .page-item:last-child .page-link {
    -webkit-border-top-right-radius: .2rem;
    border-top-right-radius: .2rem;
    -webkit-border-bottom-right-radius: .2rem;
    border-bottom-right-radius: .2rem
}

.pager {
    padding-left: 0;
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-align: center;
    list-style: none
}

.pager li {
    display: inline
}

.pager li>a,
.pager li>span {
    display: inline-block;
    padding: 5px 14px;
    background-color: #fff;
    border: 1px solid #ddd;
    -webkit-border-radius: 15px;
    border-radius: 15px
}

.pager li>a:focus,
.pager li>a:hover {
    text-decoration: none;
    background-color: #eceeef
}

.pager .disabled>a,
.pager .disabled>a:focus,
.pager .disabled>a:hover {
    color: #818a91;
    cursor: not-allowed;
    background-color: #fff
}

.pager .disabled>span {
    color: #818a91;
    cursor: not-allowed;
    background-color: #fff
}

.pager-next>a,
.pager-next>span {
    float: right
}

.pager-prev>a,
.pager-prev>span {
    float: left
}

.jspContainer {
    overflow: hidden;
    position: relative;
    width: 100%!important
}

.jspPane {
    position: absolute;
    width: 100%!important
}

.jspVerticalBar {
    position: absolute;
    top: 0;
    right: 0;
    width: 8px;
    height: 100%;
    background: 0 0
}

.jspHorizontalBar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 8px;
    background: 0 0
}

.jspCap {
    display: none
}

.jspHorizontalBar .jspCap {
    float: left
}

.jspTrack {
    background: 0 0;
    position: relative
}

.jspDrag {
    background: #d3dee6;
    border: solid 1px #fff;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    position: relative;
    top: 0;
    left: 0;
    cursor: pointer;
    display: none !important;
}

.jspHorizontalBar .jspDrag,
.jspHorizontalBar .jspTrack {
    float: left;
    height: 100%
}

.jspArrow {
    background: #50506d;
    text-indent: -20000px;
    display: block;
    cursor: pointer;
    padding: 0;
    margin: 0
}

.jspArrow.jspDisabled {
    cursor: default;
    background: #80808d
}

.jspVerticalBar .jspArrow {
    height: 8px
}

.jspHorizontalBar .jspArrow {
    width: 8px;
    float: left;
    height: 100%
}

.jspVerticalBar .jspArrow:focus {
    outline: 0
}

.jspCorner {
    background: #eeeef4;
    float: left;
    height: 100%
}

* html .jspCorner {
    margin: 0 -3px 0 0
}

.flag-icon-background {
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat
}

.flag-icon {
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
    position: relative;
    display: inline-block;
    width: 1.33333333em;
    line-height: 1em;
    opacity: .7
}

.flag-icon:before {
    content: "\00a0"
}

.flag-icon.flag-icon-squared {
    width: 1em
}

.flag-icon-ad {
    background-image: url(../img/flags/4x3/ad.svg)
}

.flag-icon-ad.flag-icon-squared {
    background-image: url(../img/flags/1x1/ad.svg)
}

.flag-icon-ae {
    background-image: url(../img/flags/4x3/ae.svg)
}

.flag-icon-ae.flag-icon-squared {
    background-image: url(../img/flags/1x1/ae.svg)
}

.flag-icon-af {
    background-image: url(../img/flags/4x3/af.svg)
}

.flag-icon-af.flag-icon-squared {
    background-image: url(../img/flags/1x1/af.svg)
}

.flag-icon-ag {
    background-image: url(../img/flags/4x3/ag.svg)
}

.flag-icon-ag.flag-icon-squared {
    background-image: url(../img/flags/1x1/ag.svg)
}

.flag-icon-ai {
    background-image: url(../img/flags/4x3/ai.svg)
}

.flag-icon-ai.flag-icon-squared {
    background-image: url(../img/flags/1x1/ai.svg)
}

.flag-icon-al {
    background-image: url(../img/flags/4x3/al.svg)
}

.flag-icon-al.flag-icon-squared {
    background-image: url(../img/flags/1x1/al.svg)
}

.flag-icon-am {
    background-image: url(../img/flags/4x3/am.svg)
}

.flag-icon-am.flag-icon-squared {
    background-image: url(../img/flags/1x1/am.svg)
}

.flag-icon-ao {
    background-image: url(../img/flags/4x3/ao.svg)
}

.flag-icon-ao.flag-icon-squared {
    background-image: url(../img/flags/1x1/ao.svg)
}

.flag-icon-aq {
    background-image: url(../img/flags/4x3/aq.svg)
}

.flag-icon-aq.flag-icon-squared {
    background-image: url(../img/flags/1x1/aq.svg)
}

.flag-icon-ar {
    background-image: url(../img/flags/4x3/ar.svg)
}

.flag-icon-ar.flag-icon-squared {
    background-image: url(../img/flags/1x1/ar.svg)
}

.flag-icon-as {
    background-image: url(../img/flags/4x3/as.svg)
}

.flag-icon-as.flag-icon-squared {
    background-image: url(../img/flags/1x1/as.svg)
}

.flag-icon-at {
    background-image: url(../img/flags/4x3/at.svg)
}

.flag-icon-at.flag-icon-squared {
    background-image: url(../img/flags/1x1/at.svg)
}

.flag-icon-au {
    background-image: url(../img/flags/4x3/au.svg)
}

.flag-icon-au.flag-icon-squared {
    background-image: url(../img/flags/1x1/au.svg)
}

.flag-icon-aw {
    background-image: url(../img/flags/4x3/aw.svg)
}

.flag-icon-aw.flag-icon-squared {
    background-image: url(../img/flags/1x1/aw.svg)
}

.flag-icon-ax {
    background-image: url(../img/flags/4x3/ax.svg)
}

.flag-icon-ax.flag-icon-squared {
    background-image: url(../img/flags/1x1/ax.svg)
}

.flag-icon-az {
    background-image: url(../img/flags/4x3/az.svg)
}

.flag-icon-az.flag-icon-squared {
    background-image: url(../img/flags/1x1/az.svg)
}

.flag-icon-ba {
    background-image: url(../img/flags/4x3/ba.svg)
}

.flag-icon-ba.flag-icon-squared {
    background-image: url(../img/flags/1x1/ba.svg)
}

.flag-icon-bb {
    background-image: url(../img/flags/4x3/bb.svg)
}

.flag-icon-bb.flag-icon-squared {
    background-image: url(../img/flags/1x1/bb.svg)
}

.flag-icon-bd {
    background-image: url(../img/flags/4x3/bd.svg)
}

.flag-icon-bd.flag-icon-squared {
    background-image: url(../img/flags/1x1/bd.svg)
}

.flag-icon-be {
    background-image: url(../img/flags/4x3/be.svg)
}

.flag-icon-be.flag-icon-squared {
    background-image: url(../img/flags/1x1/be.svg)
}

.flag-icon-bf {
    background-image: url(../img/flags/4x3/bf.svg)
}

.flag-icon-bf.flag-icon-squared {
    background-image: url(../img/flags/1x1/bf.svg)
}

.flag-icon-bg {
    background-image: url(../img/flags/4x3/bg.svg)
}

.flag-icon-bg.flag-icon-squared {
    background-image: url(../img/flags/1x1/bg.svg)
}

.flag-icon-bh {
    background-image: url(../img/flags/4x3/bh.svg)
}

.flag-icon-bh.flag-icon-squared {
    background-image: url(../img/flags/1x1/bh.svg)
}

.flag-icon-bi {
    background-image: url(../img/flags/4x3/bi.svg)
}

.flag-icon-bi.flag-icon-squared {
    background-image: url(../img/flags/1x1/bi.svg)
}

.flag-icon-bj {
    background-image: url(../img/flags/4x3/bj.svg)
}

.flag-icon-bj.flag-icon-squared {
    background-image: url(../img/flags/1x1/bj.svg)
}

.flag-icon-bl {
    background-image: url(../img/flags/4x3/bl.svg)
}

.flag-icon-bl.flag-icon-squared {
    background-image: url(../img/flags/1x1/bl.svg)
}

.flag-icon-bm {
    background-image: url(../img/flags/4x3/bm.svg)
}

.flag-icon-bm.flag-icon-squared {
    background-image: url(../img/flags/1x1/bm.svg)
}

.flag-icon-bn {
    background-image: url(../img/flags/4x3/bn.svg)
}

.flag-icon-bn.flag-icon-squared {
    background-image: url(../img/flags/1x1/bn.svg)
}

.flag-icon-bo {
    background-image: url(../img/flags/4x3/bo.svg)
}

.flag-icon-bo.flag-icon-squared {
    background-image: url(../img/flags/1x1/bo.svg)
}

.flag-icon-bq {
    background-image: url(../img/flags/4x3/bq.svg)
}

.flag-icon-bq.flag-icon-squared {
    background-image: url(../img/flags/1x1/bq.svg)
}

.flag-icon-br {
    background-image: url(../img/flags/4x3/br.svg)
}

.flag-icon-br.flag-icon-squared {
    background-image: url(../img/flags/1x1/br.svg)
}

.flag-icon-bs {
    background-image: url(../img/flags/4x3/bs.svg)
}

.flag-icon-bs.flag-icon-squared {
    background-image: url(../img/flags/1x1/bs.svg)
}

.flag-icon-bt {
    background-image: url(../img/flags/4x3/bt.svg)
}

.flag-icon-bt.flag-icon-squared {
    background-image: url(../img/flags/1x1/bt.svg)
}

.flag-icon-bv {
    background-image: url(../img/flags/4x3/bv.svg)
}

.flag-icon-bv.flag-icon-squared {
    background-image: url(../img/flags/1x1/bv.svg)
}

.flag-icon-bw {
    background-image: url(../img/flags/4x3/bw.svg)
}

.flag-icon-bw.flag-icon-squared {
    background-image: url(../img/flags/1x1/bw.svg)
}

.flag-icon-by {
    background-image: url(../img/flags/4x3/by.svg)
}

.flag-icon-by.flag-icon-squared {
    background-image: url(../img/flags/1x1/by.svg)
}

.flag-icon-bz {
    background-image: url(../img/flags/4x3/bz.svg)
}

.flag-icon-bz.flag-icon-squared {
    background-image: url(../img/flags/1x1/bz.svg)
}

.flag-icon-ca {
    background-image: url(../img/flags/4x3/ca.svg)
}

.flag-icon-ca.flag-icon-squared {
    background-image: url(../img/flags/1x1/ca.svg)
}

.flag-icon-cc {
    background-image: url(../img/flags/4x3/cc.svg)
}

.flag-icon-cc.flag-icon-squared {
    background-image: url(../img/flags/1x1/cc.svg)
}

.flag-icon-cd {
    background-image: url(../img/flags/4x3/cd.svg)
}

.flag-icon-cd.flag-icon-squared {
    background-image: url(../img/flags/1x1/cd.svg)
}

.flag-icon-cf {
    background-image: url(../img/flags/4x3/cf.svg)
}

.flag-icon-cf.flag-icon-squared {
    background-image: url(../img/flags/1x1/cf.svg)
}

.flag-icon-cg {
    background-image: url(../img/flags/4x3/cg.svg)
}

.flag-icon-cg.flag-icon-squared {
    background-image: url(../img/flags/1x1/cg.svg)
}

.flag-icon-ch {
    background-image: url(../img/flags/4x3/ch.svg)
}

.flag-icon-ch.flag-icon-squared {
    background-image: url(../img/flags/1x1/ch.svg)
}

.flag-icon-ci {
    background-image: url(../img/flags/4x3/ci.svg)
}

.flag-icon-ci.flag-icon-squared {
    background-image: url(../img/flags/1x1/ci.svg)
}

.flag-icon-ck {
    background-image: url(../img/flags/4x3/ck.svg)
}

.flag-icon-ck.flag-icon-squared {
    background-image: url(../img/flags/1x1/ck.svg)
}

.flag-icon-cl {
    background-image: url(../img/flags/4x3/cl.svg)
}

.flag-icon-cl.flag-icon-squared {
    background-image: url(../img/flags/1x1/cl.svg)
}

.flag-icon-cm {
    background-image: url(../img/flags/4x3/cm.svg)
}

.flag-icon-cm.flag-icon-squared {
    background-image: url(../img/flags/1x1/cm.svg)
}

.flag-icon-cn {
    background-image: url(../img/flags/4x3/cn.svg)
}

.flag-icon-cn.flag-icon-squared {
    background-image: url(../img/flags/1x1/cn.svg)
}

.flag-icon-co {
    background-image: url(../img/flags/4x3/co.svg)
}

.flag-icon-co.flag-icon-squared {
    background-image: url(../img/flags/1x1/co.svg)
}

.flag-icon-cr {
    background-image: url(../img/flags/4x3/cr.svg)
}

.flag-icon-cr.flag-icon-squared {
    background-image: url(../img/flags/1x1/cr.svg)
}

.flag-icon-cu {
    background-image: url(../img/flags/4x3/cu.svg)
}

.flag-icon-cu.flag-icon-squared {
    background-image: url(../img/flags/1x1/cu.svg)
}

.flag-icon-cv {
    background-image: url(../img/flags/4x3/cv.svg)
}

.flag-icon-cv.flag-icon-squared {
    background-image: url(../img/flags/1x1/cv.svg)
}

.flag-icon-cw {
    background-image: url(../img/flags/4x3/cw.svg)
}

.flag-icon-cw.flag-icon-squared {
    background-image: url(../img/flags/1x1/cw.svg)
}

.flag-icon-cx {
    background-image: url(../img/flags/4x3/cx.svg)
}

.flag-icon-cx.flag-icon-squared {
    background-image: url(../img/flags/1x1/cx.svg)
}

.flag-icon-cy {
    background-image: url(../img/flags/4x3/cy.svg)
}

.flag-icon-cy.flag-icon-squared {
    background-image: url(../img/flags/1x1/cy.svg)
}

.flag-icon-cz {
    background-image: url(../img/flags/4x3/cz.svg)
}

.flag-icon-cz.flag-icon-squared {
    background-image: url(../img/flags/1x1/cz.svg)
}

.flag-icon-de {
    background-image: url(../img/flags/4x3/de.svg)
}

.flag-icon-de.flag-icon-squared {
    background-image: url(../img/flags/1x1/de.svg)
}

.flag-icon-dj {
    background-image: url(../img/flags/4x3/dj.svg)
}

.flag-icon-dj.flag-icon-squared {
    background-image: url(../img/flags/1x1/dj.svg)
}

.flag-icon-dk {
    background-image: url(../img/flags/4x3/dk.svg)
}

.flag-icon-dk.flag-icon-squared {
    background-image: url(../img/flags/1x1/dk.svg)
}

.flag-icon-dm {
    background-image: url(../img/flags/4x3/dm.svg)
}

.flag-icon-dm.flag-icon-squared {
    background-image: url(../img/flags/1x1/dm.svg)
}

.flag-icon-do {
    background-image: url(../img/flags/4x3/do.svg)
}

.flag-icon-do.flag-icon-squared {
    background-image: url(../img/flags/1x1/do.svg)
}

.flag-icon-dz {
    background-image: url(../img/flags/4x3/dz.svg)
}

.flag-icon-dz.flag-icon-squared {
    background-image: url(../img/flags/1x1/dz.svg)
}

.flag-icon-ec {
    background-image: url(../img/flags/4x3/ec.svg)
}

.flag-icon-ec.flag-icon-squared {
    background-image: url(../img/flags/1x1/ec.svg)
}

.flag-icon-ee {
    background-image: url(../img/flags/4x3/ee.svg)
}

.flag-icon-ee.flag-icon-squared {
    background-image: url(../img/flags/1x1/ee.svg)
}

.flag-icon-eg {
    background-image: url(../img/flags/4x3/eg.svg)
}

.flag-icon-eg.flag-icon-squared {
    background-image: url(../img/flags/1x1/eg.svg)
}

.flag-icon-eh {
    background-image: url(../img/flags/4x3/eh.svg)
}

.flag-icon-eh.flag-icon-squared {
    background-image: url(../img/flags/1x1/eh.svg)
}

.flag-icon-er {
    background-image: url(../img/flags/4x3/er.svg)
}

.flag-icon-er.flag-icon-squared {
    background-image: url(../img/flags/1x1/er.svg)
}

.flag-icon-es {
    background-image: url(../img/flags/4x3/es.svg)
}

.flag-icon-es.flag-icon-squared {
    background-image: url(../img/flags/1x1/es.svg)
}

.flag-icon-et {
    background-image: url(../img/flags/4x3/et.svg)
}

.flag-icon-et.flag-icon-squared {
    background-image: url(../img/flags/1x1/et.svg)
}

.flag-icon-fi {
    background-image: url(../img/flags/4x3/fi.svg)
}

.flag-icon-fi.flag-icon-squared {
    background-image: url(../img/flags/1x1/fi.svg)
}

.flag-icon-fj {
    background-image: url(../img/flags/4x3/fj.svg)
}

.flag-icon-fj.flag-icon-squared {
    background-image: url(../img/flags/1x1/fj.svg)
}

.flag-icon-fk {
    background-image: url(../img/flags/4x3/fk.svg)
}

.flag-icon-fk.flag-icon-squared {
    background-image: url(../img/flags/1x1/fk.svg)
}

.flag-icon-fm {
    background-image: url(../img/flags/4x3/fm.svg)
}

.flag-icon-fm.flag-icon-squared {
    background-image: url(../img/flags/1x1/fm.svg)
}

.flag-icon-fo {
    background-image: url(../img/flags/4x3/fo.svg)
}

.flag-icon-fo.flag-icon-squared {
    background-image: url(../img/flags/1x1/fo.svg)
}

.flag-icon-fr {
    background-image: url(../img/flags/4x3/fr.svg)
}

.flag-icon-fr.flag-icon-squared {
    background-image: url(../img/flags/1x1/fr.svg)
}

.flag-icon-ga {
    background-image: url(../img/flags/4x3/ga.svg)
}

.flag-icon-ga.flag-icon-squared {
    background-image: url(../img/flags/1x1/ga.svg)
}

.flag-icon-gb {
    background-image: url(../img/flags/4x3/gb.svg)
}

.flag-icon-gb.flag-icon-squared {
    background-image: url(../img/flags/1x1/gb.svg)
}

.flag-icon-gd {
    background-image: url(../img/flags/4x3/gd.svg)
}

.flag-icon-gd.flag-icon-squared {
    background-image: url(../img/flags/1x1/gd.svg)
}

.flag-icon-ge {
    background-image: url(../img/flags/4x3/ge.svg)
}

.flag-icon-ge.flag-icon-squared {
    background-image: url(../img/flags/1x1/ge.svg)
}

.flag-icon-gf {
    background-image: url(../img/flags/4x3/gf.svg)
}

.flag-icon-gf.flag-icon-squared {
    background-image: url(../img/flags/1x1/gf.svg)
}

.flag-icon-gg {
    background-image: url(../img/flags/4x3/gg.svg)
}

.flag-icon-gg.flag-icon-squared {
    background-image: url(../img/flags/1x1/gg.svg)
}

.flag-icon-gh {
    background-image: url(../img/flags/4x3/gh.svg)
}

.flag-icon-gh.flag-icon-squared {
    background-image: url(../img/flags/1x1/gh.svg)
}

.flag-icon-gi {
    background-image: url(../img/flags/4x3/gi.svg)
}

.flag-icon-gi.flag-icon-squared {
    background-image: url(../img/flags/1x1/gi.svg)
}

.flag-icon-gl {
    background-image: url(../img/flags/4x3/gl.svg)
}

.flag-icon-gl.flag-icon-squared {
    background-image: url(../img/flags/1x1/gl.svg)
}

.flag-icon-gm {
    background-image: url(../img/flags/4x3/gm.svg)
}

.flag-icon-gm.flag-icon-squared {
    background-image: url(../img/flags/1x1/gm.svg)
}

.flag-icon-gn {
    background-image: url(../img/flags/4x3/gn.svg)
}

.flag-icon-gn.flag-icon-squared {
    background-image: url(../img/flags/1x1/gn.svg)
}

.flag-icon-gp {
    background-image: url(../img/flags/4x3/gp.svg)
}

.flag-icon-gp.flag-icon-squared {
    background-image: url(../img/flags/1x1/gp.svg)
}

.flag-icon-gq {
    background-image: url(../img/flags/4x3/gq.svg)
}

.flag-icon-gq.flag-icon-squared {
    background-image: url(../img/flags/1x1/gq.svg)
}

.flag-icon-gr {
    background-image: url(../img/flags/4x3/gr.svg)
}

.flag-icon-gr.flag-icon-squared {
    background-image: url(../img/flags/1x1/gr.svg)
}

.flag-icon-gs {
    background-image: url(../img/flags/4x3/gs.svg)
}

.flag-icon-gs.flag-icon-squared {
    background-image: url(../img/flags/1x1/gs.svg)
}

.flag-icon-gt {
    background-image: url(../img/flags/4x3/gt.svg)
}

.flag-icon-gt.flag-icon-squared {
    background-image: url(../img/flags/1x1/gt.svg)
}

.flag-icon-gu {
    background-image: url(../img/flags/4x3/gu.svg)
}

.flag-icon-gu.flag-icon-squared {
    background-image: url(../img/flags/1x1/gu.svg)
}

.flag-icon-gw {
    background-image: url(../img/flags/4x3/gw.svg)
}

.flag-icon-gw.flag-icon-squared {
    background-image: url(../img/flags/1x1/gw.svg)
}

.flag-icon-gy {
    background-image: url(../img/flags/4x3/gy.svg)
}

.flag-icon-gy.flag-icon-squared {
    background-image: url(../img/flags/1x1/gy.svg)
}

.flag-icon-hk {
    background-image: url(../img/flags/4x3/hk.svg)
}

.flag-icon-hk.flag-icon-squared {
    background-image: url(../img/flags/1x1/hk.svg)
}

.flag-icon-hm {
    background-image: url(../img/flags/4x3/hm.svg)
}

.flag-icon-hm.flag-icon-squared {
    background-image: url(../img/flags/1x1/hm.svg)
}

.flag-icon-hn {
    background-image: url(../img/flags/4x3/hn.svg)
}

.flag-icon-hn.flag-icon-squared {
    background-image: url(../img/flags/1x1/hn.svg)
}

.flag-icon-hr {
    background-image: url(../img/flags/4x3/hr.svg)
}

.flag-icon-hr.flag-icon-squared {
    background-image: url(../img/flags/1x1/hr.svg)
}

.flag-icon-ht {
    background-image: url(../img/flags/4x3/ht.svg)
}

.flag-icon-ht.flag-icon-squared {
    background-image: url(../img/flags/1x1/ht.svg)
}

.flag-icon-hu {
    background-image: url(../img/flags/4x3/hu.svg)
}

.flag-icon-hu.flag-icon-squared {
    background-image: url(../img/flags/1x1/hu.svg)
}

.flag-icon-id {
    background-image: url(../img/flags/4x3/id.svg)
}

.flag-icon-id.flag-icon-squared {
    background-image: url(../img/flags/1x1/id.svg)
}

.flag-icon-ie {
    background-image: url(../img/flags/4x3/ie.svg)
}

.flag-icon-ie.flag-icon-squared {
    background-image: url(../img/flags/1x1/ie.svg)
}

.flag-icon-il {
    background-image: url(../img/flags/4x3/il.svg)
}

.flag-icon-il.flag-icon-squared {
    background-image: url(../img/flags/1x1/il.svg)
}

.flag-icon-im {
    background-image: url(../img/flags/4x3/im.svg)
}

.flag-icon-im.flag-icon-squared {
    background-image: url(../img/flags/1x1/im.svg)
}

.flag-icon-in {
    background-image: url(../img/flags/4x3/in.svg)
}

.flag-icon-in.flag-icon-squared {
    background-image: url(../img/flags/1x1/in.svg)
}

.flag-icon-io {
    background-image: url(../img/flags/4x3/io.svg)
}

.flag-icon-io.flag-icon-squared {
    background-image: url(../img/flags/1x1/io.svg)
}

.flag-icon-iq {
    background-image: url(../img/flags/4x3/iq.svg)
}

.flag-icon-iq.flag-icon-squared {
    background-image: url(../img/flags/1x1/iq.svg)
}

.flag-icon-ir {
    background-image: url(../img/flags/4x3/ir.svg)
}

.flag-icon-ir.flag-icon-squared {
    background-image: url(../img/flags/1x1/ir.svg)
}

.flag-icon-is {
    background-image: url(../img/flags/4x3/is.svg)
}

.flag-icon-is.flag-icon-squared {
    background-image: url(../img/flags/1x1/is.svg)
}

.flag-icon-it {
    background-image: url(../img/flags/4x3/it.svg)
}

.flag-icon-it.flag-icon-squared {
    background-image: url(../img/flags/1x1/it.svg)
}

.flag-icon-je {
    background-image: url(../img/flags/4x3/je.svg)
}

.flag-icon-je.flag-icon-squared {
    background-image: url(../img/flags/1x1/je.svg)
}

.flag-icon-jm {
    background-image: url(../img/flags/4x3/jm.svg)
}

.flag-icon-jm.flag-icon-squared {
    background-image: url(../img/flags/1x1/jm.svg)
}

.flag-icon-jo {
    background-image: url(../img/flags/4x3/jo.svg)
}

.flag-icon-jo.flag-icon-squared {
    background-image: url(../img/flags/1x1/jo.svg)
}

.flag-icon-jp {
    background-image: url(../img/flags/4x3/jp.svg)
}

.flag-icon-jp.flag-icon-squared {
    background-image: url(../img/flags/1x1/jp.svg)
}

.flag-icon-ke {
    background-image: url(../img/flags/4x3/ke.svg)
}

.flag-icon-ke.flag-icon-squared {
    background-image: url(../img/flags/1x1/ke.svg)
}

.flag-icon-kg {
    background-image: url(../img/flags/4x3/kg.svg)
}

.flag-icon-kg.flag-icon-squared {
    background-image: url(../img/flags/1x1/kg.svg)
}

.flag-icon-kh {
    background-image: url(../img/flags/4x3/kh.svg)
}

.flag-icon-kh.flag-icon-squared {
    background-image: url(../img/flags/1x1/kh.svg)
}

.flag-icon-ki {
    background-image: url(../img/flags/4x3/ki.svg)
}

.flag-icon-ki.flag-icon-squared {
    background-image: url(../img/flags/1x1/ki.svg)
}

.flag-icon-km {
    background-image: url(../img/flags/4x3/km.svg)
}

.flag-icon-km.flag-icon-squared {
    background-image: url(../img/flags/1x1/km.svg)
}

.flag-icon-kn {
    background-image: url(../img/flags/4x3/kn.svg)
}

.flag-icon-kn.flag-icon-squared {
    background-image: url(../img/flags/1x1/kn.svg)
}

.flag-icon-kp {
    background-image: url(../img/flags/4x3/kp.svg)
}

.flag-icon-kp.flag-icon-squared {
    background-image: url(../img/flags/1x1/kp.svg)
}

.flag-icon-kr {
    background-image: url(../img/flags/4x3/kr.svg)
}

.flag-icon-kr.flag-icon-squared {
    background-image: url(../img/flags/1x1/kr.svg)
}

.flag-icon-kw {
    background-image: url(../img/flags/4x3/kw.svg)
}

.flag-icon-kw.flag-icon-squared {
    background-image: url(../img/flags/1x1/kw.svg)
}

.flag-icon-ky {
    background-image: url(../img/flags/4x3/ky.svg)
}

.flag-icon-ky.flag-icon-squared {
    background-image: url(../img/flags/1x1/ky.svg)
}

.flag-icon-kz {
    background-image: url(../img/flags/4x3/kz.svg)
}

.flag-icon-kz.flag-icon-squared {
    background-image: url(../img/flags/1x1/kz.svg)
}

.flag-icon-la {
    background-image: url(../img/flags/4x3/la.svg)
}

.flag-icon-la.flag-icon-squared {
    background-image: url(../img/flags/1x1/la.svg)
}

.flag-icon-lb {
    background-image: url(../img/flags/4x3/lb.svg)
}

.flag-icon-lb.flag-icon-squared {
    background-image: url(../img/flags/1x1/lb.svg)
}

.flag-icon-lc {
    background-image: url(../img/flags/4x3/lc.svg)
}

.flag-icon-lc.flag-icon-squared {
    background-image: url(../img/flags/1x1/lc.svg)
}

.flag-icon-li {
    background-image: url(../img/flags/4x3/li.svg)
}

.flag-icon-li.flag-icon-squared {
    background-image: url(../img/flags/1x1/li.svg)
}

.flag-icon-lk {
    background-image: url(../img/flags/4x3/lk.svg)
}

.flag-icon-lk.flag-icon-squared {
    background-image: url(../img/flags/1x1/lk.svg)
}

.flag-icon-lr {
    background-image: url(../img/flags/4x3/lr.svg)
}

.flag-icon-lr.flag-icon-squared {
    background-image: url(../img/flags/1x1/lr.svg)
}

.flag-icon-ls {
    background-image: url(../img/flags/4x3/ls.svg)
}

.flag-icon-ls.flag-icon-squared {
    background-image: url(../img/flags/1x1/ls.svg)
}

.flag-icon-lt {
    background-image: url(../img/flags/4x3/lt.svg)
}

.flag-icon-lt.flag-icon-squared {
    background-image: url(../img/flags/1x1/lt.svg)
}

.flag-icon-lu {
    background-image: url(../img/flags/4x3/lu.svg)
}

.flag-icon-lu.flag-icon-squared {
    background-image: url(../img/flags/1x1/lu.svg)
}

.flag-icon-lv {
    background-image: url(../img/flags/4x3/lv.svg)
}

.flag-icon-lv.flag-icon-squared {
    background-image: url(../img/flags/1x1/lv.svg)
}

.flag-icon-ly {
    background-image: url(../img/flags/4x3/ly.svg)
}

.flag-icon-ly.flag-icon-squared {
    background-image: url(../img/flags/1x1/ly.svg)
}

.flag-icon-ma {
    background-image: url(../img/flags/4x3/ma.svg)
}

.flag-icon-ma.flag-icon-squared {
    background-image: url(../img/flags/1x1/ma.svg)
}

.flag-icon-mc {
    background-image: url(../img/flags/4x3/mc.svg)
}

.flag-icon-mc.flag-icon-squared {
    background-image: url(../img/flags/1x1/mc.svg)
}

.flag-icon-md {
    background-image: url(../img/flags/4x3/md.svg)
}

.flag-icon-md.flag-icon-squared {
    background-image: url(../img/flags/1x1/md.svg)
}

.flag-icon-me {
    background-image: url(../img/flags/4x3/me.svg)
}

.flag-icon-me.flag-icon-squared {
    background-image: url(../img/flags/1x1/me.svg)
}

.flag-icon-mf {
    background-image: url(../img/flags/4x3/mf.svg)
}

.flag-icon-mf.flag-icon-squared {
    background-image: url(../img/flags/1x1/mf.svg)
}

.flag-icon-mg {
    background-image: url(../img/flags/4x3/mg.svg)
}

.flag-icon-mg.flag-icon-squared {
    background-image: url(../img/flags/1x1/mg.svg)
}

.flag-icon-mh {
    background-image: url(../img/flags/4x3/mh.svg)
}

.flag-icon-mh.flag-icon-squared {
    background-image: url(../img/flags/1x1/mh.svg)
}

.flag-icon-mk {
    background-image: url(../img/flags/4x3/mk.svg)
}

.flag-icon-mk.flag-icon-squared {
    background-image: url(../img/flags/1x1/mk.svg)
}

.flag-icon-ml {
    background-image: url(../img/flags/4x3/ml.svg)
}

.flag-icon-ml.flag-icon-squared {
    background-image: url(../img/flags/1x1/ml.svg)
}

.flag-icon-mm {
    background-image: url(../img/flags/4x3/mm.svg)
}

.flag-icon-mm.flag-icon-squared {
    background-image: url(../img/flags/1x1/mm.svg)
}

.flag-icon-mn {
    background-image: url(../img/flags/4x3/mn.svg)
}

.flag-icon-mn.flag-icon-squared {
    background-image: url(../img/flags/1x1/mn.svg)
}

.flag-icon-mo {
    background-image: url(../img/flags/4x3/mo.svg)
}

.flag-icon-mo.flag-icon-squared {
    background-image: url(../img/flags/1x1/mo.svg)
}

.flag-icon-mp {
    background-image: url(../img/flags/4x3/mp.svg)
}

.flag-icon-mp.flag-icon-squared {
    background-image: url(../img/flags/1x1/mp.svg)
}

.flag-icon-mq {
    background-image: url(../img/flags/4x3/mq.svg)
}

.flag-icon-mq.flag-icon-squared {
    background-image: url(../img/flags/1x1/mq.svg)
}

.flag-icon-mr {
    background-image: url(../img/flags/4x3/mr.svg)
}

.flag-icon-mr.flag-icon-squared {
    background-image: url(../img/flags/1x1/mr.svg)
}

.flag-icon-ms {
    background-image: url(../img/flags/4x3/ms.svg)
}

.flag-icon-ms.flag-icon-squared {
    background-image: url(../img/flags/1x1/ms.svg)
}

.flag-icon-mt {
    background-image: url(../img/flags/4x3/mt.svg)
}

.flag-icon-mt.flag-icon-squared {
    background-image: url(../img/flags/1x1/mt.svg)
}

.flag-icon-mu {
    background-image: url(../img/flags/4x3/mu.svg)
}

.flag-icon-mu.flag-icon-squared {
    background-image: url(../img/flags/1x1/mu.svg)
}

.flag-icon-mv {
    background-image: url(../img/flags/4x3/mv.svg)
}

.flag-icon-mv.flag-icon-squared {
    background-image: url(../img/flags/1x1/mv.svg)
}

.flag-icon-mw {
    background-image: url(../img/flags/4x3/mw.svg)
}

.flag-icon-mw.flag-icon-squared {
    background-image: url(../img/flags/1x1/mw.svg)
}

.flag-icon-mx {
    background-image: url(../img/flags/4x3/mx.svg)
}

.flag-icon-mx.flag-icon-squared {
    background-image: url(../img/flags/1x1/mx.svg)
}

.flag-icon-my {
    background-image: url(../img/flags/4x3/my.svg)
}

.flag-icon-my.flag-icon-squared {
    background-image: url(../img/flags/1x1/my.svg)
}

.flag-icon-mz {
    background-image: url(../img/flags/4x3/mz.svg)
}

.flag-icon-mz.flag-icon-squared {
    background-image: url(../img/flags/1x1/mz.svg)
}

.flag-icon-na {
    background-image: url(../img/flags/4x3/na.svg)
}

.flag-icon-na.flag-icon-squared {
    background-image: url(../img/flags/1x1/na.svg)
}

.flag-icon-nc {
    background-image: url(../img/flags/4x3/nc.svg)
}

.flag-icon-nc.flag-icon-squared {
    background-image: url(../img/flags/1x1/nc.svg)
}

.flag-icon-ne {
    background-image: url(../img/flags/4x3/ne.svg)
}

.flag-icon-ne.flag-icon-squared {
    background-image: url(../img/flags/1x1/ne.svg)
}

.flag-icon-nf {
    background-image: url(../img/flags/4x3/nf.svg)
}

.flag-icon-nf.flag-icon-squared {
    background-image: url(../img/flags/1x1/nf.svg)
}

.flag-icon-ng {
    background-image: url(../img/flags/4x3/ng.svg)
}

.flag-icon-ng.flag-icon-squared {
    background-image: url(../img/flags/1x1/ng.svg)
}

.flag-icon-ni {
    background-image: url(../img/flags/4x3/ni.svg)
}

.flag-icon-ni.flag-icon-squared {
    background-image: url(../img/flags/1x1/ni.svg)
}

.flag-icon-nl {
    background-image: url(../img/flags/4x3/nl.svg)
}

.flag-icon-nl.flag-icon-squared {
    background-image: url(../img/flags/1x1/nl.svg)
}

.flag-icon-no {
    background-image: url(../img/flags/4x3/no.svg)
}

.flag-icon-no.flag-icon-squared {
    background-image: url(../img/flags/1x1/no.svg)
}

.flag-icon-np {
    background-image: url(../img/flags/4x3/np.svg)
}

.flag-icon-np.flag-icon-squared {
    background-image: url(../img/flags/1x1/np.svg)
}

.flag-icon-nr {
    background-image: url(../img/flags/4x3/nr.svg)
}

.flag-icon-nr.flag-icon-squared {
    background-image: url(../img/flags/1x1/nr.svg)
}

.flag-icon-nu {
    background-image: url(../img/flags/4x3/nu.svg)
}

.flag-icon-nu.flag-icon-squared {
    background-image: url(../img/flags/1x1/nu.svg)
}

.flag-icon-nz {
    background-image: url(../img/flags/4x3/nz.svg)
}

.flag-icon-nz.flag-icon-squared {
    background-image: url(../img/flags/1x1/nz.svg)
}

.flag-icon-om {
    background-image: url(../img/flags/4x3/om.svg)
}

.flag-icon-om.flag-icon-squared {
    background-image: url(../img/flags/1x1/om.svg)
}

.flag-icon-pa {
    background-image: url(../img/flags/4x3/pa.svg)
}

.flag-icon-pa.flag-icon-squared {
    background-image: url(../img/flags/1x1/pa.svg)
}

.flag-icon-pe {
    background-image: url(../img/flags/4x3/pe.svg)
}

.flag-icon-pe.flag-icon-squared {
    background-image: url(../img/flags/1x1/pe.svg)
}

.flag-icon-pf {
    background-image: url(../img/flags/4x3/pf.svg)
}

.flag-icon-pf.flag-icon-squared {
    background-image: url(../img/flags/1x1/pf.svg)
}

.flag-icon-pg {
    background-image: url(../img/flags/4x3/pg.svg)
}

.flag-icon-pg.flag-icon-squared {
    background-image: url(../img/flags/1x1/pg.svg)
}

.flag-icon-ph {
    background-image: url(../img/flags/4x3/ph.svg)
}

.flag-icon-ph.flag-icon-squared {
    background-image: url(../img/flags/1x1/ph.svg)
}

.flag-icon-pk {
    background-image: url(../img/flags/4x3/pk.svg)
}

.flag-icon-pk.flag-icon-squared {
    background-image: url(../img/flags/1x1/pk.svg)
}

.flag-icon-pl {
    background-image: url(../img/flags/4x3/pl.svg)
}

.flag-icon-pl.flag-icon-squared {
    background-image: url(../img/flags/1x1/pl.svg)
}

.flag-icon-pm {
    background-image: url(../img/flags/4x3/pm.svg)
}

.flag-icon-pm.flag-icon-squared {
    background-image: url(../img/flags/1x1/pm.svg)
}

.flag-icon-pn {
    background-image: url(../img/flags/4x3/pn.svg)
}

.flag-icon-pn.flag-icon-squared {
    background-image: url(../img/flags/1x1/pn.svg)
}

.flag-icon-pr {
    background-image: url(../img/flags/4x3/pr.svg)
}

.flag-icon-pr.flag-icon-squared {
    background-image: url(../img/flags/1x1/pr.svg)
}

.flag-icon-ps {
    background-image: url(../img/flags/4x3/ps.svg)
}

.flag-icon-ps.flag-icon-squared {
    background-image: url(../img/flags/1x1/ps.svg)
}

.flag-icon-pt {
    background-image: url(../img/flags/4x3/pt.svg)
}

.flag-icon-pt.flag-icon-squared {
    background-image: url(../img/flags/1x1/pt.svg)
}

.flag-icon-pw {
    background-image: url(../img/flags/4x3/pw.svg)
}

.flag-icon-pw.flag-icon-squared {
    background-image: url(../img/flags/1x1/pw.svg)
}

.flag-icon-py {
    background-image: url(../img/flags/4x3/py.svg)
}

.flag-icon-py.flag-icon-squared {
    background-image: url(../img/flags/1x1/py.svg)
}

.flag-icon-qa {
    background-image: url(../img/flags/4x3/qa.svg)
}

.flag-icon-qa.flag-icon-squared {
    background-image: url(../img/flags/1x1/qa.svg)
}

.flag-icon-re {
    background-image: url(../img/flags/4x3/re.svg)
}

.flag-icon-re.flag-icon-squared {
    background-image: url(../img/flags/1x1/re.svg)
}

.flag-icon-ro {
    background-image: url(../img/flags/4x3/ro.svg)
}

.flag-icon-ro.flag-icon-squared {
    background-image: url(../img/flags/1x1/ro.svg)
}

.flag-icon-rs {
    background-image: url(../img/flags/4x3/rs.svg)
}

.flag-icon-rs.flag-icon-squared {
    background-image: url(../img/flags/1x1/rs.svg)
}

.flag-icon-ru {
    background-image: url(../img/flags/4x3/ru.svg)
}

.flag-icon-ru.flag-icon-squared {
    background-image: url(../img/flags/1x1/ru.svg)
}

.flag-icon-rw {
    background-image: url(../img/flags/4x3/rw.svg)
}

.flag-icon-rw.flag-icon-squared {
    background-image: url(../img/flags/1x1/rw.svg)
}

.flag-icon-sa {
    background-image: url(../img/flags/4x3/sa.svg)
}

.flag-icon-sa.flag-icon-squared {
    background-image: url(../img/flags/1x1/sa.svg)
}

.flag-icon-sb {
    background-image: url(../img/flags/4x3/sb.svg)
}

.flag-icon-sb.flag-icon-squared {
    background-image: url(../img/flags/1x1/sb.svg)
}

.flag-icon-sc {
    background-image: url(../img/flags/4x3/sc.svg)
}

.flag-icon-sc.flag-icon-squared {
    background-image: url(../img/flags/1x1/sc.svg)
}

.flag-icon-sd {
    background-image: url(../img/flags/4x3/sd.svg)
}

.flag-icon-sd.flag-icon-squared {
    background-image: url(../img/flags/1x1/sd.svg)
}

.flag-icon-se {
    background-image: url(../img/flags/4x3/se.svg)
}

.flag-icon-se.flag-icon-squared {
    background-image: url(../img/flags/1x1/se.svg)
}

.flag-icon-sg {
    background-image: url(../img/flags/4x3/sg.svg)
}

.flag-icon-sg.flag-icon-squared {
    background-image: url(../img/flags/1x1/sg.svg)
}

.flag-icon-sh {
    background-image: url(../img/flags/4x3/sh.svg)
}

.flag-icon-sh.flag-icon-squared {
    background-image: url(../img/flags/1x1/sh.svg)
}

.flag-icon-si {
    background-image: url(../img/flags/4x3/si.svg)
}

.flag-icon-si.flag-icon-squared {
    background-image: url(../img/flags/1x1/si.svg)
}

.flag-icon-sj {
    background-image: url(../img/flags/4x3/sj.svg)
}

.flag-icon-sj.flag-icon-squared {
    background-image: url(../img/flags/1x1/sj.svg)
}

.flag-icon-sk {
    background-image: url(../img/flags/4x3/sk.svg)
}

.flag-icon-sk.flag-icon-squared {
    background-image: url(../img/flags/1x1/sk.svg)
}

.flag-icon-sl {
    background-image: url(../img/flags/4x3/sl.svg)
}

.flag-icon-sl.flag-icon-squared {
    background-image: url(../img/flags/1x1/sl.svg)
}

.flag-icon-sm {
    background-image: url(../img/flags/4x3/sm.svg)
}

.flag-icon-sm.flag-icon-squared {
    background-image: url(../img/flags/1x1/sm.svg)
}

.flag-icon-sn {
    background-image: url(../img/flags/4x3/sn.svg)
}

.flag-icon-sn.flag-icon-squared {
    background-image: url(../img/flags/1x1/sn.svg)
}

.flag-icon-so {
    background-image: url(../img/flags/4x3/so.svg)
}

.flag-icon-so.flag-icon-squared {
    background-image: url(../img/flags/1x1/so.svg)
}

.flag-icon-sr {
    background-image: url(../img/flags/4x3/sr.svg)
}

.flag-icon-sr.flag-icon-squared {
    background-image: url(../img/flags/1x1/sr.svg)
}

.flag-icon-ss {
    background-image: url(../img/flags/4x3/ss.svg)
}

.flag-icon-ss.flag-icon-squared {
    background-image: url(../img/flags/1x1/ss.svg)
}

.flag-icon-st {
    background-image: url(../img/flags/4x3/st.svg)
}

.flag-icon-st.flag-icon-squared {
    background-image: url(../img/flags/1x1/st.svg)
}

.flag-icon-sv {
    background-image: url(../img/flags/4x3/sv.svg)
}

.flag-icon-sv.flag-icon-squared {
    background-image: url(../img/flags/1x1/sv.svg)
}

.flag-icon-sx {
    background-image: url(../img/flags/4x3/sx.svg)
}

.flag-icon-sx.flag-icon-squared {
    background-image: url(../img/flags/1x1/sx.svg)
}

.flag-icon-sy {
    background-image: url(../img/flags/4x3/sy.svg)
}

.flag-icon-sy.flag-icon-squared {
    background-image: url(../img/flags/1x1/sy.svg)
}

.flag-icon-sz {
    background-image: url(../img/flags/4x3/sz.svg)
}

.flag-icon-sz.flag-icon-squared {
    background-image: url(../img/flags/1x1/sz.svg)
}

.flag-icon-tc {
    background-image: url(../img/flags/4x3/tc.svg)
}

.flag-icon-tc.flag-icon-squared {
    background-image: url(../img/flags/1x1/tc.svg)
}

.flag-icon-td {
    background-image: url(../img/flags/4x3/td.svg)
}

.flag-icon-td.flag-icon-squared {
    background-image: url(../img/flags/1x1/td.svg)
}

.flag-icon-tf {
    background-image: url(../img/flags/4x3/tf.svg)
}

.flag-icon-tf.flag-icon-squared {
    background-image: url(../img/flags/1x1/tf.svg)
}

.flag-icon-tg {
    background-image: url(../img/flags/4x3/tg.svg)
}

.flag-icon-tg.flag-icon-squared {
    background-image: url(../img/flags/1x1/tg.svg)
}

.flag-icon-th {
    background-image: url(../img/flags/4x3/th.svg)
}

.flag-icon-th.flag-icon-squared {
    background-image: url(../img/flags/1x1/th.svg)
}

.flag-icon-tj {
    background-image: url(../img/flags/4x3/tj.svg)
}

.flag-icon-tj.flag-icon-squared {
    background-image: url(../img/flags/1x1/tj.svg)
}

.flag-icon-tk {
    background-image: url(../img/flags/4x3/tk.svg)
}

.flag-icon-tk.flag-icon-squared {
    background-image: url(../img/flags/1x1/tk.svg)
}

.flag-icon-tl {
    background-image: url(../img/flags/4x3/tl.svg)
}

.flag-icon-tl.flag-icon-squared {
    background-image: url(../img/flags/1x1/tl.svg)
}

.flag-icon-tm {
    background-image: url(../img/flags/4x3/tm.svg)
}

.flag-icon-tm.flag-icon-squared {
    background-image: url(../img/flags/1x1/tm.svg)
}

.flag-icon-tn {
    background-image: url(../img/flags/4x3/tn.svg)
}

.flag-icon-tn.flag-icon-squared {
    background-image: url(../img/flags/1x1/tn.svg)
}

.flag-icon-to {
    background-image: url(../img/flags/4x3/to.svg)
}

.flag-icon-to.flag-icon-squared {
    background-image: url(../img/flags/1x1/to.svg)
}

.flag-icon-tr {
    background-image: url(../img/flags/4x3/tr.svg)
}

.flag-icon-tr.flag-icon-squared {
    background-image: url(../img/flags/1x1/tr.svg)
}

.flag-icon-tt {
    background-image: url(../img/flags/4x3/tt.svg)
}

.flag-icon-tt.flag-icon-squared {
    background-image: url(../img/flags/1x1/tt.svg)
}

.flag-icon-tv {
    background-image: url(../img/flags/4x3/tv.svg)
}

.flag-icon-tv.flag-icon-squared {
    background-image: url(../img/flags/1x1/tv.svg)
}

.flag-icon-tw {
    background-image: url(../img/flags/4x3/tw.svg)
}

.flag-icon-tw.flag-icon-squared {
    background-image: url(../img/flags/1x1/tw.svg)
}

.flag-icon-tz {
    background-image: url(../img/flags/4x3/tz.svg)
}

.flag-icon-tz.flag-icon-squared {
    background-image: url(../img/flags/1x1/tz.svg)
}

.flag-icon-ua {
    background-image: url(../img/flags/4x3/ua.svg)
}

.flag-icon-ua.flag-icon-squared {
    background-image: url(../img/flags/1x1/ua.svg)
}

.flag-icon-ug {
    background-image: url(../img/flags/4x3/ug.svg)
}

.flag-icon-ug.flag-icon-squared {
    background-image: url(../img/flags/1x1/ug.svg)
}

.flag-icon-um {
    background-image: url(../img/flags/4x3/um.svg)
}

.flag-icon-um.flag-icon-squared {
    background-image: url(../img/flags/1x1/um.svg)
}

.flag-icon-us {
    background-image: url(../img/flags/4x3/us.svg)
}

.flag-icon-us.flag-icon-squared {
    background-image: url(../img/flags/1x1/us.svg)
}

.flag-icon-uy {
    background-image: url(../img/flags/4x3/uy.svg)
}

.flag-icon-uy.flag-icon-squared {
    background-image: url(../img/flags/1x1/uy.svg)
}

.flag-icon-uz {
    background-image: url(../img/flags/4x3/uz.svg)
}

.flag-icon-uz.flag-icon-squared {
    background-image: url(../img/flags/1x1/uz.svg)
}

.flag-icon-va {
    background-image: url(../img/flags/4x3/va.svg)
}

.flag-icon-va.flag-icon-squared {
    background-image: url(../img/flags/1x1/va.svg)
}

.flag-icon-vc {
    background-image: url(../img/flags/4x3/vc.svg)
}

.flag-icon-vc.flag-icon-squared {
    background-image: url(../img/flags/1x1/vc.svg)
}

.flag-icon-ve {
    background-image: url(../img/flags/4x3/ve.svg)
}

.flag-icon-ve.flag-icon-squared {
    background-image: url(../img/flags/1x1/ve.svg)
}

.flag-icon-vg {
    background-image: url(../img/flags/4x3/vg.svg)
}

.flag-icon-vg.flag-icon-squared {
    background-image: url(../img/flags/1x1/vg.svg)
}

.flag-icon-vi {
    background-image: url(../img/flags/4x3/vi.svg)
}

.flag-icon-vi.flag-icon-squared {
    background-image: url(../img/flags/1x1/vi.svg)
}

.flag-icon-vn {
    background-image: url(../img/flags/4x3/vn.svg)
}

.flag-icon-vn.flag-icon-squared {
    background-image: url(../img/flags/1x1/vn.svg)
}

.flag-icon-vu {
    background-image: url(../img/flags/4x3/vu.svg)
}

.flag-icon-vu.flag-icon-squared {
    background-image: url(../img/flags/1x1/vu.svg)
}

.flag-icon-wf {
    background-image: url(../img/flags/4x3/wf.svg)
}

.flag-icon-wf.flag-icon-squared {
    background-image: url(../img/flags/1x1/wf.svg)
}

.flag-icon-ws {
    background-image: url(../img/flags/4x3/ws.svg)
}

.flag-icon-ws.flag-icon-squared {
    background-image: url(../img/flags/1x1/ws.svg)
}

.flag-icon-ye {
    background-image: url(../img/flags/4x3/ye.svg)
}

.flag-icon-ye.flag-icon-squared {
    background-image: url(../img/flags/1x1/ye.svg)
}

.flag-icon-yt {
    background-image: url(../img/flags/4x3/yt.svg)
}

.flag-icon-yt.flag-icon-squared {
    background-image: url(../img/flags/1x1/yt.svg)
}

.flag-icon-za {
    background-image: url(../img/flags/4x3/za.svg)
}

.flag-icon-za.flag-icon-squared {
    background-image: url(../img/flags/1x1/za.svg)
}

.flag-icon-zm {
    background-image: url(../img/flags/4x3/zm.svg)
}

.flag-icon-zm.flag-icon-squared {
    background-image: url(../img/flags/1x1/zm.svg)
}

.flag-icon-zw {
    background-image: url(../img/flags/4x3/zw.svg)
}

.flag-icon-zw.flag-icon-squared {
    background-image: url(../img/flags/1x1/zw.svg)
}

.flag-icon-eu {
    background-image: url(../img/flags/4x3/eu.svg)
}

.flag-icon-eu.flag-icon-squared {
    background-image: url(../img/flags/1x1/eu.svg)
}

.flag-icon-gb-eng {
    background-image: url(../img/flags/4x3/gb-eng.svg)
}

.flag-icon-gb-eng.flag-icon-squared {
    background-image: url(../img/flags/1x1/gb-eng.svg)
}

.flag-icon-gb-sct {
    background-image: url(../img/flags/4x3/gb-sct.svg)
}

.flag-icon-gb-sct.flag-icon-squared {
    background-image: url(../img/flags/1x1/gb-sct.svg)
}

.flag-icon-gb-wls {
    background-image: url(../img/flags/4x3/gb-wls.svg)
}

.flag-icon-gb-wls.flag-icon-squared {
    background-image: url(../img/flags/1x1/gb-wls.svg)
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
    display: block
}

audio,
canvas,
video {
    display: inline-block
}

audio:not([controls]) {
    display: none;
    height: 0
}

[hidden] {
    display: none
}

html {
    font-size: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%
}

button,
html,
input,
select,
textarea {
    font-family: sans-serif
}

body {
    margin: 0
}

*,
:after,
:before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

:focus {
    outline: 0
}

:active,
:hover {
    outline: 0
}

abbr[title] {
    border-bottom: 1px dotted
}

/* b,
strong {
    font-weight: 700;
    color:#2a5c8a;
} */

blockquote {
    margin: 1em 40px
}

dfn {
    font-style: italic
}

hr {
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    height: 0
}

mark {
    background: #ff0;
    color: #000
}

pre {
    margin: 0
}

code,
kbd,
pre,
samp {
    font-family: monospace, serif;
    font-size: 1em
}

pre {
    white-space: pre;
    white-space: pre-wrap;
    word-wrap: break-word
}

q {
    quotes: none
}

q:after,
q:before {
    content: '';
    content: none
}

small {
    font-size: 80%
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline
}

sup {
    top: -.5em
}

sub {
    bottom: -.25em
}

dl,
menu,
ol,
ul {
    margin: 0
}

dd {
    margin: 0 0 0 40px
}

nav ol,
nav ul {
    list-style: none;
    list-style-image: none
}

img {
    border: 0;
    -ms-interpolation-mode: bicubic
}

svg:not(:root) {
    overflow: hidden
}

figure {
    margin: 0
}

form {
    margin: 0
}

fieldset {
    border: 1px solid silver;
    margin: 0 2px;
    padding: .35em .625em .75em
}

legend {
    border: 0;
    padding: 0;
    white-space: normal
}

button,
input,
select,
textarea {
    font-size: 100%;
    margin: 0;
    vertical-align: baseline
}

button,
input {
    line-height: normal
}

button,
select {
    text-transform: none
}

button,
html input[type=button],
input[type=reset],
input[type=submit] {
    -webkit-appearance: button;
    cursor: pointer
}

button[disabled],
html input[disabled] {
    cursor: default
}

input[type=checkbox],
input[type=radio] {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0
}

input[type=search] {
    -webkit-appearance: textfield;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration {
    -webkit-appearance: none
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0
}

textarea {
    overflow: auto;
    vertical-align: top
}

table {
    border-collapse: collapse;
    border-spacing: 0
}

html {
    font-size: 1em;
    line-height: 1.4
}

* {
    padding: 0;
    margin: 0
}

img {
    border: none
}

ul {
    list-style: none
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0
}

textarea {
    resize: vertical
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0
}

audio,
canvas,
img,
video {
    vertical-align: middle
}

.browsehappy {
    margin: .2em 0;
    background: #ccc;
    color: #000;
    padding: .2em 0
}

::-moz-selection {
    background: #b3d4fc;
    text-shadow: none
}

::selection {

    background: #b3d4fc;
    text-shadow: none
}

.ir {
    background-color: transparent;
    border: 0;
    overflow: hidden
}

.ir:before {
    content: "";
    display: block;
    width: 0;
    height: 150%
}

.hidden {
    display: none!important;
    visibility: hidden
}

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto
}

.invisible {
    visibility: hidden
}

.clear {
    clear: both;
    height: 0;
    font-size: 0;
    line-height: 0;
    overflow: hidden
}

.clearfix:after,
.clearfix:before {
    content: " ";
    display: table
}

.clearfix:after {
    clear: both
}

.text-center {
    text-align: center
}

.text-left {
    text-align: left
}

.text-right {
    text-align: right
}

.float-left {
    float: left
}

.float-right {
    float: right
}

.tbl {
    display: table;
    width: 100%;
    border-collapse: collapse
}

.tbl-row {
    display: table-row
}

.tbl-cell {
    display: table-cell;
    vertical-align: middle
}

.b-a-0,
.no-border {
    border: none
}

.b-a {
    border: solid 1px #d8e2e7
}

.b-l {
    border-left: solid 1px #d8e2e7
}

.b-r {
    border-right: solid 1px #d8e2e7
}

.b-t {
    border-top: solid 1px #d8e2e7
}

.b-b {
    border-bottom: solid 1px #d8e2e7
}

.b-t-0 {
    border-top: none
}

.b-r-0 {
    border-bottom: none
}

.border-primary {
    border-color: #00a8ff
}

.border-default {
    border-color: #d8e2e7
}

.border-warning {
    border-color: #f29824
}

.border-success {
    border-color: #46c35f
}

.border-info {
    border-color: #ac6bec
}

.border-white {
    border-color: #fff
}

.border-danger {
    border-color: #fa424a
}

.no-radius {
    -webkit-border-radius: 0;
    border-radius: 0
}

.round {
    -webkit-border-radius: .25rem;
    border-radius: .25rem
}

.brtl {
    -webkit-border-top-left-radius: .25rem;
    border-top-left-radius: .25rem
}

.brtr {
    -webkit-border-top-right-radius: .25rem;
    border-top-right-radius: .25rem
}

.blbl {
    -webkit-border-bottom-left-radius: .25rem;
    border-bottom-left-radius: .25rem
}

.brbr {
    -webkit-border-bottom-right-radius: .25rem;
    border-bottom-right-radius: .25rem
}

.overflow-hidden {
    overflow: hidden
}

.overflow-visible {
    overflow: visible
}

.overflow-auto {
    overflow: auto
}

.w100 {
    width: 100px
}

.w150 {
    width: 150px
}

.w200 {
    width: 200px
}

.w250 {
    width: 250px
}

.w300 {
    width: 300px
}

.w350 {
    width: 350px
}

.w400 {
    width: 400px
}

.w450 {
    width: 450px
}

.w500 {
    width: 500px
}

.relative {
    position: relative
}

.absolute {
    position: absolute
}

.lt {
    left: 0
}

.rt {
    right: 0
}

.bt {
    bottom: 0
}

.tp {
    top: 0
}

.no-padding {
    padding: 0
}

.no-margin {
    margin: 0
}

.no-bg {
    background: 0 0
}

.no-shadow {
    -webkit-box-shadow: none;
    box-shadow: none
}

.shadow {
    -webkit-box-shadow: 1px 1px rgba(0, 0, 0, .3);
    box-shadow: 1px 1px rgba(0, 0, 0, .3)
}

.text-shadow {
    text-shadow: 1px 1px rgba(0, 0, 0, .3)
}

.block {
    display: block
}

.m-x-n {
    margin-left: -15px;
    margin-right: -15px
}

.m-r-n {
    margin-right: -15px
}

.m-l-n-n {
    margin-left: -15px
}

.m-a-lg {
    margin: 30px
}

.m-r-lg {
    margin-right: 30px
}

.m-b-lg {
    margin-bottom: 30px
}

.m-l-lg {
    margin-left: 30px
}

.m-t-lg {
    margin-top: 30px
}

.m-x-lg {
    margin-left: 30px;
    margin-right: 30px
}

.m-y-lg {
    margin-top: 30px;
    margin-bottom: 30px
}

.m-a-md {
    margin: 20px
}

.m-r-md {
    margin-right: 20px
}

.m-b-md {
    margin-bottom: 20px
}

.m-l-md {
    margin-left: 20px
}

.m-t-md {
    margin-top: 20px
}

.m-x-md {
    margin-left: 20px;
    margin-right: 20px
}

.m-y-md {
    margin-top: 20px;
    margin-bottom: 20px
}

.m-a {
    margin: 15px
}

.m-r {
    margin-right: 15px
}

.m-b {
    margin-bottom: 15px
}

.m-l {
    margin-left: 15px
}

.m-t {
    margin-top: 15px
}

.m-x {
    margin-left: 15px;
    margin-right: 15px
}

.m-y {
    margin-top: 15px;
    margin-bottom: 15px
}

.m-a-0 {
    margin: 0
}

.m-r-0 {
    margin-right: 0
}

.m-b-0 {
    margin-bottom: 0
}

.m-l-0 {
    margin-left: 0
}

.m-t-0 {
    margin-top: 0
}

.m-x-0 {
    margin-left: 0;
    margin-right: 0
}

.m-y-0 {
    margin-top: 0;
    margin-bottom: 0
}

.m-x-auto {
    margin-right: auto;
    margin-left: auto
}

.p-a-lg {
    padding: 30px
}

.p-r-lg {
    padding-right: 30px
}

.p-b-lg {
    padding-bottom: 30px
}

.p-l-lg {
    padding-left: 30px
}

.p-t-lg {
    padding-top: 30px
}

.p-x-lg {
    padding-left: 30px;
    padding-right: 30px
}

.p-y-lg {
    padding-top: 30px;
    padding-bottom: 30px
}

.p-a-md {
    padding: 20px
}

.p-r-md {
    padding-right: 20px
}

.p-b-md {
    padding-bottom: 20px
}

.p-l-md {
    padding-left: 20px
}

.p-t-md {
    padding-top: 20px
}

.p-x-md {
    padding-left: 20px;
    padding-right: 20px
}

.p-y-md {
    padding-top: 20px;
    padding-bottom: 20px
}

.p-a {
    padding: 15px
}

.p-r {
    padding-right: 15px
}

.p-b {
    padding-bottom: 15px
}

.p-l {
    padding-left: 15px
}

.p-t {
    padding-top: 15px
}

.p-x {
    padding-left: 15px;
    padding-right: 15px
}

.p-y {
    padding-top: 15px;
    padding-bottom: 15px
}

.p-a-0 {
    padding: 0
}

.p-r-0 {
    padding-right: 0
}

.p-b-0 {
    padding-bottom: 0
}

.p-l-0 {
    padding-left: 0
}

.p-t-0 {
    padding-top: 0
}

.p-x-0 {
    padding-left: 0;
    padding-right: 0
}

.p-y-0 {
    padding-top: 0;
    padding-bottom: 0
}

.p-x-auto {
    padding-right: auto;
    padding-left: auto
}

.display-table {
    display: table;
    width: 100%
}

.text-vertical-middle {
    display: table-cell;
    vertical-align: middle;
    text-align: center
}

.inline-block {
    display: inline-block!important
}

@font-face {
    font-family: 'Glyphicons Halflings';
    src: url(../fonts/glyphicons-halflings-regular.eot);
    src: url(../fonts/glyphicons-halflings-regular.eot?#iefix) format('embedded-opentype'), url(../fonts/glyphicons-halflings-regular.woff2) format('woff2'), url(../fonts/glyphicons-halflings-regular.woff) format('woff'), url(../fonts/glyphicons-halflings-regular.ttf) format('truetype'), url(../fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular) format('svg')
}

.glyphicon {
    position: relative;
    top: 1px;
    display: inline-block;
    font-family: 'Glyphicons Halflings';
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.glyphicon-asterisk:before {
    content: "\002a"
}

.glyphicon-plus:before {
    content: "\002b"
}

.glyphicon-eur:before,
.glyphicon-euro:before {
    content: "\20ac"
}

.glyphicon-minus:before {
    content: "\2212"
}

.glyphicon-cloud:before {
    content: "\2601"
}

.glyphicon-envelope:before {
    content: "\2709"
}

.glyphicon-pencil:before {
    content: "\270f"
}

.glyphicon-glass:before {
    content: "\e001"
}

.glyphicon-music:before {
    content: "\e002"
}

.glyphicon-search:before {
    content: "\e003"
}

.glyphicon-heart:before {
    content: "\e005"
}

.glyphicon-star:before {
    content: "\e006"
}

.glyphicon-star-empty:before {
    content: "\e007"
}

.glyphicon-user:before {
    content: "\e008"
}

.glyphicon-film:before {
    content: "\e009"
}

.glyphicon-th-large:before {
    content: "\e010"
}

.glyphicon-th:before {
    content: "\e011"
}

.glyphicon-th-list:before {
    content: "\e012"
}

.glyphicon-ok:before {
    content: "\e013"
}

.glyphicon-remove:before {
    content: "\e014"
}

.glyphicon-zoom-in:before {
    content: "\e015"
}

.glyphicon-zoom-out:before {
    content: "\e016"
}

.glyphicon-off:before {
    content: "\e017"
}

.glyphicon-signal:before {
    content: "\e018"
}

.glyphicon-cog:before {
    content: "\e019"
}

.glyphicon-trash:before {
    content: "\e020"
}

.glyphicon-home:before {
    content: "\e021"
}

.glyphicon-file:before {
    content: "\e022"
}

.glyphicon-time:before {
    content: "\e023"
}

.glyphicon-road:before {
    content: "\e024"
}

.glyphicon-download-alt:before {
    content: "\e025"
}

.glyphicon-download:before {
    content: "\e026"
}

.glyphicon-upload:before {
    content: "\e027"
}

.glyphicon-inbox:before {
    content: "\e028"
}

.glyphicon-play-circle:before {
    content: "\e029"
}

.glyphicon-repeat:before {
    content: "\e030"
}

.glyphicon-refresh:before {
    content: "\e031"
}

.glyphicon-list-alt:before {
    content: "\e032"
}

.glyphicon-lock:before {
    content: "\e033"
}

.glyphicon-flag:before {
    content: "\e034"
}

.glyphicon-headphones:before {
    content: "\e035"
}

.glyphicon-volume-off:before {
    content: "\e036"
}

.glyphicon-volume-down:before {
    content: "\e037"
}

.glyphicon-volume-up:before {
    content: "\e038"
}

.glyphicon-qrcode:before {
    content: "\e039"
}

.glyphicon-barcode:before {
    content: "\e040"
}

.glyphicon-tag:before {
    content: "\e041"
}

.glyphicon-tags:before {
    content: "\e042"
}

.glyphicon-book:before {
    content: "\e043"
}

.glyphicon-bookmark:before {
    content: "\e044"
}

.glyphicon-print:before {
    content: "\e045"
}

.glyphicon-camera:before {
    content: "\e046"
}

.glyphicon-font:before {
    content: "\e047"
}

.glyphicon-bold:before {
    content: "\e048"
}

.glyphicon-italic:before {
    content: "\e049"
}

.glyphicon-text-height:before {
    content: "\e050"
}

.glyphicon-text-width:before {
    content: "\e051"
}

.glyphicon-align-left:before {
    content: "\e052"
}

.glyphicon-align-center:before {
    content: "\e053"
}

.glyphicon-align-right:before {
    content: "\e054"
}

.glyphicon-align-justify:before {
    content: "\e055"
}

.glyphicon-list:before {
    content: "\e056"
}

.glyphicon-indent-left:before {
    content: "\e057"
}

.glyphicon-indent-right:before {
    content: "\e058"
}

.glyphicon-facetime-video:before {
    content: "\e059"
}

.glyphicon-picture:before {
    content: "\e060"
}

.glyphicon-map-marker:before {
    content: "\e062"
}

.glyphicon-adjust:before {
    content: "\e063"
}

.glyphicon-tint:before {
    content: "\e064"
}

.glyphicon-edit:before {
    content: "\e065"
}

.glyphicon-share:before {
    content: "\e066"
}

.glyphicon-check:before {
    content: "\e067"
}

.glyphicon-move:before {
    content: "\e068"
}

.glyphicon-step-backward:before {
    content: "\e069"
}

.glyphicon-fast-backward:before {
    content: "\e070"
}

.glyphicon-backward:before {
    content: "\e071"
}

.glyphicon-play:before {
    content: "\e072"
}

.glyphicon-pause:before {
    content: "\e073"
}

.glyphicon-stop:before {
    content: "\e074"
}

.glyphicon-forward:before {
    content: "\e075"
}

.glyphicon-fast-forward:before {
    content: "\e076"
}

.glyphicon-step-forward:before {
    content: "\e077"
}

.glyphicon-eject:before {
    content: "\e078"
}

.glyphicon-chevron-left:before {
    content: "\e079"
}

.glyphicon-chevron-right:before {
    content: "\e080"
}

.glyphicon-plus-sign:before {
    content: "\e081"
}

.glyphicon-minus-sign:before {
    content: "\e082"
}

.glyphicon-remove-sign:before {
    content: "\e083"
}

.glyphicon-ok-sign:before {
    content: "\e084"
}

.glyphicon-question-sign:before {
    content: "\e085"
}

.glyphicon-info-sign:before {
    content: "\e086"
}

.glyphicon-screenshot:before {
    content: "\e087"
}

.glyphicon-remove-circle:before {
    content: "\e088"
}

.glyphicon-ok-circle:before {
    content: "\e089"
}

.glyphicon-ban-circle:before {
    content: "\e090"
}

.glyphicon-arrow-left:before {
    content: "\e091"
}

.glyphicon-arrow-right:before {
    content: "\e092"
}

.glyphicon-arrow-up:before {
    content: "\e093"
}

.glyphicon-arrow-down:before {
    content: "\e094"
}

.glyphicon-share-alt:before {
    content: "\e095"
}

.glyphicon-resize-full:before {
    content: "\e096"
}

.glyphicon-resize-small:before {
    content: "\e097"
}

.glyphicon-exclamation-sign:before {
    content: "\e101"
}

.glyphicon-gift:before {
    content: "\e102"
}

.glyphicon-leaf:before {
    content: "\e103"
}

.glyphicon-fire:before {
    content: "\e104"
}

.glyphicon-eye-open:before {
    content: "\e105"
}

.glyphicon-eye-close:before {
    content: "\e106"
}

.glyphicon-warning-sign:before {
    content: "\e107"
}

.glyphicon-plane:before {
    content: "\e108"
}

.glyphicon-calendar:before {
    content: "\e109"
}

.glyphicon-random:before {
    content: "\e110"
}

.glyphicon-comment:before {
    content: "\e111"
}

.glyphicon-magnet:before {
    content: "\e112"
}

.glyphicon-chevron-up:before {
    content: "\e113"
}

.glyphicon-chevron-down:before {
    content: "\e114"
}

.glyphicon-retweet:before {
    content: "\e115"
}

.glyphicon-shopping-cart:before {
    content: "\e116"
}

.glyphicon-folder-close:before {
    content: "\e117"
}

.glyphicon-folder-open:before {
    content: "\e118"
}

.glyphicon-resize-vertical:before {
    content: "\e119"
}

.glyphicon-resize-horizontal:before {
    content: "\e120"
}

.glyphicon-hdd:before {
    content: "\e121"
}

.glyphicon-bullhorn:before {
    content: "\e122"
}

.glyphicon-bell:before {
    content: "\e123"
}

.glyphicon-certificate:before {
    content: "\e124"
}

.glyphicon-thumbs-up:before {
    content: "\e125"
}

.glyphicon-thumbs-down:before {
    content: "\e126"
}

.glyphicon-hand-right:before {
    content: "\e127"
}

.glyphicon-hand-left:before {
    content: "\e128"
}

.glyphicon-hand-up:before {
    content: "\e129"
}

.glyphicon-hand-down:before {
    content: "\e130"
}

.glyphicon-circle-arrow-right:before {
    content: "\e131"
}

.glyphicon-circle-arrow-left:before {
    content: "\e132"
}

.glyphicon-circle-arrow-up:before {
    content: "\e133"
}

.glyphicon-circle-arrow-down:before {
    content: "\e134"
}

.glyphicon-globe:before {
    content: "\e135"
}

.glyphicon-wrench:before {
    content: "\e136"
}

.glyphicon-tasks:before {
    content: "\e137"
}

.glyphicon-filter:before {
    content: "\e138"
}

.glyphicon-briefcase:before {
    content: "\e139"
}

.glyphicon-fullscreen:before {
    content: "\e140"
}

.glyphicon-dashboard:before {
    content: "\e141"
}

.glyphicon-paperclip:before {
    content: "\e142"
}

.glyphicon-heart-empty:before {
    content: "\e143"
}

.glyphicon-link:before {
    content: "\e144"
}

.glyphicon-phone:before {
    content: "\e145"
}

.glyphicon-pushpin:before {
    content: "\e146"
}

.glyphicon-usd:before {
    content: "\e148"
}

.glyphicon-gbp:before {
    content: "\e149"
}

.glyphicon-sort:before {
    content: "\e150"
}

.glyphicon-sort-by-alphabet:before {
    content: "\e151"
}

.glyphicon-sort-by-alphabet-alt:before {
    content: "\e152"
}

.glyphicon-sort-by-order:before {
    content: "\e153"
}

.glyphicon-sort-by-order-alt:before {
    content: "\e154"
}

.glyphicon-sort-by-attributes:before {
    content: "\e155"
}

.glyphicon-sort-by-attributes-alt:before {
    content: "\e156"
}

.glyphicon-unchecked:before {
    content: "\e157"
}

.glyphicon-expand:before {
    content: "\e158"
}

.glyphicon-collapse-down:before {
    content: "\e159"
}

.glyphicon-collapse-up:before {
    content: "\e160"
}

.glyphicon-log-in:before {
    content: "\e161"
}

.glyphicon-flash:before {
    content: "\e162"
}

.glyphicon-log-out:before {
    content: "\e163"
}

.glyphicon-new-window:before {
    content: "\e164"
}

.glyphicon-record:before {
    content: "\e165"
}

.glyphicon-save:before {
    content: "\e166"
}

.glyphicon-open:before {
    content: "\e167"
}

.glyphicon-saved:before {
    content: "\e168"
}

.glyphicon-import:before {
    content: "\e169"
}

.glyphicon-export:before {
    content: "\e170"
}

.glyphicon-send:before {
    content: "\e171"
}

.glyphicon-floppy-disk:before {
    content: "\e172"
}

.glyphicon-floppy-saved:before {
    content: "\e173"
}

.glyphicon-floppy-remove:before {
    content: "\e174"
}

.glyphicon-floppy-save:before {
    content: "\e175"
}

.glyphicon-floppy-open:before {
    content: "\e176"
}

.glyphicon-credit-card:before {
    content: "\e177"
}

.glyphicon-transfer:before {
    content: "\e178"
}

.glyphicon-cutlery:before {
    content: "\e179"
}

.glyphicon-header:before {
    content: "\e180"
}

.glyphicon-compressed:before {
    content: "\e181"
}

.glyphicon-earphone:before {
    content: "\e182"
}

.glyphicon-phone-alt:before {
    content: "\e183"
}

.glyphicon-tower:before {
    content: "\e184"
}

.glyphicon-stats:before {
    content: "\e185"
}

.glyphicon-sd-video:before {
    content: "\e186"
}

.glyphicon-hd-video:before {
    content: "\e187"
}

.glyphicon-subtitles:before {
    content: "\e188"
}

.glyphicon-sound-stereo:before {
    content: "\e189"
}

.glyphicon-sound-dolby:before {
    content: "\e190"
}

.glyphicon-sound-5-1:before {
    content: "\e191"
}

.glyphicon-sound-6-1:before {
    content: "\e192"
}

.glyphicon-sound-7-1:before {
    content: "\e193"
}

.glyphicon-copyright-mark:before {
    content: "\e194"
}

.glyphicon-registration-mark:before {
    content: "\e195"
}

.glyphicon-cloud-download:before {
    content: "\e197"
}

.glyphicon-cloud-upload:before {
    content: "\e198"
}

.glyphicon-tree-conifer:before {
    content: "\e199"
}

.glyphicon-tree-deciduous:before {
    content: "\e200"
}

.glyphicon-cd:before {
    content: "\e201"
}

.glyphicon-save-file:before {
    content: "\e202"
}

.glyphicon-open-file:before {
    content: "\e203"
}

.glyphicon-level-up:before {
    content: "\e204"
}

.glyphicon-copy:before {
    content: "\e205"
}

.glyphicon-paste:before {
    content: "\e206"
}

.glyphicon-alert:before {
    content: "\e209"
}

.glyphicon-equalizer:before {
    content: "\e210"
}

.glyphicon-king:before {
    content: "\e211"
}

.glyphicon-queen:before {
    content: "\e212"
}

.glyphicon-pawn:before {
    content: "\e213"
}

.glyphicon-bishop:before {
    content: "\e214"
}

.glyphicon-knight:before {
    content: "\e215"
}

.glyphicon-baby-formula:before {
    content: "\e216"
}

.glyphicon-tent:before {
    content: "\26fa"
}

.glyphicon-blackboard:before {
    content: "\e218"
}

.glyphicon-bed:before {
    content: "\e219"
}

.glyphicon-apple:before {
    content: "\f8ff"
}

.glyphicon-erase:before {
    content: "\e221"
}

.glyphicon-hourglass:before {
    content: "\231b"
}

.glyphicon-lamp:before {
    content: "\e223"
}

.glyphicon-duplicate:before {
    content: "\e224"
}

.glyphicon-piggy-bank:before {
    content: "\e225"
}

.glyphicon-scissors:before {
    content: "\e226"
}

.glyphicon-bitcoin:before {
    content: "\e227"
}

.glyphicon-btc:before {
    content: "\e227"
}

.glyphicon-xbt:before {
    content: "\e227"
}

.glyphicon-yen:before {
    content: "\00a5"
}

.glyphicon-jpy:before {
    content: "\00a5"
}

.glyphicon-ruble:before {
    content: "\20bd"
}

.glyphicon-rub:before {
    content: "\20bd"
}

.glyphicon-scale:before {
    content: "\e230"
}

.glyphicon-ice-lolly:before {
    content: "\e231"
}

.glyphicon-ice-lolly-tasted:before {
    content: "\e232"
}

.glyphicon-education:before {
    content: "\e233"
}

.glyphicon-option-horizontal:before {
    content: "\e234"
}

.glyphicon-option-vertical:before {
    content: "\e235"
}

.glyphicon-menu-hamburger:before {
    content: "\e236"
}

.glyphicon-modal-window:before {
    content: "\e237"
}

.glyphicon-oil:before {
    content: "\e238"
}

.glyphicon-grain:before {
    content: "\e239"
}

.glyphicon-sunglasses:before {
    content: "\e240"
}

.glyphicon-text-size:before {
    content: "\e241"
}

.glyphicon-text-color:before {
    content: "\e242"
}

.glyphicon-text-background:before {
    content: "\e243"
}

.glyphicon-object-align-top:before {
    content: "\e244"
}

.glyphicon-object-align-bottom:before {
    content: "\e245"
}

.glyphicon-object-align-horizontal:before {
    content: "\e246"
}

.glyphicon-object-align-left:before {
    content: "\e247"
}

.glyphicon-object-align-vertical:before {
    content: "\e248"
}

.glyphicon-object-align-right:before {
    content: "\e249"
}

.glyphicon-triangle-right:before {
    content: "\e250"
}

.glyphicon-triangle-left:before {
    content: "\e251"
}

.glyphicon-triangle-bottom:before {
    content: "\e252"
}

.glyphicon-triangle-top:before {
    content: "\e253"
}

.glyphicon-console:before {
    content: "\e254"
}

.glyphicon-superscript:before {
    content: "\e255"
}

.glyphicon-subscript:before {
    content: "\e256"
}

.glyphicon-menu-left:before {
    content: "\e257"
}

.glyphicon-menu-right:before {
    content: "\e258"
}

.glyphicon-menu-down:before {
    content: "\e259"
}

.glyphicon-menu-up:before {
    content: "\e260"
}

@font-face {
    font-family: startui;
    src: url(../fonts/startui.eot);
    src: url(../fonts/startui.eot?#iefix) format("embedded-opentype"), url(../fonts/startui.woff) format("woff"), url(../fonts/startui.ttf) format("truetype"), url(../fonts/startui.svg#startui) format("svg");
    font-weight: 400;
    font-style: normal
}

[data-icon]:before {
    content: attr(data-icon);
    font-family: startui!important;
    font-style: normal!important;
    font-weight: 400!important;
    font-variant: normal!important;
    text-transform: none!important;
    speak: none;
    line-height: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    vertical-align: middle;
    position: relative;
    top: -.15em
}

[class*=" font-icon-"]:before,
[class^=font-icon-]:before {
    font-family: startui!important;
    font-style: normal!important;
    font-weight: 400!important;
    font-variant: normal!important;
    text-transform: none!important;
    speak: none;
    line-height: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    vertical-align: middle;
    position: relative;
    top: -.15em
}

.font-icon-alarm:before {
    content: "\62"
}

.font-icon-build:before {
    content: "\63"
}

.font-icon-burger:before {
    content: "\64"
}

.font-icon-calend:before {
    content: "\65"
}

.font-icon-cart:before {
    content: "\66"
}

.font-icon-cogwheel:before {
    content: "\67"
}

.font-icon-comments:before {
    content: "\68"
}

.font-icon-contacts:before {
    content: "\69"
}

.font-icon-dashboard:before {
    content: "\6a"
}

.font-icon-dots:before {
    content: "\6b"
}

.font-icon-expand:before {
    content: "\6c"
}

.font-icon-filter:before {
    content: "\6d"
}

.font-icon-help:before {
    content: "\6e"
}

.font-icon-home:before {
    content: "\6f"
}

.font-icon-page:before {
    content: "\70"
}

.font-icon-pencil:before {
    content: "\71"
}

.font-icon-re:before {
    content: "\72"
}

.font-icon-rollup:before {
    content: "\73"
}

.font-icon-search:before {
    content: "\74"
}

.font-icon-share:before {
    content: "\75"
}

.font-icon-star:before {
    content: "\76"
}

.font-icon-users:before {
    content: "\77"
}

.font-icon-wallet:before {
    content: "\78"
}

.font-icon-minus:before {
    content: "\79"
}

.font-icon-pencil-thin:before {
    content: "\7a"
}

.font-icon-close:before {
    content: "\41"
}

.font-icon-speed:before {
    content: "\61"
}

.font-icon-menu-addl:before {
    content: "\42"
}

.font-icon-search:before {
    content: "\74"
}

.font-icon-edit:before {
    content: "\43"
}

.font-icon-chart:before {
    content: "\44"
}

.font-icon-zigzag:before {
    content: "\45"
}

.font-icon-tablet:before {
    content: "\46"
}

.font-icon-widget:before {
    content: "\47"
}

.font-icon-map:before {
    content: "\48"
}

.font-icon-chart-2:before {
    content: "\49"
}

.font-icon-doc:before {
    content: "\4a"
}

.font-icon-question:before {
    content: "\4b"
}

.font-icon-user:before {
    content: "\4c"
}

.font-icon-notebook:before {
    content: "\4d"
}

.font-icon-mail:before {
    content: "\4e"
}

.font-icon-close-2:before {
    content: "\4f"
}

.font-icon-pen:before {
    content: "\50"
}

.font-icon-arrow-down:before {
    content: "\51"
}

.font-icon-arrow-left:before {
    content: "\52"
}

.font-icon-arrow-right:before {
    content: "\53"
}

.font-icon-arrow-top:before {
    content: "\54"
}

.font-icon-check-circle:before {
    content: "\55"
}

.font-icon-cam-photo:before {
    content: "\56"
}

.font-icon-cam-video:before {
    content: "\57"
}

.font-icon-sound:before {
    content: "\58"
}

.font-icon-earth:before {
    content: "\59"
}

.font-icon-mail-2:before {
    content: "\5a"
}

.font-icon-upload:before {
    content: "\30"
}

.font-icon-dropbox:before {
    content: "\31"
}

.font-icon-google-drive:before {
    content: "\32"
}

.font-icon-yandex-disk:before {
    content: "\33"
}

.font-icon-box:before {
    content: "\34"
}

.font-icon-arrow-square-down:before {
    content: "\35"
}

.font-icon-refresh:before {
    content: "\36"
}

.font-icon-list-square:before {
    content: "\37"
}

.font-icon-list-rotate:before {
    content: "\38"
}

.font-icon-download:before {
    content: "\39"
}

.font-icon-heart:before {
    content: "\21"
}

.font-icon-check-bird:before {
    content: "\22"
}

.font-icon-clock:before {
    content: "\23"
}

.font-icon-trash:before {
    content: "\24"
}

.font-icon-circle-lined-error:before {
    content: "\25"
}

.font-icon-circle-lined-i:before {
    content: "\26"
}

.font-icon-circle-lined-smile:before {
    content: "\27"
}

.font-icon-circle-lined-success:before {
    content: "\28"
}

.font-icon-one-drive:before {
    content: "\29"
}

.font-icon-cloud-upload-2:before {
    content: "\2a"
}

.font-icon-plus:before {
    content: "\2b"
}

.font-icon-minus-1:before {
    content: "\2c"
}

.font-icon-arrow-square-up:before {
    content: "\2d"
}

.font-icon-revers:before {
    content: "\2e"
}

.font-icon-import:before {
    content: "\2f"
}

.font-icon-award:before {
    content: "\3a"
}

.font-icon-case:before {
    content: "\3b"
}

.font-icon-earth-bordered:before {
    content: "\3c"
}

.font-icon-comment:before {
    content: "\3d"
}

.font-icon-eye:before {
    content: "\3e"
}

.font-icon-fb-fill:before {
    content: "\3f"
}

.font-icon-in-fill:before {
    content: "\40"
}

.font-icon-lamp:before {
    content: "\5b"
}

.font-icon-picture:before {
    content: "\5d"
}

.font-icon-pdf-fill:before {
    content: "\5e"
}

.font-icon-notebook-bird:before {
    content: "\5f"
}

.font-icon-quote:before {
    content: "\60"
}

.font-icon-vk-fill:before {
    content: "\7b"
}

.font-icon-video-fill:before {
    content: "\7c"
}

.font-icon-tw-fill:before {
    content: "\7d"
}

.font-icon-answer:before {
    content: "\7e"
}

.font-icon-archive:before {
    content: "\5c"
}

.font-icon-case-2:before {
    content: "\e000"
}

.font-icon-clip:before {
    content: "\e001"
}

.font-icon-cloud:before {
    content: "\e002"
}

.font-icon-comments-2:before {
    content: "\e003"
}

.font-icon-del:before {
    content: "\e004"
}

.font-icon-event:before {
    content: "\e005"
}

.font-icon-download-3:before {
    content: "\e006"
}

.font-icon-download-2:before {
    content: "\e007"
}

.font-icon-dots-vert-square:before {
    content: "\e008"
}

.font-icon-fire:before {
    content: "\e009"
}

.font-icon-folder:before {
    content: "\e00a"
}

.font-icon-lock:before {
    content: "\e00b"
}

.font-icon-ok:before {
    content: "\e00c"
}

.font-icon-picture-2:before {
    content: "\e00d"
}

.font-icon-pin:before {
    content: "\e00e"
}

.font-icon-refresh-2:before {
    content: "\e00f"
}

.font-icon-view-cascade:before {
    content: "\e010"
}

.font-icon-users-group:before {
    content: "\e011"
}

.font-icon-upload-2:before {
    content: "\e012"
}

.font-icon-view-grid:before {
    content: "\e013"
}

.font-icon-view-rows:before {
    content: "\e014"
}

.font-icon-warning:before {
    content: "\e015"
}

.font-icon-facebook:before {
    content: "\e016"
}

.font-icon-instagram:before {
    content: "\e017"
}

.font-icon-google-plus:before {
    content: "\e018"
}

.font-icon-linkedin:before {
    content: "\e019"
}

.font-icon-twitter:before {
    content: "\e01a"
}

.font-icon-phone:before {
    content: "\e01b"
}

.font-icon-gp-fill:before {
    content: "\e01c"
}

.font-icon-ok-fill:before {
    content: "\e01d"
}

.font-icon-editor-align-center:before {
    content: "\e01e"
}

.font-icon-editor-align-justify:before {
    content: "\e01f"
}

.font-icon-editor-align-left:before {
    content: "\e020"
}

.font-icon-editor-align-right:before {
    content: "\e021"
}

.font-icon-editor-bold:before {
    content: "\e022"
}

.font-icon-editor-code:before {
    content: "\e023"
}

.font-icon-editor-eraser:before {
    content: "\e024"
}

.font-icon-editor-font:before {
    content: "\e025"
}

.font-icon-editor-fullscreen:before {
    content: "\e026"
}

.font-icon-editor-help:before {
    content: "\e027"
}

.font-icon-editor-img:before {
    content: "\e028"
}

.font-icon-editor-link:before {
    content: "\e029"
}

.font-icon-editor-list:before {
    content: "\e02a"
}

.font-icon-editor-magic:before {
    content: "\e02b"
}

.font-icon-editor-numeric-list:before {
    content: "\e02c"
}

.font-icon-editor-table:before {
    content: "\e02d"
}

.font-icon-editor-underline:before {
    content: "\e02e"
}

.font-icon-editor-video:before {
    content: "\e02f"
}

.font-icon-alarm-2:before {
    content: "\e030"
}

.font-icon-alarm-rotate:before {
    content: "\e031"
}

.font-icon-binoculars:before {
    content: "\e032"
}

.font-icon-cart-2:before {
    content: "\e033"
}

.font-icon-card:before {
    content: "\e034"
}

.font-icon-bookmark:before {
    content: "\e035"
}

.font-icon-chart-3:before {
    content: "\e036"
}

.font-icon-chart-4:before {
    content: "\e037"
}

.font-icon-check-square:before {
    content: "\e038"
}

.font-icon-del-circle:before {
    content: "\e039"
}

.font-icon-comp:before {
    content: "\e03a"
}

.font-icon-cloud-download:before {
    content: "\e03b"
}

.font-icon-downloaded:before {
    content: "\e03c"
}

.font-icon-link:before {
    content: "\e03d"
}

.font-icon-i-circle:before {
    content: "\e03e"
}

.font-icon-notebook-lines:before {
    content: "\e03f"
}

.font-icon-pdf:before {
    content: "\e040"
}

.font-icon-pen-square:before {
    content: "\e041"
}

.font-icon-play-prev:before {
    content: "\e042"
}

.font-icon-play-next:before {
    content: "\e043"
}

.font-icon-play-circle:before {
    content: "\e044"
}

.font-icon-play:before {
    content: "\e045"
}

.font-icon-pin-2:before {
    content: "\e046"
}

.font-icon-server:before {
    content: "\e047"
}

.font-icon-warning-circle:before {
    content: "\e048"
}

.font-icon-users-two:before {
    content: "\e049"
}

.font-icon-weather-cloud:before {
    content: "\e04a"
}

.font-icon-weather-cloud-circles:before {
    content: "\e04b"
}

.font-icon-weather-cloud-drops-lightning:before {
    content: "\e04c"
}

.font-icon-weather-cloud-moon:before {
    content: "\e04d"
}

.font-icon-weather-cloud-one-circle:before {
    content: "\e04e"
}

.font-icon-weather-cloud-one-drop:before {
    content: "\e04f"
}

.font-icon-weather-cloud-rain-snow:before {
    content: "\e050"
}

.font-icon-weather-cloud-sun:before {
    content: "\e051"
}

.font-icon-weather-cloud-two-circles:before {
    content: "\e052"
}

.font-icon-weather-cloud-two-drops:before {
    content: "\e053"
}

.font-icon-weather-cloud-two-snow:before {
    content: "\e054"
}

.font-icon-weather-clouds:before {
    content: "\e055"
}

.font-icon-weather-clound-lightning:before {
    content: "\e056"
}

.font-icon-weather-sun:before {
    content: "\e057"
}

.font-icon-weather-snowflake:before {
    content: "\e058"
}

.font-icon-weather-snow:before {
    content: "\e059"
}

.font-icon-weather-rain:before {
    content: "\e05a"
}

.font-icon-weather-one-snow:before {
    content: "\e05b"
}

.font-icon-weather-moon-small-cloud:before {
    content: "\e05c"
}

.font-icon-weather-moon-cloud-rain:before {
    content: "\e05d"
}

.font-icon-weather-moon-cloud:before {
    content: "\e05e"
}

.font-icon-weather-moon:before {
    content: "\e05f"
}

.font-icon-weather-lightning:before {
    content: "\e060"
}

.font-icon-weather-house-water:before {
    content: "\e061"
}

.font-icon-weather-funnel:before {
    content: "\e062"
}

.font-icon-weather-drop:before {
    content: "\e063"
}

.font-icon-weather-sun-cloud:before {
    content: "\e064"
}

.font-icon-weather-sun-clouds:before {
    content: "\e065"
}

.font-icon-weather-sun-rain:before {
    content: "\e066"
}

.font-icon-weather-thermometer:before {
    content: "\e067"
}

.font-icon-weather-umbrella:before {
    content: "\e068"
}

.font-icon-weather-waves:before {
    content: "\e069"
}

.font-icon-wp:before {
    content: "\e06a"
}

.font-icon-player-full-screen:before {
    content: "\e06b"
}

.font-icon-player-next:before {
    content: "\e06c"
}

.font-icon-player-settings:before {
    content: "\e06d"
}

.font-icon-player-sound:before {
    content: "\e06e"
}

.font-icon-player-subtitres:before {
    content: "\e06f"
}

.font-icon-player-wide-screen:before {
    content: "\e070"
}

.font-icon-case-3:before {
    content: "\e071"
}

.font-icon-github:before {
    content: "\e072"
}

.font-icon-learn:before {
    content: "\e073"
}

.font-icon-play-next-square:before {
    content: "\e074"
}

.font-icon-play-prev-square:before {
    content: "\e075"
}

.font-icon-play-square:before {
    content: "\e076"
}

.font-icon-picture-double:before {
    content: "\e077"
}

.font-icon-snippet:before {
    content: "\e078"
}

.font-icon-post:before {
    content: "\e079"
}

.font-icon-plus-1:before {
    content: "\e07a"
}

@font-face {
    font-family: 'Proxima Nova';
    src: url(../fonts/Proxima_Nova_Regular.eot);
    src: url(../fonts/Proxima_Nova_Regular.eot) format('embedded-opentype'), url(../fonts/Proxima_Nova_Regular.woff2) format('woff2'), url(../fonts/Proxima_Nova_Regular.woff) format('woff'), url(../fonts/Proxima_Nova_Regular.ttf) format('truetype'), url(../fonts/Proxima_Nova_Regular.svg#Proxima_Nova_Regular) format('svg');
    font-style: normal;
    font-weight: 400
}

@font-face {
    font-family: 'Proxima Nova';
    src: url(../fonts/Proxima_Nova_Regular_Italic.eot);
    src: url(../fonts/Proxima_Nova_Regular_Italic.eot) format('embedded-opentype'), url(../fonts/Proxima_Nova_Regular_Italic.woff2) format('woff2'), url(../fonts/Proxima_Nova_Regular_Italic.woff) format('woff'), url(../fonts/Proxima_Nova_Regular_Italic.ttf) format('truetype'), url(../fonts/Proxima_Nova_Regular_Italic.svg#Proxima_Nova_Regular_Italic) format('svg');
    font-style: italic;
    font-weight: 400
}

@font-face {
    font-family: 'Proxima Nova';
    src: url(../fonts/Proxima_Nova_Bold.eot);
    src: url(../fonts/Proxima_Nova_Bold.eot) format('embedded-opentype'), url(../fonts/Proxima_Nova_Bold.woff2) format('woff2'), url(../fonts/Proxima_Nova_Bold.woff) format('woff'), url(../fonts/Proxima_Nova_Bold.ttf) format('truetype'), url(../fonts/Proxima_Nova_Bold.svg#Proxima_Nova_Bold) format('svg');
    font-style: normal;
    font-weight: 700
}

@font-face {
    font-family: 'Proxima Nova';
    src: url(../fonts/Proxima_Nova_Bold_Italic.eot);
    src: url(../fonts/Proxima_Nova_Bold_Italic.eot) format('embedded-opentype'), url(../fonts/Proxima_Nova_Bold_Italic.woff2) format('woff2'), url(../fonts/Proxima_Nova_Bold_Italic.woff) format('woff'), url(../fonts/Proxima_Nova_Bold_Italic.ttf) format('truetype'), url(../fonts/Proxima_Nova_Bold_Italic.svg#Proxima_Nova_Bold_Italic) format('svg');
    font-style: italic;
    font-weight: 700
}

@font-face {
    font-family: 'Proxima Nova';
    src: url(../fonts/Proxima_Nova_Semibold.eot);
    src: url(../fonts/Proxima_Nova_Semibold.eot) format('embedded-opentype'), url(../fonts/Proxima_Nova_Semibold.woff2) format('woff2'), url(../fonts/Proxima_Nova_Semibold.woff) format('woff'), url(../fonts/Proxima_Nova_Semibold.ttf) format('truetype'), url(../fonts/Proxima_Nova_Semibold.svg#Proxima_Nova_Semibold) format('svg');
    font-style: normal;
    font-weight: 600
}

@font-face {
    font-family: 'Proxima Nova';
    src: url(../fonts/Proxima_Nova_Semibold_Italic.eot);
    src: url(../fonts/Proxima_Nova_Semibold_Italic.eot) format('embedded-opentype'), url(../fonts/Proxima_Nova_Semibold_Italic.woff2) format('woff2'), url(../fonts/Proxima_Nova_Semibold_Italic.woff) format('woff'), url(../fonts/Proxima_Nova_Semibold_Italic.ttf) format('truetype'), url(../fonts/Proxima_Nova_Semibold_Italic.svg#Proxima_Nova_Semibold_Italic) format('svg');
    font-style: italic;
    font-weight: 600
}

@font-face {
    font-family: 'Proxima Nova';
    src: url(../fonts/Proxima_Nova_Light.eot);
    src: url(../fonts/Proxima_Nova_Light.eot) format('embedded-opentype'), url(../fonts/Proxima_Nova_Light.woff2) format('woff2'), url(../fonts/Proxima_Nova_Light.woff) format('woff'), url(../fonts/Proxima_Nova_Light.ttf) format('truetype'), url(../fonts/Proxima_Nova_Light.svg#Proxima_Nova_Light) format('svg');
    font-style: normal;
    font-weight: 300
}

@font-face {
    font-family: 'Proxima Nova';
    src: url(../fonts/Proxima_Nova_Light_Italic.eot);
    src: url(../fonts/Proxima_Nova_Light_Italic.eot) format('embedded-opentype'), url(../fonts/Proxima_Nova_Light_Italic.woff2) format('woff2'), url(../fonts/Proxima_Nova_Light_Italic.woff) format('woff'), url(../fonts/Proxima_Nova_Light_Italic.ttf) format('truetype'), url(../fonts/Proxima_Nova_Light_Italic.svg#Proxima_Nova_Light_Italic) format('svg');
    font-style: italic;
    font-weight: 300
}

body,
button,
html,
input,
select,
textarea {
    color: #343434;
    font-family: 'Proxima Nova', sans-serif;
    line-height: 1.4;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -o-font-smoothing: antialiased
}

a,
button {
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out
}

a,
a:focus,
a:hover {
    color: #0082c6;
    text-decoration: none;
    cursor: pointer;
    outline: 0!important
}

a:focus:hover,
a:hover,
a:hover:hover {
    text-decoration: none
}

.color-black-blue {
    color: #323a44!important
}

.color-blue-grey {
    color: #6c7a86!important
}

.color-blue-grey-lighter {
    color: #919fa9!important
}

.color-blue {
    color: #00a8ff!important
}

.color-purple {
    color: #ac6bec!important
}

.color-yellow {
    color: #fed832!important
}

.color-lime {
    color: #afed3e!important
}

.color-green {
    color: #46c35f!important
}

.color-red {
    color: #fa424a!important
}

.color-orange {
    color: #fdad2a!important
}

.semibold {
    font-weight: 600
}

.uppercase {
    text-transform: uppercase
}

.font-16 {
    font-size: 1rem
}

.font-14 {
    font-size: .875rem
}

.font-11 {
    font-size: .6875rem
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: normal;
    color: rgb(19, 30, 34);
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin: 0px;
    padding: 0px;
    margin-bottom: 20px;
}

h1 .text-muted,
h2 .text-muted,
h3 .text-muted,
h4 .text-muted,
h5 .text-muted,
h6 .text-muted {
    display: inline;
    font-size: 80%
}

h1 .text-muted.smaller,
h2 .text-muted.smaller,
h3 .text-muted.smaller,
h4 .text-muted.smaller,
h5 .text-muted.smaller,
h6 .text-muted.smaller {
    font-size: 65%
}

h1 .label,
h2 .label,
h3 .label,
h4 .label,
h5 .label,
h6 .label {
    font-size: 75%!important
}

h1.with-border,
h2.with-border,
h3.with-border,
h4.with-border,
h5.with-border,
h6.with-border {
    border-bottom: solid 1px #d8e2e7;
    padding-bottom: .8rem
}

h1 {
    font-size: 2.5rem;
    margin-top: 0
}

h2 {
    font-size: 2rem
}

h3 {
    font-size: 1.75rem
}

h4 {
    font-size: 1.5rem
}

h5 {
    font-size: 1.25rem
}

h6 {
    font-size: 1rem
}

.text-block {
    zoom: 1;
    font-size: .8125rem;
    line-height: 1.4
}

.text-block:after,
.text-block:before {
    content: " ";
    display: table
}

.text-block:after {
    clear: both
}

.text-block.text-block-typical {
    font-size: 1rem
}

.text-block p {
    margin: .8em 0
}

.text-block a {
    text-decoration: none;
    color: #0082c6;
    border-bottom: solid 1px rgba(0, 130, 198, .3)
}

.text-block a:hover {
    border-bottom-color: transparent
}

.text-block ol,
.text-block ul {
    margin: .5em 0 .5em 1.5rem
}

.text-block ol li,
.text-block ul li {
    margin: 0 0 .5em
}

.text-block ul li {
    padding: 0 0 0 .8125rem;
    position: relative
}

.text-block ul li:before {
    content: '';
    display: block;
    width: 5px;
    height: 5px;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background: #29313d;
    position: absolute;
    left: 0;
    top: .5em
}

.text-block ul li ul {
    margin-bottom: 0;
    margin-left: .8125rem;
    margin-top: .3em
}

.text-block ul li li:before {
    background-color: #fff;
    border: solid 1px #29313d
}

.text-block.bs {
    font-size: 1rem
}

mark {
    background: #e4f6fe
}

.blockquote {
    margin: 2rem 0;
    padding: .75rem 0 .75rem 1.375rem;
    border-left: solid 4px #00a8ff;
    background: #f6f8fa;
    font-size: 1rem
}

.blockquote p {
    margin-top: 0
}

.blockquote-footer {
    font-size: .8125rem
}

code {
    color: #6c7a86;
    background: #ecf2f5;
    padding: 0 .4rem 2px;
    font-size: .9rem
}

pre.code {
    border: solid 2px #adb7be;
    padding: 1.375rem;
    margin: 2rem 0
}

[placeholder],
input[placeholder] {
    color: #8e9fa7
}

::-webkit-input-placeholder {
    color: #8e9fa7!important
}

::-moz-placeholder {
    color: #8e9fa7!important;
    opacity: 1!important
}

:-moz-placeholder {
    color: #8e9fa7!important;
    opacity: 1!important
}

::-moz-placeholder {
    color: #8e9fa7!important
}

:-ms-input-placeholder {
    color: #8e9fa7!important
}

label {
    margin: 0;
    display: block
}

label.error {
    color: #fa424a
}

.form-label {
    display: block;
    margin-bottom: 6px;
    font-size: 1rem
}

.form-label .font-icon {
    margin: 0 4px 0 0;
    color: #adb7be
}

.form-control {
    border: solid 1px rgba(197, 214, 222, .7);
    -webkit-box-shadow: none;
    box-shadow: none;
    font-size: 1rem;
    color: #343434!important
}

.form-control:focus {
    border-color: #c5d6de
}

.form-control.form-control-error {
    background-color: #fff6f6;
    border-color: #fa424a;
    color: #fa424a!important
}

.form-control:disabled {
    background-color: #eceff4
}

.form-control.form-control-rounded {
    -webkit-border-radius: 25rem;
    border-radius: 25rem
}

.form-control.form-control-sm {
    padding-top: .225rem;
    padding-bottom: .225rem
}

.form-control.form-control-success {
    border-color: #46c35f;
    background-position-y: 49%
}

.form-control.form-control-danger {
    border-color: #fa424a;
    background-position-y: 49%
}

.form-control.form-control-warning {
    border-color: #f29824;
    background-position-y: 49%
}

.form-control.form-control-blue-fill {
    border-color: #00a8ff;
    background-color: #e4f6fe
}

.form-control.form-control-green-fill {
    border-color: #46c35f;
    background-color: #edf9ee
}

.form-control.form-control-red-fill {
    border-color: #fa424a;
    background-color: #feecec
}

.form-control.form-control-purple-fill {
    border-color: #ac6bec;
    background-color: #eee2fc
}

.form-control.form-control-orange-fill {
    border-color: #f29824;
    background-color: #fdf4e6
}

textarea.form-control {
    resize: none;
    height: auto
}

.input-group-addon {
    background-color: #f6f8fa;
    border-color: rgba(197, 214, 222, .7);
    font-size: 1rem;
    font-weight: 600;
    color: #6c7a86
}

/* .error-list,
.text-muted {
    font-size: .875rem;
    color: #6c7a86;
    padding: 4px 0 0;
    display: block
} */

.form-group-radios {
    position: relative
}

.form-group-radios .form-label,
.form-group-radios .radio {
    margin-bottom: 15px
}

.error:not(a),
.form-group-error:not(a) {
    color: #fa424a
}

.error .form-control,
.form-group-error .form-control {
    border-color: #fa424a
}

.error .form-control-wrapper .form-control,
.form-group-error .form-control-wrapper .form-control {
    background-color: #fff6f6
}

.error.form-group-checkbox .checkbox label,
.form-group-error.form-group-checkbox .checkbox label {
    color: #343434
}

.form-control-wrapper {
    position: relative
}

.form-control-wrapper.form-control-icon-left .fa,
.form-control-wrapper.form-control-icon-left .font-icon,
.form-control-wrapper.form-control-icon-left .glyphicon,
.form-control-wrapper.form-control-icon-right .fa,
.form-control-wrapper.form-control-icon-right .font-icon,
.form-control-wrapper.form-control-icon-right .glyphicon {
    width: 38px;
    height: 38px;
    line-height: 40px;
    text-align: center;
    position: absolute;
    top: 0;
    color: rgba(145, 159, 169, .7)
}

.form-control-wrapper.form-control-icon-left .form-control-lg+.fa,
.form-control-wrapper.form-control-icon-left .form-control-lg+.font-icon,
.form-control-wrapper.form-control-icon-left .form-control-lg+.glyphicon,
.form-control-wrapper.form-control-icon-right .form-control-lg+.fa,
.form-control-wrapper.form-control-icon-right .form-control-lg+.font-icon,
.form-control-wrapper.form-control-icon-right .form-control-lg+.glyphicon {
    height: 48px;
    line-height: 48px
}

.form-control-wrapper.form-control-icon-left .form-control-sm+.fa,
.form-control-wrapper.form-control-icon-left .form-control-sm+.font-icon,
.form-control-wrapper.form-control-icon-left .form-control-sm+.glyphicon,
.form-control-wrapper.form-control-icon-right .form-control-sm+.fa,
.form-control-wrapper.form-control-icon-right .form-control-sm+.font-icon,
.form-control-wrapper.form-control-icon-right .form-control-sm+.glyphicon {
    height: 34px;
    line-height: 34px
}

.form-control-wrapper.form-control-icon-left .form-control {
    padding-left: 36px
}

.form-control-wrapper.form-control-icon-left .fa,
.form-control-wrapper.form-control-icon-left .font-icon,
.form-control-wrapper.form-control-icon-left .glyphicon {
    left: 0
}

.form-control-wrapper.form-control-icon-right .form-control {
    padding-right: 36px
}

.form-control-wrapper.form-control-icon-right .fa,
.form-control-wrapper.form-control-icon-right .font-icon,
.form-control-wrapper.form-control-icon-right .glyphicon {
    right: 0
}

.form-tooltip-error {
    background: #f95858;
    color: #fff!important;
    padding: 5px 8px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    position: absolute;
    right: 0;
    bottom: 100%;
    margin-bottom: 8px;
    max-width: 230px;
    font-size: .875rem
}

.form-tooltip-error:before {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    bottom: -4px;
    margin-left: -5px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: #f95858 transparent transparent transparent
}

.form-tooltip-error * {
    color: #fff!important
}

.form-group-checkbox .form-tooltip-error {
    right: 100%;
    bottom: 0;
    margin-bottom: 0;
    margin-right: 10px;
    min-width: 150px
}

.form-group-checkbox .form-tooltip-error:before {
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
    margin: 0!important;
    left: auto;
    right: -7px;
    bottom: 9px
}

.form-label .form-tooltip-error {
    position: relative;
    display: inline-block;
    margin: -4px 0 -4px 8px
}

.form-label .form-tooltip-error:before {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    bottom: 50%;
    margin: 0 0 -2px!important;
    left: -7px
}

.form-error-text-block {
    background-color: #fff6f6;
    border: dashed 1px #fa424a;
    padding: 12px 14px 3px;
    color: #fa424a;
    margin: 0 0 1rem;
    font-size: 1rem
}

.form-error-text-block li {
    margin: 0 0 10px;
    position: relative;
    padding: 0 0 0 12px
}

.form-error-text-block li:before {
    content: '';
    display: block;
    width: 5px;
    height: 5px;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background: #fa424a;
    position: absolute;
    left: 0;
    top: .5em;
    margin-top: -1px
}

.has-success .form-label {
    color: #46c35f
}

.has-danger .form-label {
    color: #fa424a
}

.has-warning .form-label {
    color: #f29824
}

.input-group.date .input-group-addon {
    background-color: #dbe4ea;
    border: none;
    border-color: #dbe4ea;
    color: rgba(108, 122, 134, .7);
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    padding: 0 .75rem
}

.input-group.date .input-group-addon .font-icon {
    vertical-align: middle;
    font-size: 1rem;
    position: relative;
    top: .08rem
}

.input-group.date .bootstrap-datetimepicker-widget+.input-group-addon,
.input-group.date .input-group-addon:hover {
    background-color: #00a8ff;
    border: none;
    border-color: #00a8ff;
    color: #fff
}

.input-group.clockpicker .input-group-addon .font-icon {
    vertical-align: middle;
    font-size: 1rem;
    position: relative;
    top: 0
}

.bootstrap-datetimepicker-widget {
    font-size: .8125rem
}

.bootstrap-datetimepicker-widget table thead tr:first-child th:hover {
    background: 0 0;
    color: #00a8ff!important
}

.bootstrap-datetimepicker-widget table thead tr:first-child th.next,
.bootstrap-datetimepicker-widget table thead tr:first-child th.prev {
    color: #adb7be
}

.bootstrap-datetimepicker-widget table td.day,
.bootstrap-datetimepicker-widget table th {
    width: 32px;
    height: 28px;
    line-height: 28px
}

.bootstrap-datetimepicker-widget table th.dow {
    font-weight: 600
}

.bootstrap-datetimepicker-widget table th.picker-switch {
    width: 165px
}

.bootstrap-datetimepicker-widget table td.day:hover {
    background: 0 0;
    color: #00a8ff
}

.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover {
    background-color: #00a8ff;
    color: #fff
}

.bootstrap-datetimepicker-widget table td span.active {
    background: #00a8ff
}

.bootstrap-datetimepicker-widget table td span:hover,
.bootstrap-datetimepicker-widget table td.minute:hover {
    background-color: #dbe4ea
}

.bootstrap-datetimepicker-widget table td span.timepicker-hour {
    background: 0 0!important
}

.bootstrap-datetimepicker-widget a[data-action].btn {
    padding: 0;
    background: 0 0;
    color: #adb7be;
    border: none;
    -webkit-transition: none;
    transition: none
}

.bootstrap-datetimepicker-widget a[data-action].btn:hover {
    color: #6c7a86
}

.bootstrap-datetimepicker-widget .accordion-toggle span {
    color: rgba(108, 122, 134, .7);
    background: #dbe4ea
}

.bootstrap-datetimepicker-widget .accordion-toggle span:hover {
    color: #fff;
    background-color: #00a8ff
}

.bootstrap-datetimepicker-widget.dropdown-menu:after,
.bootstrap-datetimepicker-widget.dropdown-menu:before {
    display: none
}

.bootstrap-datetimepicker-widget .glyphicon-calendar,
.bootstrap-datetimepicker-widget .glyphicon-time {
    font-family: startui;
    vertical-align: middle
}

.bootstrap-datetimepicker-widget .glyphicon-calendar:before,
.bootstrap-datetimepicker-widget .glyphicon-time:before {
    vertical-align: middle;
    position: relative;
    top: 0
}

.bootstrap-datetimepicker-widget .glyphicon-calendar:before {
    content: "\65"
}

.bootstrap-datetimepicker-widget .glyphicon-time:before {
    content: "\23"
}

.bootstrap-datetimepicker-widget .glyphicon-chevron-down:before,
.bootstrap-datetimepicker-widget .glyphicon-chevron-up:before {
    position: relative;
    top: 1px
}

.checkbox,
.checkbox-bird,
.checkbox-detailed,
.checkbox-slide,
.checkbox-toggle,
.radio {
    position: relative;
    margin-bottom: .75rem
}

.checkbox input,
.checkbox-bird input,
.checkbox-detailed input,
.checkbox-slide input,
.checkbox-toggle input,
.radio input {
    position: absolute;
    visibility: hidden
}

.checkbox input+label,
.checkbox-bird input+label,
.checkbox-detailed input+label,
.checkbox-slide input+label,
.checkbox-toggle input+label,
.radio input+label {
    position: relative;
    display: inline-block;
    font-size: 1rem;
    min-height: 18px;
    line-height: 18px;
    cursor: pointer
}

.checkbox input:disabled+label,
.checkbox-bird input:disabled+label,
.checkbox-detailed input:disabled+label,
.checkbox-slide input:disabled+label,
.checkbox-toggle input:disabled+label,
.radio input:disabled+label {
    cursor: not-allowed;
    color: #8e9fa7
}

.checkbox,
.radio {
    cursor: default
}

.checkbox input+label,
.radio input+label {
    z-index: 2;
    padding: 0 0 0 24px
}

.checkbox input+label:after,
.checkbox input+label:before,
.radio input+label:after,
.radio input+label:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0
}

.checkbox input+label:before,
.radio input+label:before {
    width: 16px;
    height: 16px;
    background: #fff;
    border: solid 1px #c5d6de
}

.checkbox input+label:hover:before,
.radio input+label:hover:before {
    background-color: #d9f2ff;
    border-color: #00a8ff
}

.checkbox input[type=checkbox]+label:after,
.radio input[type=checkbox]+label:after {
    display: inline-block;
    width: 16px;
    height: 16px;
    font-family: startui!important;
    font-style: normal!important;
    font-weight: 400!important;
    font-variant: normal!important;
    text-transform: none!important;
    speak: none;
    line-height: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    vertical-align: middle;
    position: relative;
    top: -.15em;
    position: absolute;
    text-align: center;
    top: 0;
    font-size: 11px;
    line-height: 16px
}

.checkbox input[type=checkbox]+label:before,
.radio input[type=checkbox]+label:before {
    -webkit-border-radius: 2px;
    border-radius: 2px
}

.checkbox input[type=radio]+label:before,
.radio input[type=radio]+label:before {
    -webkit-border-radius: 50%;
    border-radius: 50%
}

.checkbox input:checked+label:before,
.radio input:checked+label:before {
    border-color: #00a8ff
}

.checkbox input[type=checkbox]:checked+label:after,
.radio input[type=checkbox]:checked+label:after {
    content: "\22"
}

.checkbox input[type=radio]:checked+label:after,
.radio input[type=radio]:checked+label:after {
    width: 8px;
    height: 8px;
    background: #343434;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    left: 4px;
    top: 4px
}

.checkbox input:disabled+label:before,
.radio input:disabled+label:before {
    background-color: #eceff4;
    border-color: #dbe4eb
}

.checkbox input[type=checkbox]:disabled+label:after,
.radio input[type=checkbox]:disabled+label:after {
    color: rgba(52, 52, 52, .4)
}

.checkbox input[type=radio]:disabled+label:after,
.radio input[type=radio]:disabled+label:after {
    background-color: rgba(52, 52, 52, .4)
}

.checkbox.checkbox-only,
.radio.checkbox-only {
    padding: 0;
    width: 16px;
    height: 16px;
    margin: 0
}

.checkbox-slide input+label {
    padding: 0 0 0 52px
}

.checkbox-slide input+label:after,
.checkbox-slide input+label:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0
}

.checkbox-slide input+label:before {
    left: 1px;
    top: 7px;
    width: 42px;
    height: 4px;
    -webkit-border-radius: 25rem;
    border-radius: 25rem;
    background: #929faa;
    -webkit-transition: background .4s ease;
    transition: background .4s ease
}

.checkbox-slide input+label:after {
    width: 20px;
    height: 20px;
    top: -1px;
    border: solid 1px #c5d6de;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    -webkit-transition: left .4s ease;
    transition: left .4s ease;
    background: #fff;
    background: -webkit-linear-gradient(top, #fff 0, #e4f6ff 100%);
    background: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#e4f6ff));
    background: linear-gradient(to bottom, #fff 0, #e4f6ff 100%)
}

.checkbox-slide input+label:hover:after {
    border-color: #00a8ff
}

.checkbox-slide input:checked+label:before {
    background-color: #343434
}

.checkbox-slide input:checked+label:after {
    left: 24px
}

.checkbox-slide input:disabled+label:before {
    background-color: #c6d6df
}

.checkbox-slide input:disabled+label:after {
    background: #fcfefe;
    border-color: #c5d6de;
    background: -webkit-linear-gradient(top, #fcfefe 0, #edf2f5 100%);
    background: -webkit-gradient(linear, left top, left bottom, from(#fcfefe), to(#edf2f5));
    background: linear-gradient(to bottom, #fcfefe 0, #edf2f5 100%)
}

.checkbox-toggle input+label {
    padding: 0 0 0 52px
}

.checkbox-toggle input+label:after,
.checkbox-toggle input+label:before {
    content: '';
    display: block;
    -webkit-border-radius: 25rem;
    border-radius: 25rem;
    position: absolute;
    left: 0;
    top: -1px;
    height: 20px
}

.checkbox-toggle input+label:before {
    width: 43px;
    background: #929faa;
    -webkit-transition: background .4s ease;
    transition: background .4s ease
}

.checkbox-toggle input+label:after {
    width: 20px;
    border: solid 1px #929faa;
    -webkit-transition: left .4s ease;
    transition: left .4s ease;
    background: #fff;
    background: -webkit-linear-gradient(top, #fff 0, #eef4f7 100%);
    background: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#eef4f7));
    background: linear-gradient(to bottom, #fff 0, #eef4f7 100%)
}

.checkbox-toggle input+label:hover:after {
    border-color: #00a8ff;
    background: #fff;
    background: -webkit-linear-gradient(top, #fff 0, #e9f8ff 100%);
    background: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#e9f8ff));
    background: linear-gradient(to bottom, #fff 0, #e9f8ff 100%)
}

.checkbox-toggle input:checked+label:before {
    background-color: #00a8ff
}

.checkbox-toggle input:checked+label:after {
    left: 23px;
    border-color: #00a8ff;
    background: #fff;
    background: -webkit-linear-gradient(top, #fff 0, #e9f8ff 100%);
    background: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#e9f8ff));
    background: linear-gradient(to bottom, #fff 0, #e9f8ff 100%)
}

.checkbox-toggle input:disabled+label:before {
    background-color: #dbe4eb
}

.checkbox-toggle input:disabled+label:after {
    border-color: #dbe4eb;
    background: #fff;
    background: -webkit-linear-gradient(top, #fff 0, #eef4f7 100%);
    background: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#eef4f7));
    background: linear-gradient(to bottom, #fff 0, #eef4f7 100%)
}

.checkbox-toggle.-large {
    margin-top: 15px
}

.checkbox-toggle.-large input+label {
    padding: 0 0 0 69px;
    position: relative
}

.checkbox-toggle.-large input+label:after,
.checkbox-toggle.-large input+label:before {
    -webkit-border-radius: 35rem;
    border-radius: 35rem;
    height: 30px;
    position: absolute;
    top: -6px
}

.checkbox-toggle.-large input+label:before {
    width: 60px
}

.checkbox-toggle.-large input+label:after {
    width: 30px
}

.checkbox-toggle.-large input:checked+label:after {
    left: 30px
}

.checkbox-toggle.-extra-large {
    margin-top: 27px
}

.checkbox-toggle.-extra-large input+label {
    padding: 0 0 0 89px;
    position: relative
}

.checkbox-toggle.-extra-large input+label:after,
.checkbox-toggle.-extra-large input+label:before {
    -webkit-border-radius: 45rem;
    border-radius: 45rem;
    height: 40px;
    position: absolute;
    top: -12px
}

.checkbox-toggle.-extra-large input+label:before {
    width: 80px
}

.checkbox-toggle.-extra-large input+label:after {
    width: 40px
}

.checkbox-toggle.-extra-large input:checked+label:after {
    left: 40px
}

.checkbox-bird input+label {
    display: block;
    min-width: 20px;
    height: 20px;
    line-height: 20px;
    padding: 0 0 0 28px;
    position: relative
}

.checkbox-bird input+label:before {
    font-family: startui!important;
    font-style: normal!important;
    font-weight: 400!important;
    font-variant: normal!important;
    text-transform: none!important;
    speak: none;
    line-height: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    vertical-align: middle;
    position: relative;
    top: -.15em;
    content: "\55";
    color: rgba(173, 183, 190, .7);
    font-size: 1.125rem;
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 20px;
    height: 20px
}

.checkbox-bird input:checked+label:before {
    color: #00a8ff
}

.checkbox-bird.grey input:checked+label:before {
    color: #6b7a85
}

.checkbox-bird.green input:checked+label:before {
    color: #46c35f
}

.checkbox-bird.purple input:checked+label:before {
    color: #ac6bec
}

.checkbox-bird.orange input:checked+label:before {
    color: #f29824
}

.checkbox-bird.red input:checked+label:before {
    color: #fa424a
}

.checkbox-detailed {
    display: inline-block;
    vertical-align: top;
    margin: 0 12px 12px 0
}

.checkbox-detailed input+label {
    width: 200px;
    height: 84px;
    border: solid 1px #d8e2e7;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    padding: 0 10px 0 50px;
    line-height: 1.4
}

.checkbox-detailed input+label:before {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    border: solid 1px #c5d6de;
    position: absolute;
    left: 19px;
    top: 50%;
    margin-top: -6px;
    -webkit-border-radius: 50%;
    border-radius: 50%
}

.checkbox-detailed input:checked+label {
    background-color: #e4f6fe;
    border-color: #00a8ff
}

.checkbox-detailed input:checked+label:before {
    border-color: #00a8ff;
    background-color: #00a8ff
}

.checkbox-detailed .checkbox-detailed-tbl {
    display: table;
    width: 100%;
    height: 100%
}

.checkbox-detailed .checkbox-detailed-cell {
    display: table-cell;
    vertical-align: middle;
    padding: 5px 0
}

.checkbox-detailed .checkbox-detailed-title {
    font-weight: 600;
    display: block
}

.btn-group.bootstrap-select .font-icon {
    display: inline-block;
    color: #929faa;
    margin: -2px 8px -2px 0;
    font-size: 16px;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    vertical-align: middle;
    position: relative;
    top: -.05em
}

.btn-group.bootstrap-select .user-item {
    position: relative;
    display: inline-block;
    vertical-align: top;
    min-height: 20px;
    padding: 0 0 0 21px
}

.btn-group.bootstrap-select .user-item img {
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    left: -5px;
    top: 0;
    -webkit-border-radius: 50%;
    border-radius: 50%
}

.btn-group.bootstrap-select>.btn.dropdown-toggle {
    outline: 0!important;
    -webkit-box-shadow: none!important;
    box-shadow: none!important;
    background: #fff!important;
    font-weight: 400;
    color: #343434!important;
    border-color: #d8e2e7!important;
    padding-left: 0;
    vertical-align: middle;
    font-size: 1rem;
    text-align: left;
    padding-top: 0;
    padding-bottom: 0
}

.btn-group.bootstrap-select>.btn.dropdown-toggle:after {
    display: none
}

.btn-group.bootstrap-select>.btn.dropdown-toggle .filter-option {
    display: inline-block;
    position: relative;
    white-space: nowrap;
    padding-left: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    padding-top: .375rem;
    padding-bottom: .375rem;
    margin: 0
}

.btn-group.bootstrap-select>.btn.dropdown-toggle .bs-caret {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 23px;
    text-align: center;
    background: #dbe4ea
}

.btn-group.bootstrap-select>.btn.dropdown-toggle .bs-caret .caret {
    display: none!important
}

.btn-group.bootstrap-select>.btn.dropdown-toggle .bs-caret:after,
.btn-group.bootstrap-select>.btn.dropdown-toggle .bs-caret:before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    left: 50%;
    margin-left: -3px;
    top: 50%
}

.btn-group.bootstrap-select>.btn.dropdown-toggle .bs-caret:before {
    border-width: 0 3px 5px 3px;
    border-color: transparent transparent #6c7a86 transparent;
    margin-top: -6px
}

.btn-group.bootstrap-select>.btn.dropdown-toggle .bs-caret:after {
    border-width: 5px 3px 0 3px;
    border-color: #6c7a86 transparent transparent transparent;
    margin-top: 1px
}

.btn-group.bootstrap-select>.btn.dropdown-toggle .user-item img {
    top: 50%;
    margin-top: -10px
}

.btn-group.bootstrap-select .dropdown-menu {
    border-top: none;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    margin-top: -1px;
    font-size: 1rem;
    padding: 0
}

.btn-group.bootstrap-select .dropdown-menu a {
    display: block;
    padding: 5px 1rem;
    color: #343434;
    position: static;
    border: none;
    font-size: 1rem;
    font-weight: 400
}

.btn-group.bootstrap-select .dropdown-menu a:hover {
    color: #00a8ff
}

.btn-group.bootstrap-select .dropdown-menu a:hover .font-icon {
    color: #00a8ff
}

.btn-group.bootstrap-select .dropdown-menu .dropdown-header {
    font-size: 1rem;
    text-transform: none;
    color: #343434;
    font-weight: 600;
    padding-left: 1rem;
    padding-right: 1rem
}

.btn-group.bootstrap-select.open>.btn.dropdown-toggle {
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-color: #fff!important
}

.btn-group.bootstrap-select.open>.btn.dropdown-toggle .bs-caret {
    background: 0 0
}

.btn-group.bootstrap-select.open.dropup>.btn.dropdown-toggle {
    -webkit-border-radius: 0 0 3px 3px;
    border-radius: 0 0 3px 3px;
    border-top-color: #fff!important;
    border-bottom-color: #d8e2e7!important
}

.btn-group.bootstrap-select.open.dropup .dropdown-menu {
    -webkit-border-radius: .25rem .25rem 0 0;
    border-radius: .25rem .25rem 0 0;
    border-top: solid 1px #d8e2e7;
    border-bottom: none;
    margin-bottom: -1px
}

.btn-group.bootstrap-select.disabled {
    opacity: .65
}

.btn-group.bootstrap-select.disabled>.btn.dropdown-toggle.disabled {
    opacity: 1;
    background-color: #dbe4ea!important
}

.btn-group.bootstrap-select.disabled>.btn.dropdown-toggle.disabled .filter-option {
    color: #6c7a86
}

.btn-group.bootstrap-select.bootstrap-select-arrow>.btn.dropdown-toggle .bs-caret {
    background: 0 0
}

.btn-group.bootstrap-select.bootstrap-select-arrow>.btn.dropdown-toggle .bs-caret:after {
    display: none
}

.btn-group.bootstrap-select.bootstrap-select-arrow>.btn.dropdown-toggle .bs-caret:before {
    width: auto;
    height: auto;
    border: none;
    left: 0;
    margin-left: 0;
    top: 50%!important;
    margin-top: -8px;
    font-family: startui!important;
    font-style: normal!important;
    font-weight: 400!important;
    font-variant: normal!important;
    text-transform: none!important;
    speak: none;
    line-height: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    vertical-align: middle;
    position: relative;
    top: -.15em;
    content: "\51";
    color: #6c7a86;
    font-size: .6875rem;
    text-align: left
}

.select2,
.select2-arrow,
.select2-icon,
.select2-photo {
    width: 100%!important
}

.select2-dropdown {
    border-color: #d8e2e7;
    font-size: 1rem
}

.select2-dropdown.select2-dropdown--above {
    border-bottom: none;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0
}

.select2-results__option {
    padding: 5px 1rem;
    background: 0 0!important
}

.select2-results__option:hover {
    color: #00a8ff
}

.select2-results__option[role=group]:hover {
    color: #343434
}

.select2-results__option[role=group] .select2-results__option {
    padding-left: 2.25rem
}

.select2-results__option[aria-selected=true] {
    color: #00a8ff
}

.select2-container--arrow .select2-selection--single,
.select2-container--default .select2-selection--single,
.select2-container--white .select2-selection--single {
    border: none;
    -webkit-border-radius: 0;
    border-radius: 0;
    height: auto;
    background: 0 0
}

.select2-container--arrow .select2-selection--single .select2-selection__rendered,
.select2-container--default .select2-selection--single .select2-selection__rendered,
.select2-container--white .select2-selection--single .select2-selection__rendered {
    border: solid 1px #d8e2e7;
    -webkit-border-radius: .25rem;
    border-radius: .25rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #343434;
    padding: .375rem 25px .375rem 1rem;
    min-height: 38px;
    background: #fff
}

.select2-container--arrow .select2-selection--single .select2-selection__rendered .user-item img,
.select2-container--default .select2-selection--single .select2-selection__rendered .user-item img,
.select2-container--white .select2-selection--single .select2-selection__rendered .user-item img {
    top: 50%;
    margin-top: -10px
}

.select2-container--arrow .select2-selection--single .select2-selection__arrow,
.select2-container--default .select2-selection--single .select2-selection__arrow,
.select2-container--white .select2-selection--single .select2-selection__arrow {
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
    width: 23px;
    text-align: center;
    background: #dbe4ea;
    -webkit-border-radius: 0 .25rem .25rem 0;
    border-radius: 0 .25rem .25rem 0
}

.select2-container--arrow .select2-selection--single .select2-selection__arrow:after,
.select2-container--arrow .select2-selection--single .select2-selection__arrow:before,
.select2-container--default .select2-selection--single .select2-selection__arrow:after,
.select2-container--default .select2-selection--single .select2-selection__arrow:before,
.select2-container--white .select2-selection--single .select2-selection__arrow:after,
.select2-container--white .select2-selection--single .select2-selection__arrow:before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    left: 50%;
    margin-left: -3px;
    top: 50%
}

.select2-container--arrow .select2-selection--single .select2-selection__arrow:before,
.select2-container--default .select2-selection--single .select2-selection__arrow:before,
.select2-container--white .select2-selection--single .select2-selection__arrow:before {
    border-width: 0 3px 5px 3px;
    border-color: transparent transparent #6c7a86 transparent;
    margin-top: -6px
}

.select2-container--arrow .select2-selection--single .select2-selection__arrow:after,
.select2-container--default .select2-selection--single .select2-selection__arrow:after,
.select2-container--white .select2-selection--single .select2-selection__arrow:after {
    border-width: 5px 3px 0 3px;
    border-color: #6c7a86 transparent transparent transparent;
    margin-top: 1px
}

.select2-container--arrow .select2-selection--single .select2-selection__arrow b,
.select2-container--default .select2-selection--single .select2-selection__arrow b,
.select2-container--white .select2-selection--single .select2-selection__arrow b {
    display: none
}

.select2-container--arrow .select2-results__group,
.select2-container--default .select2-results__group,
.select2-container--white .select2-results__group {
    font-weight: 600;
    padding: 5px 1rem
}

.select2-container--arrow .select2-results__option--highlighted[aria-selected],
.select2-container--default .select2-results__option--highlighted[aria-selected],
.select2-container--white .select2-results__option--highlighted[aria-selected] {
    background: 0 0;
    color: #00a8ff
}

.select2-container--arrow .select2-results>.select2-results__options,
.select2-container--default .select2-results>.select2-results__options,
.select2-container--white .select2-results>.select2-results__options {
    max-height: 250px
}

.select2-container--arrow .select2-search--inline .select2-search__field,
.select2-container--default .select2-search--inline .select2-search__field,
.select2-container--white .select2-search--inline .select2-search__field {
    font-size: 1rem;
    padding-left: 11px
}

.select2-container--arrow .font-icon,
.select2-container--default .font-icon,
.select2-container--white .font-icon {
    display: inline-block;
    color: #929faa;
    margin: -2px 8px -2px 0;
    font-size: 1rem;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    vertical-align: middle;
    position: relative;
    top: -.05em
}

.select2-container--arrow .user-item,
.select2-container--default .user-item,
.select2-container--white .user-item {
    position: relative;
    display: inline-block;
    vertical-align: top;
    min-height: 20px;
    padding: 0 0 0 21px
}

.select2-container--arrow .user-item img,
.select2-container--default .user-item img,
.select2-container--white .user-item img {
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    left: -5px;
    top: 0;
    -webkit-border-radius: 50%;
    border-radius: 50%
}

.select2-container--arrow.select2-container--open .select2-selection--single .select2-selection__rendered,
.select2-container--default.select2-container--open .select2-selection--single .select2-selection__rendered,
.select2-container--white.select2-container--open .select2-selection--single .select2-selection__rendered {
    border-bottom-color: #fff;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0
}

.select2-container--arrow.select2-container--open .select2-selection--single .select2-selection__arrow,
.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow,
.select2-container--white.select2-container--open .select2-selection--single .select2-selection__arrow {
    background: 0 0
}

.select2-container--arrow.select2-container--open.select2-container--above .select2-selection--single .select2-selection__rendered,
.select2-container--default.select2-container--open.select2-container--above .select2-selection--single .select2-selection__rendered,
.select2-container--white.select2-container--open.select2-container--above .select2-selection--single .select2-selection__rendered {
    -webkit-border-radius: 0 0 .25rem .25rem;
    border-radius: 0 0 .25rem .25rem;
    border-top-color: #fff;
    border-bottom-color: #d8e2e7
}

.select2-container--arrow.select2-container--disabled,
.select2-container--default.select2-container--disabled,
.select2-container--white.select2-container--disabled {
    opacity: .65
}

.select2-container--arrow.select2-container--disabled .select2-selection--single,
.select2-container--default.select2-container--disabled .select2-selection--single,
.select2-container--white.select2-container--disabled .select2-selection--single {
    cursor: not-allowed;
    background: 0 0
}

.select2-container--arrow.select2-container--disabled .select2-selection--single .select2-selection__rendered,
.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__rendered,
.select2-container--white.select2-container--disabled .select2-selection--single .select2-selection__rendered {
    background-color: #dbe4ea;
    color: #6c7a86
}

.select2-container--arrow.select2-container--disabled .user-item img,
.select2-container--default.select2-container--disabled .user-item img,
.select2-container--white.select2-container--disabled .user-item img {
    opacity: .7
}

.select2-container--arrow .select2-selection--multiple,
.select2-container--default .select2-selection--multiple,
.select2-container--white .select2-selection--multiple {
    border-color: #d8e2e7;
    min-height: 38px
}

.select2-container--arrow .select2-selection--multiple .select2-selection__choice,
.select2-container--default .select2-selection--multiple .select2-selection__choice,
.select2-container--white .select2-selection--multiple .select2-selection__choice {
    color: #fff;
    background: #919fa9;
    border: none;
    font-weight: 600;
    font-size: 1rem;
    padding: 0 2rem 0 .5rem;
    height: 26px;
    line-height: 26px;
    position: relative
}

.select2-container--arrow .select2-selection--multiple .select2-selection__choice__remove,
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove,
.select2-container--white .select2-selection--multiple .select2-selection__choice__remove {
    position: absolute;
    right: 0;
    top: 1px;
    color: #fff!important;
    width: 1.5rem;
    text-align: center;
    font-size: 2rem
}

.select2-container--arrow .select2-selection--multiple .select2-selection__choice__remove:hover,
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover,
.select2-container--white .select2-selection--multiple .select2-selection__choice__remove:hover {
    opacity: .7
}

.select2-container--arrow.select2-container--focus .select2-selection--multiple,
.select2-container--default.select2-container--focus .select2-selection--multiple,
.select2-container--white.select2-container--focus .select2-selection--multiple {
    border-color: #c5d6de
}

.select2-container--arrow .select2-selection--single .select2-selection__arrow {
    background: 0 0
}

.select2-container--arrow .select2-selection--single .select2-selection__arrow:after {
    display: none
}

.select2-container--arrow .select2-selection--single .select2-selection__arrow:before {
    width: auto;
    height: auto;
    border: none;
    left: 0;
    margin-left: 0;
    top: 50%!important;
    margin-top: -8px;
    font-family: startui!important;
    font-style: normal!important;
    font-weight: 400!important;
    font-variant: normal!important;
    text-transform: none!important;
    speak: none;
    line-height: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    vertical-align: middle;
    position: relative;
    top: -.15em;
    content: "\51";
    color: #6c7a86;
    font-size: .6875rem;
    text-align: left
}

.select2-container--white .select2-selection--single .select2-selection__arrow {
    background: 0 0
}

.uploading-list-title {
    font-size: 1rem;
    font-weight: 600;
    margin: 0
}

.uploading-list .uploading-list-item {
    zoom: 1;
    padding: .9rem 0;
    border-top: solid 1px #d8e2e7
}

.uploading-list .uploading-list-item:after,
.uploading-list .uploading-list-item:before {
    content: " ";
    display: table
}

.uploading-list .uploading-list-item:after {
    clear: both
}

.uploading-list .uploading-list-item:first-child {
    border-top: none
}

.uploading-list .uploading-list-item-wrapper {
    position: relative;
    padding: 0 20px 0 0;
    margin: 0 0 6px
}

.uploading-list .uploading-list-item-name,
.uploading-list .uploading-list-item-size {
    display: inline-block;
    vertical-align: top;
    white-space: nowrap;
    line-height: 1rem
}

.uploading-list .uploading-list-item-name {
    padding-right: 15px;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis
}

.uploading-list .uploading-list-item-name .font-icon {
    color: #adb7be;
    margin: 0 5px 0 0;
    vertical-align: middle;
    font-size: 1rem
}

.uploading-list .uploading-list-item-size {
    color: #919fa9
}

.uploading-list .uploading-list-item-close {
    border: none;
    background: 0 0;
    color: #dbe4ea;
    font-size: .75rem;
    position: absolute;
    right: 0;
    top: 2px
}

.uploading-list .uploading-list-item-close:hover {
    color: #00a8ff
}

.uploading-list .uploading-list-item-progress,
.uploading-list .uploading-list-item-speed {
    font-size: .875rem
}

.uploading-list .uploading-list-item-progress {
    float: left
}

.uploading-list .uploading-list-item-speed {
    float: right
}

.uploading-list .progress {
    margin-bottom: .4rem
}

.uploading-list .progress {
    background-color: #f6f8fa;
    height: 5px;
    -webkit-border-radius: 0;
    border-radius: 0;
    color: #00a8ff
}

.uploading-list .progress[value] {
    color: #00a8ff
}

.uploading-list .progress[value]::-webkit-progress-value {
    background-color: #00a8ff
}

.uploading-list .progress-bar {
    -webkit-border-radius: 0;
    border-radius: 0;
    background-color: #00a8ff
}

.drop-zone {
    width: 205px;
    height: 205px;
    border: dashed 2px #adb7be;
    text-align: center;
    padding: 25px 0 0
}

.drop-zone.dragover {
    border-color: #00a8ff
}

.drop-zone .font-icon {
    line-height: 60px;
    color: #919fa9;
    font-size: 2.75rem
}

.drop-zone .drop-zone-caption {
    font-size: 1rem;
    font-weight: 600;
    color: #919fa9;
    margin: 0 0 1rem
}

.btn-file {
    position: relative;
    overflow: hidden;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    cursor: pointer
}

.btn-file input[type=file] {
    position: absolute;
    left: 0;
    top: 0;
    height: 200px;
    width: 1000px;
    opacity: 0;
    cursor: pointer
}

.uploading-container {
    zoom: 1;
    padding: 15px 25px 15px 15px
}

.uploading-container:after,
.uploading-container:before {
    content: " ";
    display: table
}

.uploading-container:after {
    clear: both
}

.uploading-container .uploading-container-left {
    float: left;
    position: relative;
    z-index: 5;
    width: 220px
}

.uploading-container .uploading-container-right {
    float: right;
    width: 100%;
    margin-left: -220px
}

.uploading-container .uploading-container-right-in {
    margin-left: 220px
}

@media (max-width:544px) {
    .uploading-container .uploading-container-left,
    .uploading-container .uploading-container-right {
        float: none;
        width: auto;
        margin: 0
    }
    .uploading-container .uploading-container-right-in {
        margin: 0
    }
    .uploading-container .drop-zone {
        width: auto;
        margin: 0 0 1rem
    }
}

.typeahead-button button .font-icon-search {
    vertical-align: middle
}

.typeahead-filter button {
    -webkit-border-radius: 0;
    border-radius: 0
}

.typeahead-list {
    padding: 4px 0 3px
}

.typeahead-list>li.typeahead-group.active>a,
.typeahead-list>li.typeahead-group>a,
.typeahead-list>li.typeahead-group>a:focus,
.typeahead-list>li.typeahead-group>a:hover {
    background-color: #f6f8fa
}

.typeahead-item .row {
    margin: 0
}

.typeahead-item .avatar {
    margin-right: 10px
}

.typeahead-item .username {
    margin-right: 5px
}

.typeahead-item small {
    font-size: .8125rem
}

.typeahead-dropdown>li>a,
.typeahead-list>li>a {
    padding: 3px .75rem;
    font-size: .9375rem
}

var.result-container {
    font-size: .9375rem
}

.btn:focus {
    -webkit-box-shadow: none;
    box-shadow: none
}

pre+.form-group {
    margin-top: 15px
}

.page-content {

    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out
}

.dark-theme .page-content {
    padding-top: 77px
}

.with-side-menu .page-content {
    padding-left: 240px;
    margin-top:15px;
}

@media (max-width:1056px) {
    .with-side-menu .page-content {
        padding-left: 0;
        padding-right: 0
    }
}

.control-panel .page-content {
    padding-right: 62px
}

.control-panel.open .page-content {
    padding-right: 295px
}

.with-side-menu-compact .page-content {
    padding-left: 115px
}

@media (max-width:1056px) {
    .with-side-menu-compact .page-content {
        padding-left: 0;
        padding-right: 0
    }
}

.with-side-menu-addl .page-content {
    padding-left: 335px
}

@media (max-width:1056px) {
    .with-side-menu-addl .page-content {
        padding-left: 0;
        padding-right: 0
    }
}

.with-side-menu-addl-full .page-content {
    padding-left: 475px
}

@media (max-width:1056px) {
    .with-side-menu-addl-full .page-content {
        padding-left: 0;
        padding-right: 0
    }
}

@media (max-width:1056px) {
    .control-panel .page-content {
        padding-right: 45px
    }
}

@media (max-width:767px) {
    .control-panel-container {
        display: none
    }
    .control-panel .page-content {
        padding-right: 0
    }
}

.control-panel .control-panel-container {
    position: fixed;
    right: 0;
    top: 0;
    width: 44px;
    height: 100%;
    background: #f4f8fb;
    overflow: hidden;
    border-left: solid 1px #c5d6de;
    padding-top: 80px
}

.control-panel .control-panel-container .icon-toggle {
    text-align: center;
    display: block;
    background: #dce5ec;
    vertical-align: middle;
    height: 44px;
    line-height: 44px
}

.control-panel .control-panel-container .icon-toggle .caret-down {
    display: none
}

.control-panel .control-panel-container .icon-toggle:hover {
    background: #92a0ab;
    color: #fff
}

.control-panel .control-panel-container .actions,
.control-panel .control-panel-container .text {
    display: none;
    cursor: pointer
}

.control-panel .control-panel-container .tasks .icon-toggle {
    line-height: 48px
}

.control-panel .control-panel-container .emails .icon-toggle {
    line-height: 47px
}

.control-panel .control-panel-container .add .icon-toggle {
    line-height: 48px
}

.control-panel-container ul {
    display: table;
    width: 100%
}

.control-panel-container li {
    display: table-row;
    height: auto;
    overflow: hidden
}

.control-panel-container li a {
    color: #93a1ae;
    font-size: 1.2rem
}

.control-panel-container li .control-item-header {
    display: table-cell;
    background: #dce5ec;
    border-bottom: 1px solid #f3f7fa;
    overflow: hidden;
    vertical-align: middle;
    height: 44px
}

.control-panel-container .control-panel-toggle {
    width: 100%;
    font-size: 1.6rem;
    color: #c5d1dd;
    display: block;
    position: absolute;
    bottom: 0;
    text-align: center
}

.control-panel-container .control-panel-toggle.open {
    text-align: left;
    padding-left: 15px
}

.control-panel-container .control-panel-toggle.open .fa:before {
    content: "\f101"
}

.control-panel-container .control-item-content {
    display: none
}

.control-panel.open .control-panel-container {
    width: 280px
}

.control-panel.open .control-panel-container ul {
    display: block
}

.control-panel.open .control-panel-container li {
    display: block
}

.control-panel.open .control-panel-container li .control-item-header {
    display: block;
    background: #dce5ec;
    border-bottom: 1px solid #f3f7fa;
    overflow: hidden;
    padding: 0 10px;
    vertical-align: middle
}

.control-panel.open .control-panel-container .icon-toggle {
    border: none;
    display: inline-block;
    float: left;
    height: auto!important;
    width: 50px;
    text-align: left;
    position: relative
}

.control-panel.open .control-panel-container .icon-toggle .caret-down {
    display: inline-block;
    font-size: .9rem;
    position: relative;
    margin-right: 3px
}

.control-panel.open .control-panel-container .icon-toggle:hover {
    background: 0 0;
    color: #92a0ab
}

.control-panel.open .control-panel-container .text {
    float: left;
    display: block;
    line-height: 44px
}

.control-panel.open .control-panel-container .actions {
    float: right;
    display: block
}

.control-panel.open .control-panel-container .actions a {
    display: inline-block;
    margin-left: 4px;
    font-size: 1rem;
    line-height: 44px
}

.control-panel.open .control-panel-container .actions a:hover {
    color: #0082c6
}

.control-panel.open .control-panel-container .control-item-content {
    padding: 10px;
    background: #f3f7fa;
    display: none
}

.control-panel.open .control-panel-container .control-item-content.open {
    display: block
}

.control-panel.open .control-panel-container .control-item-content-text,
.control-panel.open .control-panel-container .control-item-lists {
    border: 1px solid #e0e0e0;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    background: #fff;
    font-size: .9rem
}

.control-panel.open .control-panel-container .control-item-lists li {
    border-bottom: 1px solid #e0e0e0;
    position: relative
}

.control-panel.open .control-panel-container .control-item-lists li:after {
    font-family: FontAwesome;
    content: "\f0e0";
    position: absolute;
    top: 8px;
    right: 11px;
    font-size: .9rem;
    color: #c8d5db
}

.control-panel.open .control-panel-container .control-item-lists li:last-child {
    border-bottom: none
}

.control-panel.open .control-panel-container .control-item-content-text,
.control-panel.open .control-panel-container .control-item-lists li>a {
    padding: 8px 11px;
    display: block;
    font-size: .9rem
}

.control-panel.open .control-panel-container .control-item-lists li h6 {
    margin-bottom: 0!important;
    color: #343434;
    font-weight: 600
}

.control-panel.open .control-panel-container .control-item-lists li p {
    font-size: .9rem!important;
    margin-bottom: 0!important;
    color: #343434
}

.control-panel.open .control-panel-container .control-item-actions {
    overflow: hidden;
    height: auto;
    margin-bottom: 10px
}

.control-panel.open .control-panel-container .control-item-actions .link {
    font-size: .9rem;
    float: left;
    color: #2a9de0
}

.control-panel.open .control-panel-container .control-item-actions .mark {
    font-size: .9rem;
    float: right;
    background: #d5dce2;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    color: #343434;
    padding: 0 5px
}

.control-panel.open .control-panel-container .control-item-actions .mark:hover {
    background: #2a9de0;
    color: #fff
}

.control-panel.open .control-panel-container .message {
    font-size: .9rem
}

.control-panel.open .control-panel-container .message span {
    float: left
}

.control-panel.open .control-panel-container .message a {
    float: right;
    font-size: .9rem
}

.control-panel.open .control-panel-container a.reply-all {
    position: absolute;
    right: 0;
    bottom: 0
}

.control-panel.open .control-panel-container a.reply-all:hover {
    color: #0082c6
}

.page-center {
    display: table;
    width: 100%;
    border-collapse: collapse
}

.page-center-in {
    display: table-cell;
    vertical-align: middle;
    padding: 15px 0
}

.box-typical-center {
    display: table;
    width: 100%;
    height: 100%;
    border-collapse: collapse
}

.box-typical-center-in {
    display: table-cell;
    vertical-align: middle;
    padding: 15px
}

.col-xxl-1,
.col-xxl-10,
.col-xxl-11,
.col-xxl-12,
.col-xxl-2,
.col-xxl-3,
.col-xxl-4,
.col-xxl-5,
.col-xxl-6,
.col-xxl-7,
.col-xxl-8,
.col-xxl-9 {
    position: relative;
    min-height: 1px;
    padding-right: .9375rem;
    padding-left: .9375rem
}

@media (min-width:1500px) {
    .col-xxl-1,
    .col-xxl-10,
    .col-xxl-11,
    .col-xxl-12,
    .col-xxl-2,
    .col-xxl-3,
    .col-xxl-4,
    .col-xxl-5,
    .col-xxl-6,
    .col-xxl-7,
    .col-xxl-8,
    .col-xxl-9 {
        float: left
    }
    .col-xxl-1 {
        width: 8.333333%
    }
    .col-xxl-2 {
        width: 16.666667%
    }
    .col-xxl-3 {
        width: 25%
    }
    .col-xxl-4 {
        width: 33.333333%
    }
    .col-xxl-5 {
        width: 41.666667%
    }
    .col-xxl-6 {
        width: 50%
    }
    .col-xxl-7 {
        width: 58.333333%
    }
    .col-xxl-8 {
        width: 66.666667%
    }
    .col-xxl-9 {
        width: 75%
    }
    .col-xxl-10 {
        width: 83.333333%
    }
    .col-xxl-11 {
        width: 91.666667%
    }
    .col-xxl-12 {
        width: 100%
    }
}

@media (min-width:1500px) {
    .hidden-xxl-up {
        display: none!important
    }
}

.hidden-xxl-down {
    display: none!important
}

hr {
    border-top-color: #d8e2e7;
    margin: 2em 0
}

hr.dashed {
    border-top-style: dashed
}

hr.margin-4 {
    margin: 4em 0
}

.btn.dropdown-toggle:after {
    top: 2px;
    position: relative
}

.dropdown-toggle:after {
    top: 2px;
    position: relative
}

.dropdown-menu {
    border-color: #d8e2e7;
    margin-top: 6px;
    font-size: .8125rem
}

.dropup .dropdown-menu {
    margin-bottom: 6px
}

.dropdown-menu-col {
    float: left;
    width: 50%
}

.dropdown-header,
.dropdown-item {
    padding: 4px 12px
}

.dropdown-header {
    text-transform: uppercase;
    font-weight: 600;
    color: #6c7a86;
    font-size: .875rem
}

.dropdown-item {
    font-size: .9375rem
}

.dropdown-item .font-icon {
    margin: 0 8px 0 0;
    color: #adb7be;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    font-size: 16px;
    vertical-align: middle;
    line-height: 16px;
    position: relative;
    top: -1px
}

.dropdown-item.current,
.dropdown-item:hover {
    background: 0 0;
    color: #00a8ff
}

.dropdown-item.current .font-icon,
.dropdown-item:hover .font-icon {
    color: #00a8ff
}

.dropdown-item.no-nowrap {
    white-space: normal;
    line-height: 1.3
}

.dropdown.dropdown-typical {
    position: relative
}

.dropdown.dropdown-typical a.dropdown-toggle {
    color: #343434;
    font-weight: 600;
    padding-right: 15px;
    padding-left: 15px
}

.dropdown.dropdown-typical a.dropdown-toggle .font-icon,
.dropdown.dropdown-typical a.dropdown-toggle:after {
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out
}

.dropdown.dropdown-typical a.dropdown-toggle:after {
    color: #adb7be;
    border-top: 5px solid;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    position: relative;
    top: 2px
}

.dropdown.dropdown-typical a.dropdown-toggle .font-icon {
    vertical-align: middle;
    margin: 0 6px 0 0;
    color: #adb7be;
    font-size: 16px;
    line-height: 16px;
    position: relative;
    top: -1px
}

.dropdown.dropdown-typical a.dropdown-toggle.dropdown-toggle-txt {
    font-weight: 400;
    color: #00a8ff;
    font-size: .9375rem
}

.dropdown.dropdown-typical a.dropdown-toggle.dropdown-toggle-txt:after {
    display: none
}

.dropdown.dropdown-typical a.dropdown-toggle:hover,
.dropdown.dropdown-typical.open a.dropdown-toggle {
    color: #00a8ff
}

.dropdown.dropdown-typical a.dropdown-toggle:hover .font-icon,
.dropdown.dropdown-typical a.dropdown-toggle:hover:after,
.dropdown.dropdown-typical.open a.dropdown-toggle .font-icon,
.dropdown.dropdown-typical.open a.dropdown-toggle:after {
    color: #00a8ff
}

.dropdown.dropdown-typical .dropdown-header,
.dropdown.dropdown-typical .dropdown-item {
    padding: 3px 14px
}

.dropdown.dropdown-typical .dropdown-item {
    font-size: .9375rem
}

.dropdown.dropdown-typical .dropdown-menu {
    margin-top: 2px;
    min-width: 100%
}

.dropdown.dropdown-typical .dropdown-more {
    position: relative
}

.dropdown.dropdown-typical .dropdown-more .dropdown-more-caption {
    padding: 3px 12px;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    cursor: pointer;
    position: relative;
    font-size: 15px
}

.dropdown.dropdown-typical .dropdown-more .dropdown-more-caption:before {
    content: '';
    display: block;
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -3px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px 0 4px 5px;
    border-color: transparent transparent transparent #adb7be;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out
}

.dropdown.dropdown-typical .dropdown-more .dropdown-more-caption.padding {
    padding-left: 37px
}

.dropdown.dropdown-typical .dropdown-more .dropdown-more-sub {
    display: none;
    position: absolute;
    top: -4px;
    left: 100%;
    padding: 0 0 0 4px;
    margin: 0 0 0 -3px
}

.dropdown.dropdown-typical .dropdown-more .dropdown-more-sub-in {
    background: #fff;
    border: solid 1px #d8e2e7;
    border-left: none;
    -webkit-border-radius: 0 3px 3px 0;
    border-radius: 0 3px 3px 0;
    padding-top: 4px;
    padding-bottom: 4px
}

.dropdown.dropdown-typical .dropdown-more:hover .dropdown-more-caption {
    color: #00a8ff
}

.dropdown.dropdown-typical .dropdown-more:hover .dropdown-more-caption:before {
    border-left-color: #00a8ff
}

.dropdown.dropdown-typical .dropdown-more:hover .dropdown-more-sub {
    display: block
}

.dropdown.dropdown-status {
    display: inline-block
}

.dropdown.dropdown-status .btn.dropdown-toggle {
    height: 22px;
    font-size: .8125rem;
    padding: 0 7px
}

.dropdown.dropdown-status .btn.dropdown-toggle.btn-danger {
    background-color: #fa424a!important;
    border-color: #fa424a!important
}

.dropdown.dropdown-status .btn.dropdown-toggle.btn-primary {
    background-color: #00a8ff!important;
    border-color: #00a8ff!important
}

.dropdown.dropdown-status .btn.dropdown-toggle.btn-success {
    background-color: #46c35f!important;
    border-color: #46c35f!important
}

.dropdown.dropdown-status .btn.dropdown-toggle.btn-warning {
    background-color: #f29824!important;
    border-color: #f29824!important
}

.dropdown.dropdown-status .dropdown-menu {
    margin-left: -5px
}

.dropdown.dropdown-status .dropdown-menu a.dropdown-item {
    font-weight: 400;
    border: none
}

.dropdown-toggle .caret {
    display: none
}

.btn {
    -webkit-border-radius: 3px;
    border-radius: 3px;
    border: solid 1px #00a8ff;
    background: #00a8ff;
    color: #fff;
    font-weight: 600
}

.btn:hover {
    background-color: #16b4fc;
    border-color: #16b4fc
}

.btn:active {
    background-color: #00a1f3;
    border-color: #00a1f3
}

.btn.disabled,
.btn:disabled {
    background-color: #dbe4ea;
    border-color: #dbe4ea;
    color: #6c7a86
}

.btn.active {
    background-color: #0090d9;
    border-color: #0090d9
}

.btn.active:hover {
    background-color: #007fc0;
    border-color: #007fc0
}

.btn.btn-rounded {
    -webkit-border-radius: 25rem;
    border-radius: 25rem
}

.btn.btn-grey,
.btn.dropdown-toggle,
.btn.dropdown-toggle:focus {
    background-color: #dbe4ea;
    border-color: #dbe4ea;
    color: #6c7a86;
    -webkit-transition: none;
    transition: none
}

.btn-group.open .btn.btn-grey,
.btn-group.open .btn.dropdown-toggle,
.btn-group.open .btn.dropdown-toggle:focus,
.dropdown.open .btn.btn-grey,
.dropdown.open .btn.dropdown-toggle,
.dropdown.open .btn.dropdown-toggle:focus {
    color: #fff;
    background-color: #00a8ff;
    border-color: #00a8ff
}

.btn-group.open .btn.btn-grey:after,
.btn-group.open .btn.dropdown-toggle:after,
.btn-group.open .btn.dropdown-toggle:focus:after,
.dropdown.open .btn.btn-grey:after,
.dropdown.open .btn.dropdown-toggle:after,
.dropdown.open .btn.dropdown-toggle:focus:after {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg)
}

.btn-group.open .btn.btn-grey.btn-default-outline,
.btn-group.open .btn.dropdown-toggle.btn-default-outline,
.btn-group.open .btn.dropdown-toggle:focus.btn-default-outline,
.dropdown.open .btn.btn-grey.btn-default-outline,
.dropdown.open .btn.dropdown-toggle.btn-default-outline,
.dropdown.open .btn.dropdown-toggle:focus.btn-default-outline {
    background-color: #d8e2e7;
    color: #919fa9;
    border-color: #d8e2e7
}

.btn-group.open .btn.btn-grey.btn-default-outline:hover,
.btn-group.open .btn.dropdown-toggle.btn-default-outline:hover,
.btn-group.open .btn.dropdown-toggle:focus.btn-default-outline:hover,
.dropdown.open .btn.btn-grey.btn-default-outline:hover,
.dropdown.open .btn.dropdown-toggle.btn-default-outline:hover,
.dropdown.open .btn.dropdown-toggle:focus.btn-default-outline:hover {
    background-color: #d8e2e7;
    color: #6c7a86!important
}

.btn.btn-grey.btn-default-outline,
.btn.dropdown-toggle.btn-default-outline,
.btn.dropdown-toggle:focus.btn-default-outline {
    color: #919fa9;
    border-color: #d8e2e7
}

.btn.btn-grey.btn-default-outline:hover,
.btn.dropdown-toggle.btn-default-outline:hover,
.btn.dropdown-toggle:focus.btn-default-outline:hover {
    background-color: #d8e2e7;
    color: #6c7a86!important
}

.btn.btn-danger,
.btn.btn-default,
.btn.btn-info,
.btn.btn-primary,
.btn.btn-secondary,
.btn.btn-success,
.btn.btn-warning {
    color: #fff!important
}

.btn.btn-default {
    background-color: #adb7be;
    border-color: #adb7be
}

.btn.btn-default:hover {
    background-color: #bec6cc;
    border-color: #bec6cc
}

.btn.btn-primary {
    background-color: #00a8ff;
    border-color: #00a8ff
}

.btn.btn-primary:hover {
    background-color: #16b4fc;
    border-color: #16b4fc
}

.btn.btn-secondary {
    background-color: #6b7a85;
    border-color: #6b7a85
}

.btn.btn-secondary:hover {
    background-color: #7a8994;
    border-color: #7a8994
}

.btn.btn-success {
    background-color: #46c35f;
    border-color: #46c35f
}

.btn.btn-success:hover {
    background-color: #5dca73;
    border-color: #5dca73
}

.btn.btn-info {
    background-color: #ac6bec;
    border-color: #ac6bec
}

.btn.btn-info:hover {
    background-color: #bb86ef;
    border-color: #bb86ef
}

.btn.btn-warning {
    background-color: #f29824;
    border-color: #f29824
}

.btn.btn-warning:hover {
    background-color: #f4a641;
    border-color: #f4a641
}

.btn.btn-danger {
    background-color: #fa424a;
    border-color: #fa424a
}

.btn.btn-danger:hover {
    background-color: #fb6067;
    border-color: #fb6067
}

.btn.btn-danger-outline,
.btn.btn-default-outline,
.btn.btn-info-outline,
.btn.btn-primary-outline,
.btn.btn-secondary-outline,
.btn.btn-success-outline,
.btn.btn-warning-outline {
    background-color: #fff
}

.btn.btn-danger-outline:focus,
.btn.btn-default-outline:focus,
.btn.btn-info-outline:focus,
.btn.btn-primary-outline:focus,
.btn.btn-secondary-outline:focus,
.btn.btn-success-outline:focus,
.btn.btn-warning-outline:focus {
    background-color: #fff
}

.btn.btn-danger-outline:hover,
.btn.btn-default-outline:hover,
.btn.btn-info-outline:hover,
.btn.btn-primary-outline:hover,
.btn.btn-secondary-outline:hover,
.btn.btn-success-outline:hover,
.btn.btn-warning-outline:hover {
    color: #fff!important
}

.btn.btn-primary-outline,
.btn.btn-primary-outline:focus {
    color: #00a8ff;
    border-color: #00a8ff
}

.btn.btn-primary-outline:focus:hover,
.btn.btn-primary-outline:hover {
    background-color: #00a8ff
}

.btn.btn-secondary-outline {
    color: #6b7a85;
    border-color: #6b7a85
}

.btn.btn-secondary-outline:hover {
    background-color: #6b7a85
}

.btn.btn-success-outline {
    color: #46c35f;
    border-color: #46c35f
}

.btn.btn-success-outline:hover {
    background-color: #46c35f
}

.btn.btn-info-outline {
    color: #ac6bec;
    border-color: #ac6bec
}

.btn.btn-info-outline:hover {
    background-color: #ac6bec
}

.btn.btn-warning-outline {
    color: #f29824;
    border-color: #f29824
}

.btn.btn-warning-outline:hover {
    background-color: #f29824
}

.btn.btn-danger-outline {
    color: #fa424a;
    border-color: #fa424a
}

.btn.btn-danger-outline:hover {
    background-color: #fa424a
}

.btn.btn-default-outline {
    color: #919fa9;
    border-color: #d8e2e7
}

.btn.btn-default-outline:hover {
    background-color: #d8e2e7;
    color: #6c7a86!important
}

.btn.btn-bordered {
    background-color: #fff
}

.btn.btn-select {
    background: #fff;
    border-color: #d8e2e7;
    color: #343434;
    font-weight: 400
}

.btn.btn-select:hover {
    background-color: #f6f8fa
}

.btn.btn-inline {
    margin-right: 8px;
    margin-bottom: 8px
}

.btn .font-icon-left {
    margin: 0 5px 0 0;
    position: relative;
    top: 1px
}

a.btn:focus,
a.btn:hover {
    color: #fff
}

.btn-square-icon {
    min-width: 80px;
    height: 60px;
    border: solid 1px #d8e2e7;
    background: #f6f8fa;
    padding: 4px 10px 0;
    color: #6c7a86;
    position: relative;
    margin: 0 6px 10px 0;
    vertical-align: middle;
    text-align: center
}

.btn-square-icon .fa {
    display: block;
    margin: 0 0 2px
}

.btn-square-icon .label {
    padding-left: .5em;
    padding-right: .5em;
    position: absolute;
    right: -5px;
    top: -5px
}

.btn-square-icon:hover {
    background-color: #ecf2f5
}

.btn-square-icon.btn-square-icon-rounded {
    -webkit-border-radius: .25rem;
    border-radius: .25rem
}

a.btn-square-icon {
    display: inline-block;
    color: #6c7a86;
    padding-top: 11px
}

.label {
    font-size: .875rem;
    font-weight: 600;
    background-color: #adb7be
}

h1 .label,
h2 .label,
h3 .label,
h4 .label,
h5 .label,
h6 .label {
    font-size: inherit
}

.label-default {
    background-color: #6c7a86
}

.label-danger {
    background-color: #fa424a
}

.label-primary {
    background-color: #00a8ff
}

.label-success {
    background-color: #46c35f
}

.label-default {
    background-color: #adb7be
}

.label-warning {
    background-color: #fdad2a
}

.label-info {
    background-color: #ac6bec
}

.label-light-grey {
    background-color: #eceff4;
    color: #919fa9
}

a.label-light-grey:hover {
    color: #fff;
    background-color: #00a8ff
}

.label.label-pill.label-custom {
    padding: 3px 6px 2px;
    font-size: .8125rem
}

.tbl-typical {
    width: 100%;
    border-collapse: collapse
}

.tbl-typical td,
.tbl-typical th {
    padding: 14px 15px
}

.tbl-typical td[align=center],
.tbl-typical th[align=center] {
    text-align: center
}

.tbl-typical th {
    background: #f6f8fa;
    border-bottom: solid 1px #d8e2e7;
    padding: 0;
    font-weight: 700;
    color: #6c7a86;
    font-size: 1rem
}

.tbl-typical th>div {
    position: relative;
    padding: 8px 15px
}

.tbl-typical th>div:before {
    content: '';
    display: block;
    width: 1px;
    height: 85%;
    position: absolute;
    left: 0;
    bottom: 0;
    background: -webkit-linear-gradient(top, rgba(216, 226, 231, 0) 0, #d8e2e7 77%, #d8e2e7 100%);
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(216, 226, 231, 0)), color-stop(77%, #d8e2e7), to(#d8e2e7));
    background: linear-gradient(to bottom, rgba(216, 226, 231, 0) 0, #d8e2e7 77%, #d8e2e7 100%)
}

.tbl-typical th:first-child>div:before {
    display: none
}

.tbl-typical td {
    font-size: .9375rem
}

.tbl-typical tr:nth-child(even) td {
    background: #fbfcfd
}

.tbl-typical .label {
    margin-top: 2px;
    margin-bottom: 2px
}

.bootstrap-table .table,
.fixed-table-body .table,
.table {
    font-size: 14px;
    margin-bottom: 0;
    font-weight:500;
    color:#6e6e6e !important;
}

.bootstrap-table .table td,
.bootstrap-table .table th,
.fixed-table-body .table td,
.fixed-table-body .table th,
.table td,
.table th {
    vertical-align: middle;
    border-top-color: #d8e2e7;
    padding: 11px 10px 10px
}

.bootstrap-table .table th,
.fixed-table-body .table th,
.table th {
    font-weight: 700
}

.bootstrap-table .table td,
.fixed-table-body .table td,
.table td {
    height: 50px
}

.bootstrap-table .table thead th,
.fixed-table-body .table thead th,
.table thead th {
    border-bottom: none;
    padding-top: 10px;
    padding-bottom: 9px;
    background: #f6f8fa
}

.bootstrap-table .table thead th.table-check .checkbox,
.fixed-table-body .table thead th.table-check .checkbox,
.table thead th.table-check .checkbox {
    top: -2px
}

.bootstrap-table .table .table-check,
.fixed-table-body .table .table-check,
.table .table-check {
    width: 30px;
    padding-right: 0;
    padding-left: 15px;
    padding-top: 10px
}

.bootstrap-table .table .table-check .checkbox,
.fixed-table-body .table .table-check .checkbox,
.table .table-check .checkbox {
    float: right;
    top: -1px
}

.bootstrap-table .table .table-date,
.fixed-table-body .table .table-date,
.table .table-date {
    text-align: right;
    white-space: nowrap;
    width: 10px;
    color: #919fa9
}

.bootstrap-table .table .table-date .font-icon,
.fixed-table-body .table .table-date .font-icon,
.table .table-date .font-icon {
    vertical-align: middle;
    margin: 0 0 0 3px;
    position: relative;
    top: 1px;
    font-size: .875rem
}

.bootstrap-table .table .table-photo,
.fixed-table-body .table .table-photo,
.table .table-photo {
    padding-right: 15px;
    width: 22px;
    padding-top: 10px
}

.bootstrap-table .table .table-photo img,
.fixed-table-body .table .table-photo img,
.table .table-photo img {
    display: block;
    width: 32px;
    height: 32px;
    -webkit-border-radius: 50%;
    border-radius: 50%
}

.bootstrap-table .table .table-icon-cell,
.fixed-table-body .table .table-icon-cell,
.table .table-icon-cell {
    color: #919fa9
}

.bootstrap-table .table .table-icon-cell .font-icon,
.fixed-table-body .table .table-icon-cell .font-icon,
.table .table-icon-cell .font-icon {
    position: relative
}

.bootstrap-table .table td.table-icon-cell,
.fixed-table-body .table td.table-icon-cell,
.table td.table-icon-cell {
    padding-bottom: 10px
}

.bootstrap-table .table td.table-icon-cell .font-icon,
.fixed-table-body .table td.table-icon-cell .font-icon,
.table td.table-icon-cell .font-icon {
    top: 1px
}

.bootstrap-table .table th.table-icon-cell .font-icon,
.fixed-table-body .table th.table-icon-cell .font-icon,
.table th.table-icon-cell .font-icon {
    top: 2px
}

.bootstrap-table .table a,
.fixed-table-body .table a,
.table a {
    /*border-bottom: solid 1px rgba(0, 130, 198, .5);*/
    position: relative;
    top: -1px
}

.bootstrap-table .table a:hover,
.fixed-table-body .table a:hover,
.table a:hover {
    border-bottom-color: transparent
}

.table.table-sm td {
    height: auto;
    padding: 8px 10px
}

.table.table-xs td {
    height: auto;
    padding: 5px 10px
}

.bootstrap-table-header {
    display: inline-block;
    font-weight: 700;
    margin-right: 10px!important;
    position: relative;
    top: 1px
}

.table td {
    padding-bottom: 8px;
    color:#5b5959;
    text-align:center;
}
.table td > strong
{ color:2a5c8a !important;
}

.table.font-16 {
    font-size: 1rem
}

.table-hover tbody tr:hover {
    background: #fbfcfd
}

.table-striped tbody tr:nth-of-type(odd) {
    background: 0 0
}

.table-striped tbody tr:nth-of-type(even) {
    background-color: #fbfcfd
}

.table-active,
.table-active>td,
.table-active>th {
    background-color: #ecf2f5
}

.table-success,
.table-success>td,
.table-success>th {
    background-color: #edf9ee
}

.table-warning,
.table-warning>td,
.table-warning>th {
    background-color: #fdf4e6
}

.table-danger,
.table-danger>td,
.table-danger>th {
    background-color: #feecec
}

.table-info,
.table-info>td,
.table-info>th {
    background-color: #e4f6fe
}

.table-hover .table-active:hover,
.table-hover .table-active:hover>td,
.table-hover .table-active:hover>th {
    background-color: #dbe7ec
}

.table-hover .table-success:hover,
.table-hover .table-success:hover>td,
.table-hover .table-success:hover>th {
    background-color: #daf3dc
}

.table-hover .table-warning:hover,
.table-hover .table-warning:hover>td,
.table-hover .table-warning:hover>th {
    background-color: #fbeace
}

.table-hover .table-danger:hover,
.table-hover .table-danger:hover>td,
.table-hover .table-danger:hover>th {
    background-color: #fdd4d4
}

.table-hover .table-info:hover,
.table-hover .table-info:hover>td,
.table-hover .table-info:hover>th {
    background-color: #cbeefd
}

.peity {
    vertical-align: middle
}

.bar-chart-wrapper {
    position: relative;
    padding: 15px 0 0;
    display: inline-block;
    vertical-align: middle
}

.bar-chart-wrapper .val {
    position: absolute;
    font-size: .6875rem;
    color: #919fa9;
    top: 0;
    line-height: 1
}

.bar-chart-wrapper .val.left {
    left: 0
}

.bar-chart-wrapper .val.right {
    right: 0
}

.table-responsive .bootstrap-table {
    min-width: 700px;
    overflow-y: hidden
}

.bootstrap-table .fixed-table-toolbar {
    padding: 20px 15px 0;
    zoom: 1
}

.bootstrap-table .fixed-table-toolbar:after,
.bootstrap-table .fixed-table-toolbar:before {
    content: " ";
    display: table
}

.bootstrap-table .fixed-table-toolbar:after {
    clear: both
}

.bootstrap-table .fixed-table-toolbar h1,
.bootstrap-table .fixed-table-toolbar h2,
.bootstrap-table .fixed-table-toolbar h3,
.bootstrap-table .fixed-table-toolbar h4,
.bootstrap-table .fixed-table-toolbar h5,
.bootstrap-table .fixed-table-toolbar h6 {
    font-size: .9375rem;
    margin: 0;
    line-height: 1.4
}

.bootstrap-table .fixed-table-toolbar .btn.remove {
    height: 32px;
    padding: 0 8px;
    font-size: 1rem;
    line-height: 30px
}

.bootstrap-table .fixed-table-toolbar .btn.remove .font-icon {
    line-height: 32px;
    font-size: 12px;
    vertical-align: top;
    margin: 0 3px 0 0;
    position: relative;
    top: -1px
}

.bootstrap-table .fixed-table-toolbar .bars,
.bootstrap-table .fixed-table-toolbar .columns,
.bootstrap-table .fixed-table-toolbar .search {
    margin: 0;
    padding: 0;
    line-height: normal
}

.bootstrap-table .fixed-table-toolbar .bars {
    float: left;
    padding-bottom: 15px;
    line-height: 32px
}

.bootstrap-table .fixed-table-toolbar .columns,
.bootstrap-table .fixed-table-toolbar .search {
    float: right;
    padding-bottom: 20px
}

.bootstrap-table .fixed-table-toolbar .search {
    width: 220px
}

.bootstrap-table .fixed-table-toolbar .search .form-control {
    height: 32px;
    font-size: .875rem;
    -webkit-border-radius: 25rem;
    border-radius: 25rem;
    padding: 8px 10px;
    line-height: 1.1
}

@media (max-width:767px) {
    .bootstrap-table .fixed-table-toolbar .search {
        width: 130px
    }
}

.bootstrap-table .fixed-table-toolbar .columns {
    margin-left: 10px
}

.bootstrap-table .fixed-table-toolbar .columns .btn {
    height: 32px;
    background: 0 0!important;
    border: none!important;
    color: #adb7be!important;
    padding: 0 7px;
    line-height: 32px
}

.bootstrap-table .fixed-table-toolbar .columns .btn:hover {
    color: #00a8ff!important
}

.bootstrap-table .fixed-table-toolbar .columns .btn .font-icon {
    vertical-align: middle;
    line-height: 32px
}

.bootstrap-table .fixed-table-toolbar .columns .btn .font-icon-arrow-square-down.up:before {
    content: "\2d"
}

.bootstrap-table .fixed-table-toolbar .columns .dropdown-toggle:after {
    margin-left: 0;
    margin-right: 0
}

.bootstrap-table .fixed-table-toolbar .columns .dropdown-toggle .caret {
    display: none
}

.bootstrap-table .fixed-table-toolbar .columns .btn-group.open .btn {
    color: #00a8ff!important
}

.bootstrap-table .fixed-table-toolbar .columns .dropdown-menu {
    font-size: .9375rem;
    min-width: 0;
    white-space: nowrap;
    padding: 15px
}

.bootstrap-table .fixed-table-toolbar .columns .dropdown-menu li+li {
    margin-top: 6px
}

.bootstrap-table .fixed-table-toolbar .columns .dropdown-menu .checkbox {
    margin: 0;
    padding: 0
}

.bootstrap-table .fixed-table-toolbar .columns .dropdown-menu .checkbox label {
    line-height: 16px;
    padding-top: 0;
    padding-bottom: 0
}

.bootstrap-table .fixed-table-toolbar .columns .dropdown-menu a {
    display: block;
    padding: 5px 10px;
    color: #343434
}

.bootstrap-table .fixed-table-toolbar .columns .dropdown-menu a:hover {
    color: #00a8ff
}

.bootstrap-table .table {
    padding: 0!important;
    border-bottom-color: #d8e2e7
}

.bootstrap-table .table,
.bootstrap-table td,
.bootstrap-table th,
.bootstrap-table thead {
    -webkit-border-radius: 0!important;
    border-radius: 0!important
}

.bootstrap-table .table td,
.bootstrap-table .table>thead>tr>th {
    border-color: #d8e2e7;
    border-top: solid 1px #d8e2e7!important
}

.bootstrap-table .table .bs-checkbox .checkbox {
    margin: 0 auto
}

.bootstrap-table .card-view {
    padding: 3px 0 3px 7px;
    line-height: 22px
}

.bootstrap-table .card-view .title,
.bootstrap-table .card-view .value {
    vertical-align: top;
    zoom: 1
}

.bootstrap-table .card-view .title:after,
.bootstrap-table .card-view .title:before,
.bootstrap-table .card-view .value:after,
.bootstrap-table .card-view .value:before {
    content: " ";
    display: table
}

.bootstrap-table .card-view .title:after,
.bootstrap-table .card-view .value:after {
    clear: both
}

.bootstrap-table .card-view .value {
    display: inline-block
}

.bootstrap-table .card-view a.like,
.bootstrap-table .card-view a.remove {
    line-height: 22px!important;
    height: 22px!important;
    float: left;
    padding-top: 2px!important;
    text-align: left;
    margin: 0 10px 0 0!important
}

.bootstrap-table .fixed-table-container {
    border: none;
    -webkit-border-radius: 0;
    border-radius: 0
}

.bootstrap-table .fixed-table-container thead th .th-inner {
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 1.5;
    color: #6c7a86
}

.bootstrap-table .fixed-table-container thead th .both {
    background-image: url(/img/bst-sort.png)
}

.bootstrap-table .fixed-table-container thead th .desc {
    background-image: url(/img/bst-down.png)
}

.bootstrap-table .fixed-table-container thead th .asc {
    background-image: url(/img/bst-up.png)
}

.bootstrap-table .fixed-table-container thead th.bs-checkbox,
.bootstrap-table .fixed-table-container thead th.detail {
    width: 46px!important
}

.bootstrap-table .fixed-table-container a.detail-icon,
.bootstrap-table .fixed-table-container a.like,
.bootstrap-table .fixed-table-container a.remove {
    border: none;
    color: #adb7be;
    font-size: 1rem;
    line-height: 1rem;
    text-align: center;
    position: static;
    top: 0
}

.bootstrap-table .fixed-table-container a.detail-icon .font-icon,
.bootstrap-table .fixed-table-container a.like .font-icon,
.bootstrap-table .fixed-table-container a.remove .font-icon {
    line-height: inherit;
    vertical-align: middle
}

.bootstrap-table .fixed-table-container a.detail-icon:hover,
.bootstrap-table .fixed-table-container a.like:hover,
.bootstrap-table .fixed-table-container a.remove:hover {
    color: #00a8ff
}

.bootstrap-table .fixed-table-container a.detail-icon {
    display: block;
    width: 16px;
    height: 16px;
    margin: 0 auto
}

.bootstrap-table .fixed-table-container a.detail-icon .font-icon-minus-1 {
    color: #00a8ff
}

.bootstrap-table .fixed-table-container a.like,
.bootstrap-table .fixed-table-container a.remove {
    margin: 0 5px;
    line-height: 20px;
    display: inline-block;
    padding-top: 4px
}

.bootstrap-table .fixed-table-container a.like {
    padding-top: 5px
}

.bootstrap-table .fixed-table-pagination {
    zoom: 1;
    font-size: .8125rem;
    padding: 5px 15px
}

.bootstrap-table .fixed-table-pagination:after,
.bootstrap-table .fixed-table-pagination:before {
    content: " ";
    display: table
}

.bootstrap-table .fixed-table-pagination:after {
    clear: both
}

.bootstrap-table .fixed-table-pagination .pagination-detail {
    float: left;
    line-height: 32px
}

.bootstrap-table .fixed-table-pagination .pagination-info {
    margin-right: 10px;
    position: relative;
    top: -1px
}

.bootstrap-table .fixed-table-pagination .page-list {
    vertical-align: top
}

.bootstrap-table .fixed-table-pagination .page-list .btn-group {
    margin-right: 8px;
    vertical-align: top
}

.bootstrap-table .fixed-table-pagination .btn.dropdown-toggle {
    background: #fff!important;
    color: #343434!important;
    border-color: #d8e2e7!important;
    font-weight: 400;
    font-size: .8125rem;
    height: 32px;
    padding: 0 10px
}

.bootstrap-table .fixed-table-pagination .btn.dropdown-toggle .caret {
    display: none
}

.bootstrap-table .fixed-table-pagination .dropdown-menu {
    min-width: 100%;
    text-align: center;
    margin-bottom: 2px;
    line-height: normal
}

.bootstrap-table .fixed-table-pagination .dropdown-menu a {
    display: block;
    padding: 5px 10px
}

.bootstrap-table .fixed-table-pagination div.pagination {
    float: right
}

.bootstrap-table .fixed-table-pagination ul.pagination {
    zoom: 1;
    display: block
}

.bootstrap-table .fixed-table-pagination ul.pagination:after,
.bootstrap-table .fixed-table-pagination ul.pagination:before {
    content: " ";
    display: table
}

.bootstrap-table .fixed-table-pagination ul.pagination:after {
    clear: both
}

.bootstrap-table .fixed-table-pagination ul.pagination li {
    float: left
}

.bootstrap-table .fixed-table-pagination ul.pagination a {
    display: block;
    height: 32px;
    padding: 0 5px;
    text-align: center;
    min-width: 32px;
    border: solid 1px transparent;
    -webkit-border-radius: .25rem;
    border-radius: .25rem;
    line-height: 30px;
    color: #6c7a86
}

.bootstrap-table .fixed-table-pagination ul.pagination a:hover {
    color: #00a8ff
}

.bootstrap-table .fixed-table-pagination ul.pagination li.active a {
    border-color: #d8e2e7;
    color: #6c7a86
}

.bootstrap-table .fixed-table-pagination ul.pagination li.page-next .font-icon,
.bootstrap-table .fixed-table-pagination ul.pagination li.page-pre .font-icon {
    line-height: 32px;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    color: rgba(173, 183, 190, .7)
}

.bootstrap-table .fixed-table-pagination ul.pagination li.page-next a:hover .font-icon,
.bootstrap-table .fixed-table-pagination ul.pagination li.page-pre a:hover .font-icon {
    color: #00a8ff
}

.bootstrap-table .fixed-table-pagination ul.pagination li.page-pre {
    margin-right: 10px
}

.bootstrap-table .fixed-table-pagination ul.pagination li.page-next {
    margin-left: 10px
}

.bootstrap-table .valign-top {
    vertical-align: top!important
}

.bootstrap-table .filterControl {
    margin: 0 10px 10px!important
}

.bootstrap-table .fixed-table-body-columns {
    margin-top: -1px
}

.user-card-row {
    display: table;
    width: 100%;
    border-collapse: collapse;
    font-size: .8125rem
}

.user-card-row p {
    margin: 0
}

.user-card-row a {
    -webkit-transition: none;
    transition: none
}

.user-card-row .tbl-cell.tbl-cell-photo {
    width: 42px;
    padding-right: 10px
}

.user-card-row .tbl-cell.tbl-cell-photo img {
    display: block;
    width: 32px;
    height: 32px;
    -webkit-border-radius: 50%;
    border-radius: 50%
}

.user-card-row .tbl-cell.tbl-cell-photo.tbl-cell-photo-64 {
    width: 74px
}

.user-card-row .tbl-cell.tbl-cell-photo.tbl-cell-photo-64 img {
    width: 64px;
    height: 64px
}

.user-card-row .tbl-cell.tbl-cell-date {
    white-space: nowrap;
    width: 20px;
    padding-left: 10px;
    color: #6c7a86
}

.user-card-row .tbl-cell.tbl-cell-status {
    font-weight: 600;
    text-align: right
}

.user-card-row .tbl-cell.tbl-cell-status .font-icon {
    color: #919fa9;
    font-size: .9375rem
}

.user-card-row .tbl-cell.tbl-cell-status .font-icon.active {
    color: #f18482
}

.user-card-row .tbl-cell.tbl-cell-action {
    width: 20px;
    white-space: nowrap;
    padding-left: 10px;
    vertical-align: middle
}

.user-card-row .user-card-row-name {
    font-weight: 600;
    color: #343434
}

.user-card-row .user-card-row-name a {
    color: #343434
}

.user-card-row .user-card-row-name a:hover {
    color: #00a8ff
}

.user-card-row .user-card-row-mail a {
    color: #6c7a86
}

.user-card-row .user-card-row-mail a:hover {
    color: #00a8ff
}

.user-card-row .user-card-row-location,
.user-card-row .user-card-row-status {
    color: #6c7a86
}

.user-card-row .user-card-row-location a,
.user-card-row .user-card-row-status a {
    text-decoration: none;
    color: #6c7a86;
    border-bottom: solid 1px rgba(108, 122, 134, .3)
}

.user-card-row .user-card-row-location a:hover,
.user-card-row .user-card-row-status a:hover {
    border-bottom-color: transparent
}

.box-typical-full-screen .user-card-row {
    font-size: 1rem
}

.box-typical {
    background: #fff
}

.box-typical.box-typical-padding {
    padding: 20px 15px
}

.status-online:after {
    content: '';
    width: 6px;
    height: 6px;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: -1px;
    margin-left: 4px;
    background: #46c35f
}

.circle-progress-bar {
    width: 56px;
    background: #fff;
    position: fixed;
    left: 72px;
    bottom: 35px;
    z-index: 90;
    -webkit-border-radius: 50%;
    border-radius: 50%
}

.with-side-menu-compact .circle-progress-bar {
    left: 22px
}

@media (max-width:1199px) {
    .with-side-menu-compact .circle-progress-bar {
        left: 50%!important;
        margin-left: -28px!important
    }
}

@media (max-width:1056px) {
    .circle-progress-bar {
        left: 50%!important;
        margin-left: -28px!important
    }
}

.circle-progress-bar-typical {
    width: 168px;
    background: #fff;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    margin: 0 auto
}

.circle-progress-bar-typical .pie_progress__number {
    font-size: 3rem;
    font-weight: 600
}

.circle-progress-bar-typical.size-56 {
    width: 56px
}

.circle-progress-bar-typical.size-56 .pie_progress__number {
    font-size: 1rem
}

.progress {
    background-color: #ecf2f5;
    color: #00a8ff;
    height: 12px
}

.progress.progress-no-margin {
    margin: 0
}

.progress-bar {
    -webkit-border-radius: 0;
    border-radius: 0;
    background-color: #00a8ff;
    height: 12px;
    font-size: 11px;
    line-height: 13px
}

.progress-success {
    background: #46c35f
}

@media screen and (min-width:0) {
    .progress-success .progress-bar {
        background-color: #46c35f
    }
}

.progress-info {
    background: #ac6bec
}

@media screen and (min-width:0) {
    .progress-info .progress-bar {
        background-color: #ac6bec
    }
}

.progress-warning {
    background: #f29824
}

@media screen and (min-width:0) {
    .progress-warning .progress-bar {
        background-color: #f29824
    }
}

.progress-danger {
    background: #fa424a
}

@media screen and (min-width:0) {
    .progress-danger .progress-bar {
        background-color: #fa424a
    }
}

.progress-aquamarine {
    background: #21a788
}

@media screen and (min-width:0) {
    .progress-aquamarine .progress-bar {
        background-color: #21a788
    }
}

.progress-with-amount {
    position: relative;
    padding-right: 40px
}

.progress-with-amount .progress-with-amount-number {
    position: absolute;
    right: 0;
    top: 0;
    line-height: 16px;
    color: #919fa9
}

.progress-steps {
    display: inline-block;
    vertical-align: middle;
    zoom: 1
}

.progress-steps:after,
.progress-steps:before {
    content: " ";
    display: table
}

.progress-steps:after {
    clear: both
}

.progress-steps .progress-step {
    float: left;
    height: 20px;
    width: 38px;
    border: solid 2px #c5d6de;
    background: #ecf2f5
}

.progress-steps .progress-step:not(:first-child) {
    border-left: none
}

.progress-steps .progress-step:first-child {
    -webkit-border-radius: 25rem 0 0 25rem;
    border-radius: 25rem 0 0 25rem
}

.progress-steps .progress-step:last-child {
    -webkit-border-radius: 0 25rem 25rem 0;
    border-radius: 0 25rem 25rem 0
}

.progress-steps .progress-step.active {
    border-color: #00a8ff;
    background-color: rgba(0, 168, 255, .5)
}

.progress-steps-caption {
    display: inline-block;
    vertical-align: middle;
    line-height: 20px;
    color: #6c7a86;
    margin: 0 0 0 10px;
    font-weight: 600
}

.progress-compact-style .progress {
    background-color: #f6f8fa;
    height: 5px;
    -webkit-border-radius: 0;
    border-radius: 0;
    margin: 0 0 .4rem
}

.progress-compact-style .progress-bar {
    -webkit-border-radius: 0;
    border-radius: 0
}

.progress-compact-style .progress-compact-style-label {
    font-size: .6875rem
}

.progress-compact-style.progress-with-amount {
    padding-right: 30px
}

.progress-compact-style .progress-with-amount-number {
    font-size: .6875rem;
    top: -5px
}

.progress-compact-style .progress-header {
    zoom: 1;
    padding: 0 0 5px
}

.progress-compact-style .progress-header:after,
.progress-compact-style .progress-header:before {
    content: " ";
    display: table
}

.progress-compact-style .progress-header:after {
    clear: both
}

.progress-compact-style .progress-lbl {
    float: left
}

.progress-compact-style .progress-val {
    float: right;
    color: #919fa9
}

.alert {
    padding: 8px 10px;
    color: #343434;
    font-size: 1rem;
    -webkit-box-shadow: none;
    box-shadow: none;
    position: relative
}

.alert h1,
.alert h2,

.alert h3,
.alert h4,
.alert h5,
.alert h6,
.alert strong {
    font-weight: 600
}

.alert h1,
.alert h2,
.alert h3,
.alert h4,
.alert h5,
.alert h6 {
    font-size: 1rem;
    margin: 0;
    padding: 0
}

.alert.alert-close {
    padding-right: 35px
}

.alert .close {
    color: #00a8ff;
    opacity: .5;
    position: absolute;
    right: 10px;
    top: 5px!important;
    padding: 0
}

.alert .close:hover {
    opacity: 1
}

.alert [data-notify=title] {
    display: block;
    font-weight: 600
}

.alert [data-notify=icon] {
    color: #00a8ff;
    float: left;
    margin: 0 5px -2px 0
}

.alert [data-notify=icon].glyphicon {
    top: 3px
}

.alert ul {
    margin: 5px 0 0 10px
}

.alert ul li {
    position: relative;
    padding: 0 0 0 14px
}

.alert ul li:before {
    content: '';
    display: block;
    width: 6px;
    height: 6px;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 8px;
    border: solid 2px #343434
}

.alert .font-icon-inline {
    margin: 0 3px 0 0;
    position: relative;
    top: 1px
}

.alert.alert-icon {
    padding-left: 35px
}

.alert.alert-icon .font-icon {
    position: absolute;
    left: 11px;
    top: 9px
}

.alert .alert-btns {
    padding: 10px 0 0
}

.alert .alert-btns .btn {
    margin: 0 10px 5px 0
}

.alert.alert-no-border {
    border-color: transparent!important
}

.alert.alert-no-border .close {
    opacity: 1
}

.alert.alert-no-border .close:hover {
    opacity: .5
}

.alert.alert-border-left {
    border-color: transparent!important;
    border-left: solid 4px #343434!important
}

.alert.alert-border-left .close {
    opacity: 1
}

.alert.alert-border-left .close:hover {
    opacity: .5
}

.alert.alert-fill {
    color: #fff!important
}

.alert.alert-fill .font-icon-inline {
    color: #fff!important
}

.alert.alert-fill ul li:before {
    border-color: #fff!important
}

.alert.alert-fill .close {
    color: #fff!important;
    opacity: 1;
    text-shadow: none
}

.alert.alert-fill .close:hover {
    opacity: .5
}

.alert.alert-fill .alert-btns .btn {
    border-color: #fff!important;
    background-color: transparent!important;
    color: #fff!important
}

.alert.alert-fill .alert-btns .btn:hover {
    background-color: #fff!important
}

.alert.alert-avatar {
    padding-left: 50px;
    padding-top: 14px;
    padding-bottom: 14px
}

.alert.alert-avatar .close {
    top: 10px!important
}

.alert.alert-avatar .avatar-preview {
    position: absolute;
    left: 10px;
    top: 9px
}

.alert.alert-info {
    border-color: #00a8ff;
    background-color: #e4f6fd
}

.alert.alert-info .font-icon-inline,
.alert.alert-info.alert-icon .font-icon,
.ui-pnotify .alert.alert-info {
    color: #00a8ff
}

.alert.alert-info.alert-border-left,
.alert.alert-info.alert-txt-colored {
    color: #008dd6
}

.alert.alert-info.alert-no-border {
    background-color: #cbeefe
}

.alert.alert-info.alert-fill {
    background-color: #00a8ff
}

.alert.alert-info.alert-fill .alert-btns .btn:hover {
    color: #00a8ff!important
}

.alert.alert-info.alert-fill.alert-border-left {
    border-left-color: #0086cc!important
}

.alert.alert-info .alert-btns .btn {
    background-color: transparent;
    color: #00a8ff;
    border-color: #00a8ff
}

.alert.alert-info .alert-btns .btn:hover {
    color: #fff;
    background-color: #00a8ff
}

.alert.alert-info ul li:before {
    border-color: #00a8ff
}

.alert.alert-info.alert-border-left {
    border-left-color: #00a8ff!important
}

.alert.alert-success {
    border-color: #46c35f;
    background-color: #edf9ee
}

.alert.alert-success .close,
.alert.alert-success .font-icon-inline,
.alert.alert-success [data-notify=icon],
.alert.alert-success.alert-icon .font-icon,
.ui-pnotify .alert.alert-success {
    color: #46c35f
}

.alert.alert-success.alert-border-left,
.alert.alert-success.alert-txt-colored {
    color: #37a94e
}

.alert.alert-success.alert-fill {
    background-color: #46c35f
}

.alert.alert-success.alert-fill .alert-btns .btn:hover {
    color: #46c35f!important
}

.alert.alert-success.alert-fill.alert-border-left {
    border-left-color: #34a24a!important
}

.alert.alert-success .alert-btns .btn {
    background-color: transparent;
    color: #46c35f;
    border-color: #46c35f
}

.alert.alert-success .alert-btns .btn:hover {
    color: #fff;
    background-color: #46c35f
}

.alert.alert-success ul li:before {
    border-color: #46c35f
}

.alert.alert-success.alert-border-left {
    border-left-color: #46c35f!important
}

.alert.alert-warning {
    border-color: #f29824;
    background-color: #fdf4e6
}

.alert.alert-warning .close,
.alert.alert-warning .font-icon-inline,
.alert.alert-warning [data-notify=icon],
.alert.alert-warning.alert-icon .font-icon,
.ui-pnotify .alert.alert-warning {
    color: #f29824
}

.alert.alert-warning.alert-border-left,
.alert.alert-warning.alert-txt-colored {
    color: #e0840d
}

.alert.alert-warning.alert-no-border {
    background-color: #fcf0c2
}

.alert.alert-warning.alert-fill {
    background-color: #f29824
}

.alert.alert-warning.alert-fill .alert-btns .btn:hover {
    color: #f29824!important
}

.alert.alert-warning.alert-fill.alert-border-left {
    border-left-color: #d67e0d!important
}

.alert.alert-warning .alert-btns .btn {
    background-color: transparent;
    color: #f29824;
    border-color: #f29824
}

.alert.alert-warning .alert-btns .btn:hover {
    color: #fff;
    background-color: #f29824
}

.alert.alert-warning ul li:before {
    border-color: #f29824
}

.alert.alert-warning.alert-border-left {
    border-left-color: #f29824!important
}

.alert.alert-danger {
    border-color: #fa424a;
    background-color: #feecec
}

.alert.alert-danger .close,
.alert.alert-danger .font-icon-inline,
.alert.alert-danger [data-notify=icon],
.alert.alert-danger.alert-icon .font-icon,
.ui-pnotify .alert.alert-danger {
    color: #fa424a
}

.alert.alert-danger.alert-border-left,
.alert.alert-danger.alert-txt-colored {
    color: #f91a24
}

.alert.alert-danger.alert-no-border {
    background-color: #ffd8d9
}

.alert.alert-danger.alert-fill {
    background-color: #fa424a
}

.alert.alert-danger.alert-fill .alert-btns .btn:hover {
    color: #fa424a!important
}

.alert.alert-danger.alert-fill.alert-border-left {
    border-left-color: #f9101a!important
}

.alert.alert-danger .alert-btns .btn {
    background-color: transparent;
    color: #fa424a;
    border-color: #fa424a
}

.alert.alert-danger .alert-btns .btn:hover {
    color: #fff;
    background-color: #fa424a
}

.alert.alert-danger ul li:before {
    border-color: #fa424a
}

.alert.alert-danger.alert-border-left {
    border-left-color: #fa424a!important
}

.alert.alert-aquamarine {
    border-color: #21a788;
    background-color: #d1f0eb
}

.alert.alert-aquamarine .close,
.alert.alert-aquamarine .font-icon-inline,
.alert.alert-aquamarine.alert-icon .font-icon {
    color: #21a788
}

.alert.alert-aquamarine.alert-border-left,
.alert.alert-aquamarine.alert-txt-colored {
    color: #1a856c
}

.alert.alert-aquamarine.alert-fill {
    background-color: #21a788
}

.alert.alert-aquamarine.alert-fill .alert-btns .btn:hover {
    color: #21a788!important
}

.alert.alert-aquamarine.alert-fill.alert-border-left {
    border-left-color: #197c65!important
}

.alert.alert-aquamarine .alert-btns .btn {
    background-color: transparent;
    color: #21a788;
    border-color: #21a788
}

.alert.alert-aquamarine .alert-btns .btn:hover {
    color: #fff;
    background-color: #21a788
}

.alert.alert-aquamarine ul li:before {
    border-color: #21a788
}

.alert.alert-aquamarine.alert-border-left {
    border-left-color: #21a788!important
}

.alert.alert-grey-darker {
    border-color: #919fa9;
    background-color: #f6f8fa
}

.alert.alert-grey-darker .close,
.alert.alert-grey-darker .font-icon-inline,
.alert.alert-grey-darker.alert-icon .font-icon {
    color: #919fa9
}

.alert.alert-grey-darker.alert-border-left,
.alert.alert-grey-darker.alert-txt-colored {
    color: #7a8b97
}

.alert.alert-grey-darker.alert-fill {
    background-color: #919fa9
}

.alert.alert-grey-darker.alert-fill .alert-btns .btn:hover {
    color: #919fa9!important
}

.alert.alert-grey-darker.alert-fill.alert-border-left {
    border-left-color: #748693!important
}

.alert.alert-grey-darker .alert-btns .btn {
    background-color: transparent;
    color: #919fa9;
    border-color: #919fa9
}

.alert.alert-grey-darker .alert-btns .btn:hover {
    color: #fff;
    background-color: #919fa9
}

.alert.alert-grey-darker ul li:before {
    border-color: #919fa9
}

.alert.alert-grey-darker.alert-border-left {
    border-left-color: #919fa9!important
}

.alert.alert-blue-dirty {
    border-color: #2797ca;
    background-color: #cbeefe
}

.alert.alert-blue-dirty .close,
.alert.alert-blue-dirty .font-icon-inline,
.alert.alert-blue-dirty.alert-icon .font-icon {
    color: #2797ca
}

.alert.alert-blue-dirty.alert-border-left,
.alert.alert-blue-dirty.alert-txt-colored {
    color: #207da8
}

.alert.alert-blue-dirty.alert-fill {
    background-color: #2797ca
}

.alert.alert-blue-dirty.alert-fill .alert-btns .btn:hover {
    color: #2797ca!important
}

.alert.alert-blue-dirty.alert-fill.alert-border-left {
    border-left-color: #1f779f!important
}

.alert.alert-blue-dirty .alert-btns .btn {
    background-color: transparent;
    color: #2797ca;
    border-color: #2797ca
}

.alert.alert-blue-dirty .alert-btns .btn:hover {
    color: #fff;
    background-color: #2797ca
}

.alert.alert-blue-dirty ul li:before {
    border-color: #2797ca
}

.alert.alert-blue-dirty.alert-border-left {
    border-left-color: #2797ca!important
}

.alert-purple .alert,
.alert.alert-purple {
    border-color: #ac6bec;
    background-color: #eee2fc
}

.alert-purple .alert .close,
.alert-purple .alert .font-icon-inline,
.alert-purple .alert [data-notify=icon],
.alert-purple .alert.alert-icon .font-icon,
.alert.alert-purple .close,
.alert.alert-purple .font-icon-inline,
.alert.alert-purple [data-notify=icon],
.alert.alert-purple.alert-icon .font-icon {
    color: #ac6bec
}

.alert-purple .alert.alert-border-left,
.alert-purple .alert.alert-txt-colored,
.alert.alert-purple.alert-border-left,
.alert.alert-purple.alert-txt-colored {
    color: #9847e7
}

.alert-purple .alert.alert-fill,
.alert.alert-purple.alert-fill {
    background-color: #ac6bec
}

.alert-purple .alert.alert-fill .alert-btns .btn:hover,
.alert.alert-purple.alert-fill .alert-btns .btn:hover {
    color: #ac6bec!important
}

.alert-purple .alert.alert-fill.alert-border-left,
.alert.alert-purple.alert-fill.alert-border-left {
    border-left-color: #933ee6!important
}

.alert-purple .alert .alert-btns .btn,
.alert.alert-purple .alert-btns .btn {
    background-color: transparent;
    color: #ac6bec;
    border-color: #ac6bec
}

.alert-purple .alert .alert-btns .btn:hover,
.alert.alert-purple .alert-btns .btn:hover {
    color: #fff;
    background-color: #ac6bec
}

.alert-purple .alert ul li:before,
.alert.alert-purple ul li:before {
    border-color: #ac6bec
}

.alert-purple .alert.alert-border-left,
.alert.alert-purple.alert-border-left {
    border-left-color: #ac6bec!important
}

.alert-purple.ui-pnotify .alert {
    color: #ac6bec
}

.alert-grey .alert,
.alert-white .alert,
.alert.alert-grey,
.alert.alert-white {
    border-color: #c5d6de;
    color: #6c7a86
}

.alert-grey .alert .close,
.alert-grey .alert [data-notify=icon],
.alert-white .alert .close,
.alert-white .alert [data-notify=icon],
.alert.alert-grey .close,
.alert.alert-grey [data-notify=icon],
.alert.alert-white .close,
.alert.alert-white [data-notify=icon] {
    color: #6c7a86
}

.alert-white .alert,
.alert.alert-white {
    background-color: #fff
}

.alert-grey .alert,
.alert.alert-grey {
    background-color: #f6f8fa
}

.alert-grey.ui-pnotify .alert,
.alert-white.ui-pnotify .alert {
    color: #6c7a86
}

.alert.alert-facebook,
.alert.alert-google-plus,
.alert.alert-linkedin,
.alert.alert-twitter {
    color: #fff;
    padding-left: 50px
}

.alert.alert-facebook .close,
.alert.alert-google-plus .close,
.alert.alert-linkedin .close,
.alert.alert-twitter .close {
    color: #fff;
    opacity: 1;
    text-shadow: none
}

.alert.alert-facebook .close:hover,
.alert.alert-google-plus .close:hover,
.alert.alert-linkedin .close:hover,
.alert.alert-twitter .close:hover {
    opacity: .5
}

.alert.alert-facebook .font-icon,
.alert.alert-google-plus .font-icon,
.alert.alert-linkedin .font-icon,
.alert.alert-twitter .font-icon {
    position: absolute;
    left: 10px;
    top: 10px;
    font-size: 1.875rem
}

.alert.alert-facebook {
    background-color: #37559a;
    border-color: #37559a
}

.alert.alert-google-plus {
    background-color: #d33928;
    border-color: #d33928
}

.alert.alert-twitter {
    background-color: #00aaed;
    border-color: #00aaed
}

.alert.alert-linkedin {
    background-color: #007ab4;
    border-color: #007ab4
}

.ui-pnotify .alert {
    -webkit-box-shadow: none;
    box-shadow: none
}

.ui-pnotify .alert .ui-pnotify-icon {
    position: relative;
    top: 2px
}

.alert-with-icon .alert {
    padding-left: 35px
}

.alert-with-icon .alert .ui-pnotify-icon {
    margin: 0;
    position: absolute;
    left: 11px;
    top: 8px
}

.alert-with-icon .alert .font-icon {
    position: relative;
    top: -2px
}

.sweet-alert {
    padding-left: 2rem;
    padding-right: 2rem
}

.sweet-alert h2 {
    font-weight: 400;
    position: relative;
    margin: 2rem 0 1rem
}

.sweet-alert p {
    margin: 0 0 10px
}

.sweet-alert .lead {
    font-weight: 400;
    font-size: 1.25rem;
    color: #343434;
    margin-bottom: 20px
}

.sweet-alert .btn {
    min-width: 150px;
    -webkit-border-radius: 25rem;
    border-radius: 25rem;
    -webkit-box-shadow: none!important;
    box-shadow: none!important;
    margin: 0 1rem 17px
}

.sweet-alert .btn.btn-success {
    border-color: #46c35f
}

.sweet-alert .btn.btn-success:hover {
    border-color: #5dca73
}

.sweet-alert .btn.btn-warning {
    border-color: #f29824
}

.sweet-alert .btn.btn-warning:hover {
    border-color: #f4a641
}

.sweet-alert .btn.btn-danger {
    border-color: #fa424a
}

.sweet-alert .btn.btn-danger:hover {
    border-color: #fb6067
}

.sweet-alert .btn.btn-info {
    border-color: #ac6bec
}

.sweet-alert .btn.btn-info:hover {
    border-color: #bb86ef
}

.sweet-alert .icon.success .line {
    background-color: #46c35f
}

.sweet-alert .icon.success .placeholder {
    border-color: rgba(70, 195, 95, .3)
}

.sweet-alert .icon.warning {
    border-color: #f29824
}

.sweet-alert .icon.error {
    border-color: #fa424a
}

.sweet-alert .icon.error .line {
    background-color: #fa424a
}

.sweet-alert .icon.info {
    border-color: #00a8ff
}

.sweet-alert .icon.info:after,
.sweet-alert .icon.info:before {
    background-color: #00a8ff
}

.sweet-alert .form-group {
    margin-bottom: 30px
}

.nav.nav-pills .nav-item {
    margin: 0 25px 0 0
}

.nav.nav-pills .nav-link {
    font-size: 1rem;
    color: #818181;
    font-weight: 600;
    -webkit-border-radius: 25rem;
    border-radius: 25rem;
    padding: 5px 10px
}

.nav.nav-pills .nav-link:hover {
    color: #00a8ff
}

.nav.nav-pills .nav-link.active {
    color: #fff;
    background-color: #00a8ff
}

.breadcrumb {
    font-size: 1rem;
    color: #6c7a86;
    background-color: #ecf2f5
}

.breadcrumb>.active {
    color: inherit
}

.breadcrumb.breadcrumb-clean {
    background: 0 0;
    padding: 1.5rem 0;
    color: #919fa9;
    border-bottom: solid 1px #c5d6de;
    -webkit-border-radius: 0;
    border-radius: 0;
    margin: 0 0 1.5rem
}

.breadcrumb.breadcrumb-clean>li+li::before {
    content: '>'
}

.breadcrumb.breadcrumb-clean>.active {
    color: #343434
}

.breadcrumb.breadcrumb-clean a {
    text-decoration: none;
    color: #919fa9;
    border: none
}

.breadcrumb.breadcrumb-clean a:hover {
    color: #00a8ff
}

.breadcrumb.breadcrumb-simple {
    background: 0 0;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 0
}

.pagination {
    font-size: 1rem
}

.page-link {
    border-color: #d8e2e7;
    color: #0082c6
}

.page-link:focus,
.page-link:hover {
    background-color: #f6f8fa;
    color: #0082c6
}

.page-item.active .page-link {
    border-color: #00a8ff!important;
    background-color: #00a8ff!important
}

.pager {
    font-size: 1rem
}

.pager li>a {
    border-color: #d8e2e7;
    color: #0082c6;
    -webkit-border-radius: .25rem;
    border-radius: .25rem
}

.pager li>a:hover {
    background-color: #f6f8fa
}

.pager .disabled>a {
    color: #919fa9!important
}

.tooltip {
    font-size: .8125rem;
    font-weight: 600;
    line-height: normal
}

.tooltip.in {
    opacity: .95
}

.tooltip.bs-tether-element-attached-top .tooltip-arrow,
.tooltip.tooltip-bottom .tooltip-arrow {
    border-bottom-color: #2c3949
}

.tooltip.bs-tether-element-attached-bottom .tooltip-arrow,
.tooltip.tooltip-top .tooltip-arrow {
    border-top-color: #2c3949
}

.tooltip-inner {
    background-color: #2c3949;
    padding-bottom: 5px
}

.hint-circle {
    display: inline-block;
    vertical-align: baseline;
    width: 16px;
    height: 16px;
    border: solid 1px #6c7a86;
    color: #6c7a86;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    font-size: .75rem;
    text-align: center;
    line-height: 15px;
    margin: 0 0 0 5px;
    cursor: pointer
}

.hint-circle.red {
    border-color: #fa424a;
    color: #fa424a
}

.hint-circle.green {
    border-color: #46c35f;
    color: #46c35f
}

.hint-circle.blue {
    border-color: #00a8ff;
    color: #00a8ff
}

.hint-circle.orange {
    border-color: #f29824;
    color: #f29824
}

.hint-circle.purple {
    border-color: #ac6bec;
    color: #ac6bec
}

.popover {
    border-color: #d8e2e7;
    padding: 0
}

.popover.bs-tether-element-attached-bottom .popover-arrow,
.popover.popover-top .popover-arrow {
    border-top-color: #d8e2e7
}

.popover.bs-tether-element-attached-top .popover-arrow,
.popover.popover-bottom .popover-arrow {
    border-bottom-color: #d8e2e7
}

.popover.bs-tether-element-attached-top .popover-arrow:after,
.popover.popover-bottom .popover-arrow:after {
    border-bottom-color: #ecf2f5
}

.popover.bs-tether-element-attached-left .popover-arrow,
.popover.popover-right .popover-arrow {
    border-right-color: #d8e2e7
}

.popover.bs-tether-element-attached-right .popover-arrow,
.popover.popover-left .popover-arrow {
    border-left-color: #d8e2e7
}

.popover-title {
    border-bottom-color: #d8e2e7;
    background: #ecf2f5;
    -webkit-border-radius: .3rem .3rem 0 0;
    border-radius: .3rem .3rem 0 0
}

.popover-content {
    padding-bottom: .8rem
}

.jspContainer .jspTrack {
    opacity: 0;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out
}

.jspContainer:hover .jspTrack {
    opacity: 1
}

.scrollable-block {
    overflow: auto
}

.scrollable-block.scrollable-gradient .jspContainer:after {
    content: '';
    display: block;
    width: 100%;
    height: 30px;
    position: absolute;
    left: 0;
    bottom: -1px;
    background: url(/img/gradient-scroll.png) repeat-x bottom
}

.scrollable-block.scrollable-gradient .jspVerticalBar {
    z-index: 10
}

body.modal-open {
    overflow: hidden!important;
    position: relative
}

.modal-dialog:not(.modal-sm):not(.modal-lg) {
    width: 680px
}

@media (max-width:767px) {
    .modal-dialog:not(.modal-sm):not(.modal-lg) {
        width: 500px
    }
}

@media (max-width:544px) {
    .modal-dialog:not(.modal-sm):not(.modal-lg) {
        width: auto
    }
}

.modal-content {
    -webkit-box-shadow: none;
    box-shadow: none;
    border: solid 1px #d8e2e7
}

.modal-header {
    border-bottom-color: #d8e2e7
}

.modal-title {
    font-size: 1.125rem;
    font-weight: 600
}

.modal-footer {
    border-top-color: #d8e2e7
}

.modal-close {
    position: absolute;
    right: 15px;
    top: 17px;
    color: #adb7be;
    background: 0 0;
    border: none
}

.modal-close:hover {
    color: #00a8ff
}

.close {
    font-size: 2rem;
    color: #6c7a86
}

.modal-upload {
    zoom: 1
}

.modal-upload:after,
.modal-upload:before {
    content: " ";
    display: table
}

.modal-upload:after {
    clear: both
}

.modal-upload .modal-upload-side {
    float: left;
    width: 160px;
    padding: 15px 10px 15px 15px;
    position: relative
}

.modal-upload .modal-upload-cont {
    float: right;
    width: 100%;
    margin-left: -160px
}

.modal-upload .modal-upload-cont-in {
    height: 430px;
    margin-left: 160px;
    border-left: solid 1px #d8e2e7
}

@media (max-width:767px) {
    .modal-upload:not(.menu-bottom) .modal-upload-cont,
    .modal-upload:not(.menu-bottom) .modal-upload-side {
        float: none;
        width: auto;
        margin: 0
    }
    .modal-upload:not(.menu-bottom) .modal-upload-cont-in {
        margin: 0;
        border-left: none
    }
    .modal-upload:not(.menu-bottom) .modal-upload-side {
        border-top: solid 1px #d8e2e7;
        text-align: center
    }
    .modal-upload:not(.menu-bottom) .modal-upload-side .upload-list li {
        display: inline-block;
        margin: 0 5px
    }
    .modal-upload:not(.menu-bottom) .modal-upload-side .upload-list a span {
        display: none
    }
}

.modal-upload.menu-bottom .modal-upload-cont,
.modal-upload.menu-bottom .modal-upload-side {
    float: none;
    width: auto;
    margin: 0
}

.modal-upload.menu-bottom .modal-upload-cont-in {
    margin: 0;
    border-left: none
}

.modal-upload.menu-bottom .modal-upload-side {
    border-top: solid 1px #d8e2e7;
    text-align: center
}

.modal-upload.menu-bottom .modal-upload-side .upload-list {
    padding-top: 5px
}

.modal-upload.menu-bottom .modal-upload-side .upload-list .font-icon {
    font-size: 1.5rem
}

.modal-upload.menu-bottom .modal-upload-side .upload-list .font-icon.font-icon-one-drive,
.modal-upload.menu-bottom .modal-upload-side .upload-list .font-icon.font-icon-yandex-disk {
    font-size: 2rem
}

.modal-upload.menu-bottom .modal-upload-side .upload-list .font-icon.font-icon-box {
    font-size: 2.25rem
}

.modal-upload.menu-bottom .modal-upload-side .upload-list li {
    display: inline-block;
    margin: 0 10px
}

.modal-upload.menu-bottom .modal-upload-side .upload-list a span {
    display: none
}

.modal-upload.menu-big-icons .modal-upload-cont {
    margin-left: -82px
}

.modal-upload.menu-big-icons .modal-upload-cont-in {
    margin-left: 72px
}

.modal-upload.menu-big-icons .modal-upload-side {
    width: 72px;
    padding: 0
}

.modal-upload.menu-big-icons .modal-upload-side .upload-list .font-icon {
    font-size: 1.875rem;
    margin: 0;
    line-height: 1;
    position: relative;
    top: 1px
}

.modal-upload.menu-big-icons .modal-upload-side .upload-list li {
    margin: 0;
    padding: 0;
    border-top: solid 1px #d8e2e7;
    text-align: center
}

.modal-upload.menu-big-icons .modal-upload-side .upload-list li:first-child {
    border-top: none
}

.modal-upload.menu-big-icons .modal-upload-side .upload-list li a {
    display: block;
    height: 71px;
    line-height: 70px;
    position: relative
}

.modal-upload.menu-big-icons .modal-upload-side .upload-list li a.active:after {
    content: '';
    display: block;
    width: 0;
    height: 100%;
    position: absolute;
    right: -1px;
    top: 0;
    border-right: solid 1px #fff
}

.modal-upload-body {
    height: 366px
}

.modal-upload-bottom {
    height: 64px;
    line-height: 64px;
    border-top: solid 1px #d8e2e7;
    text-align: right;
    padding: 0 20px
}

.modal-upload-bottom .btn {
    margin: 0 6px;
    min-width: 100px
}

.upload-list li {
    margin: 0 0 .5rem
}

.upload-list .font-icon {
    vertical-align: middle;
    line-height: 20px;
    font-size: 1.125rem;
    color: #adb7be;
    margin: 0 5px 0 0;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out
}

.upload-list a {
    color: #343434
}

.upload-list a.active,
.upload-list a:hover {
    color: #00a8ff
}

.upload-list a.active .font-icon,
.upload-list a:hover .font-icon {
    color: #00a8ff
}

.upload-dropbox {
    padding: 94px 15px 90px;
    text-align: center
}

.upload-dropbox h3 {
    font-weight: 600;
    font-size: 1.0625rem
}

.upload-dropbox p {
    color: #6c7a86;
    font-size: .9375rem;
    margin: 0 0 1rem
}

.upload-dropbox .btn {
    margin: 0 0 1rem
}

.upload-dropbox .text-muted {
    font-size: .9375rem
}

.upload-gd-header {
    display: table;
    width: 100%;
    border-collapse: collapse
}

.upload-gd-header .tbl-cell {
    vertical-align: middle;
    padding: 15px
}

.upload-gd-header .tbl-cell-btns {
    white-space: nowrap;
    width: 30px;
    padding-left: 0;
    font-size: 1rem
}

.upload-gd-header .tbl-cell-btns button {
    border: none;
    background: 0 0;
    margin: 0 7px;
    color: #adb7be
}

.upload-gd-header .tbl-cell-btns button:hover {
    color: #00a8ff
}

@media (max-width:544px) {
    .upload-gd-header {
        display: block;
        margin-bottom: 15px
    }
    .upload-gd-header .tbl-cell,
    .upload-gd-header .tbl-row {
        display: block
    }
    .upload-gd-header .tbl-cell {
        padding: 15px 15px 0
    }
}

.gd-doc {
    text-align: center;
    font-size: .875rem;
    line-height: 1.6;
    margin: 0 0 1rem
}

.gd-doc .gd-doc-preview {
    margin: 0 0 .6rem;
    position: relative
}

.gd-doc .gd-doc-preview img {
    height: 128px;
    max-width: 100%;
    display: block;
    width: 90px;
    margin: 0 auto;
    border: solid 2px #dbe4ea;
    -webkit-border-radius: 4px;
    border-radius: 4px
}

.gd-doc .gd-doc-preview .icon {
    display: none;
    width: 22px;
    height: 22px;
    background: #00a8ff;
    -webkit-border-radius: .25rem 0 0 0;
    border-radius: .25rem 0 0 0;
    position: absolute;
    bottom: 2px;
    right: 2px;
    color: #fff;
    text-align: center;
    line-height: 22px
}

.gd-doc .gd-doc-preview .icon .font-icon {
    vertical-align: middle;
    position: relative;
    top: 2px
}

.gd-doc .gd-doc-preview a {
    position: relative;
    display: inline-block
}

.gd-doc .gd-doc-preview a:hover img {
    border-color: #00a8ff
}

.gd-doc .gd-doc-preview a:hover .icon {
    display: block
}

.gd-doc .gd-doc-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600
}

.gd-doc .gd-doc-date {
    color: #6c7a86
}

.gd-doc-grid {
    zoom: 1
}

.gd-doc-grid:after,
.gd-doc-grid:before {
    content: " ";
    display: table
}

.gd-doc-grid:after {
    clear: both
}

.gd-doc-col {
    float: left;
    width: 25%
}

@media (max-width:480px) {
    .gd-doc-col {
        width: 33.333333%
    }
}

@media (max-width:380px) {
    .gd-doc-col {
        width: 50%
    }
}

@media (max-width:321px) {
    .gd-doc-col {
        width: 100%
    }
}

.page-content-header {
    background: #fff;
    border-bottom: solid 1px #c5d6de;
    margin: -30px 0 30px;
    position: relative
}

.page-content-header:after,
.page-content-header:before {
    content: '';
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    display: block;
    height: 100%;
    width: 15px;
    position: absolute;
    bottom: -1px;
    background: #fff;
    border-bottom: solid 1px #c5d6de
}

.page-content-header:before {
    left: -15px
}

.page-content-header:after {
    right: -15px
}

.page-content-header h1,
.page-content-header h2,
.page-content-header h3,
.page-content-header h4,
.page-content-header h5,
.page-content-header h6 {
    margin: 0
}

.page-content-header .tbl-cell {
    vertical-align: middle;
    padding: 21px 0 20px
}

.page-content-header .tbl-cell-action {
    width: 10px;
    padding-left: 15px;
    white-space: nowrap
}

.page-content-header .tbl-cell-action .btn {
    margin: -3px 0 -5px
}

@media (max-width:1056px) {
    .page-content-header:after,
    .page-content-header:before {
        display: none
    }
}

.section-header {
    padding: 0 0 27px
}

.section-header .tbl-cell {
    vertical-align: middle
}

.section-header .tbl-cell-action {
    white-space: nowrap;
    padding-left: 15px
}

.section-header .tbl-cell-action.select {
    width: 180px
}

.section-header .tbl-cell-action.button {
    width: 104px
}

.section-header h1,
.section-header h2,
.section-header h3,
.section-header h4,
.section-header h5,
.section-header h6 {
    margin: 0
}

.section-header .subtitle {
    font-size: .8125rem;
    padding: 5px 0 0
}

.section-header .breadcrumb {
    margin-bottom: -13px;
    margin-top: -9px
}

@media (max-width:767px) {
    .section-header {
        display: block
    }
    .section-header .tbl-cell,
    .section-header .tbl-row {
        display: block
    }
    .section-header .tbl-cell {
        padding-bottom: 10px
    }
    .section-header .tbl-cell-action {
        white-space: nowrap;
        padding-left: 0
    }
}

.tabs-section {
    margin: 0 0 20px
}

.tabs-section>.tab-content:not(.no-styled) {
    background: #fff;
    border: solid 1px #d8e2e7;
    border-top: none;
    -webkit-border-radius: 0 0 5px 5px;
    border-radius: 0 0 5px 5px;
    padding: 15px;
    min-height:520px;
}

.tabs-section-nav {
    overflow: auto;
    width: 100%;
    text-align: center;
    font-size: 1rem;
    border-top: solid 1px #d8e2e7
}

.tabs-section-nav .nav-item {
    float: left;
    background: #f6f8fa;
    white-space: nowrap;
    padding: 0
}

.tabs-section-nav .nav-item:first-child .nav-link {
    border-left-color: #d8e2e7
}

.tabs-section-nav .nav-item:last-child .nav-link {
    border-right-color: #d8e2e7
}

.tabs-section-nav .nav-link {
    padding: 0;
    display: block;
    color: #6c7a86;
    font-weight: 600;
    border: solid 1px #d8e2e7;
    border-left-color: transparent;
    border-right-color: transparent;
    border-top: none
}

.tabs-section-nav .nav-link.active {
    border-left-color: #d8e2e7;
    border-right-color: #d8e2e7;
    background: #fff;
    color: #343434;
    border-bottom-color: #fff
}

.tabs-section-nav .nav-link.active .nav-link-in {
    border-top-color: #00a8ff
}

.tabs-section-nav .nav-link:not(.active) .label {
    background-color: #adb7be
}

.tabs-section-nav .nav-link-in {
    display: block;
    padding: 10px;
    line-height: 28px;
    border-top: solid 4px transparent;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out
}

.tabs-section-nav .label {
    padding-left: .5em;
    padding-right: .5em;
    font-size: .875rem
}

.tabs-section-nav .tbl .nav {
    display: table-row
}

.tabs-section-nav .tbl .nav-item {
    float: none;
    display: table-cell;
    vertical-align: middle
}

.tabs-section-nav.tabs-section-nav-left .nav {
    background-color: #f6f8fa;
    zoom: 1;
    border: solid 1px #d8e2e7;
    border-left: none;
    border-top: none
}

.tabs-section-nav.tabs-section-nav-left .nav:after,
.tabs-section-nav.tabs-section-nav-left .nav:before {
    content: " ";
    display: table
}

.tabs-section-nav.tabs-section-nav-left .nav:after {
    clear: both
}

.tabs-section-nav.tabs-section-nav-left .nav-item {
    margin-bottom: -1px
}

.tabs-section-nav.tabs-section-nav-left .nav-item:last-child .nav-link {
    border-right: none
}

.tabs-section-nav.tabs-section-nav-left .nav-item:last-child .nav-link.active {
    border-right: solid 1px #d8e2e7
}

.tabs-section-nav.tabs-section-nav-left .nav-link-in {
    padding-right: 15px;
    padding-left: 15px
}

.tabs-section-nav.tabs-section-nav-left .nav-link,
.tabs-section-nav.tabs-section-nav-left .nav-link-in {
    -webkit-transition: none;
    transition: none
}

.tabs-section-nav.tabs-section-nav-icons .fa,
.tabs-section-nav.tabs-section-nav-icons .font-icon,
.tabs-section-nav.tabs-section-nav-icons .glyphicon {
    display: block;
    color: #adb7be;
    margin: 0 0 .2em;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out
}

.tabs-section-nav.tabs-section-nav-icons .nav-link-in {
    line-height: 1
}

.tabs-section-nav.tabs-section-nav-icons .nav-link.active {
    color: #00a8ff
}

.tabs-section-nav.tabs-section-nav-icons .nav-link.active .fa,
.tabs-section-nav.tabs-section-nav-icons .nav-link.active .font-icon,
.tabs-section-nav.tabs-section-nav-icons .nav-link.active .glyphicon {
    color: #00a8ff
}

.tabs-section-nav.tabs-section-nav-inline {
    border: none
}

.tabs-section-nav.tabs-section-nav-inline .nav {
    display: block;
    border: solid 1px #d8e2e7;
    zoom: 1;
    background: #f6f8fa
}

.tabs-section-nav.tabs-section-nav-inline .nav:after,
.tabs-section-nav.tabs-section-nav-inline .nav:before {
    content: " ";
    display: table
}

.tabs-section-nav.tabs-section-nav-inline .nav:after {
    clear: both
}

.tabs-section-nav.tabs-section-nav-inline .nav-item {
    display: block;
    float: left;
    background: 0 0;
    margin: 0 20px -1px
}

.tabs-section-nav.tabs-section-nav-inline .nav-link {
    border: none;
    border-bottom: 1px solid #d8e2e7;
    height: 45px;
    padding: 12px 0 0;
    background: 0 0!important
}

.tabs-section-nav.tabs-section-nav-inline .nav-link.active {
    border-bottom: solid 3px #343434
}

.tabs-section-nav.tabs-section-nav-data .number,
.tabs-section-nav.tabs-section-nav-data .percent,
.tabs-section-nav.tabs-section-nav-data .title {
    display: block;
    line-height: 1
}

.tabs-section-nav.tabs-section-nav-data .number {
    color: #343434
}

.tabs-section-nav.tabs-section-nav-data .percent,
.tabs-section-nav.tabs-section-nav-data .title {
    font-size: .75rem
}

.tabs-section-nav.tabs-section-nav-data .number,
.tabs-section-nav.tabs-section-nav-data .percent {
    margin: 0 0 6px
}

.tabs-section-nav.tabs-section-nav-data .title {
    text-transform: uppercase;
    color: #919fa9
}

.tabs-section-nav.tabs-section-nav-data .nav-link.active .title {
    color: #343434
}

.tabs-section-simple .nav {
    zoom: 1;
    margin: 0 0 10px
}

.tabs-section-simple .nav:after,
.tabs-section-simple .nav:before {
    content: " ";
    display: table
}

.tabs-section-simple .nav:after {
    clear: both
}

.tabs-section-simple .nav-item {
    float: left;
    margin: 0 30px 10px 0;
    font-size: .8125rem;
    font-weight: 600
}

.tabs-section-simple .nav-link {
    text-decoration: none;
    color: #919fa9;
    border: none
}

.tabs-section-simple .nav-link:hover {
    color: #00a8ff
}

.tabs-section-simple .nav-link.active {
    color: #343434
}

.fancybox-skin {
    -webkit-border-radius: 0;
    border-radius: 0
}

.card {
    border-color: #d8e2e7;
    margin-bottom: 30px
}

.card-footer,
.card-header {
    background: 0 0
}

.card-header {
    border-bottom-color: #d8e2e7;
    font-weight: 600
}

.card-header.card-header-lg {
    font-size: 1.125rem
}

.card-header.card-header-xl {
    font-size: 1.25rem
}

.card-header.card-header-xxl {
    font-size: 1.375rem
}

.card-header .modal-close {
    font-size: .75rem
}

.card-block {
    padding: 16px
}

.card-default .card-header {
    background-color: #f6f8fa
}

.card-inversed .card-block {
    background-color: #f6f8fa
}

.card-blue {
    border-color: #00a8ff
}

.card-blue .card-header {
    border-bottom-color: #00a8ff;
    background-color: #e4f6fe
}

.card-blue .card-header .modal-close {
    color: #00a8ff
}

.card-blue .card-header .modal-close:hover {
    opacity: .75
}

.card-green {
    border-color: #46c35f
}

.card-green .card-header {
    border-bottom-color: #46c35f;
    background-color: #edf9ee
}

.card-green .card-header .modal-close {
    color: #46c35f
}

.card-green .card-header .modal-close:hover {
    opacity: .75
}

.card-purple {
    border-color: #ac6bec
}

.card-purple .card-header {
    border-bottom-color: #ac6bec;
    background-color: #eee2fc
}

.card-purple .card-header .modal-close {
    color: #ac6bec
}

.card-purple .card-header .modal-close:hover {
    opacity: .75
}

.card-red {
    border-color: #fa424a
}

.card-red .card-header {
    border-bottom-color: #fa424a;
    background-color: #feecec
}

.card-red .card-header .modal-close {
    color: #fa424a
}

.card-red .card-header .modal-close:hover {
    opacity: .75
}

.card-orange {
    border-color: #f29824
}

.card-orange .card-header {
    border-bottom-color: #f29824;
    background-color: #fdf4e6
}

.card-orange .card-header .modal-close {
    color: #f29824
}

.card-orange .card-header .modal-close:hover {
    opacity: .75
}

.card-blue-fill {
    border-color: #00a8ff
}

.card-blue-fill .card-header {
    border-bottom-color: #00a8ff;
    background-color: #00a8ff;
    color: #fff
}

.card-blue-fill .card-header .modal-close {
    color: #fff
}

.card-blue-fill .card-header .modal-close:hover {
    opacity: .75
}

.bootstrap-touchspin .bootstrap-touchspin-postfix {
    border-left: none
}

.bootstrap-touchspin .bootstrap-touchspin-prefix {
    border-right: none
}

.bootstrap-touchspin .input-group-btn-vertical>.btn {
    padding-top: 9px;
    padding-bottom: 9px;
    padding-left: 12px;
    text-align: center
}

.bootstrap-touchspin .input-group-btn-vertical i {
    left: 7px;
    top: 4px
}

.bootstrap-touchspin .dropdown-menu>li>a {
    padding: 4px 12px;
    display: block;
    font-size: .875rem;
    color: #343434
}

.bootstrap-touchspin .dropdown-menu>li>a:hover {
    color: #00a8ff
}

.bootstrap-touchspin.input-group-sm .btn,
.bootstrap-touchspin.input-group-sm .form-control {
    height: 32px
}

.avatar-preview {
    display: inline-block;
    vertical-align: middle
}

.avatar-preview img {
    display: block;
    width: 100%;
    height: 100%;
    -webkit-border-radius: 50%;
    border-radius: 50%
}

.avatar-preview.avatar-preview-24 {
    width: 24px;
    height: 24px
}

.avatar-preview.avatar-preview-32 {
    width: 32px;
    height: 32px
}

.avatar-preview.avatar-preview-48 {
    width: 48px;
    height: 48px
}

.avatar-preview.avatar-preview-64 {
    width: 64px;
    height: 64px
}

.avatar-preview.avatar-preview-100 {
    width: 100px;
    height: 100px
}

.avatar-preview.avatar-preview-128 {
    width: 128px;
    height: 128px
}

.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-right: .25rem;
    margin-left: .25rem;
    vertical-align: middle;
    content: "";
    border-top: 5px solid;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    position: relative;
    top: -1px
}

.caret.caret-up {
    border-top: none;
    border-bottom: solid 5px
}

.dd {
    position: relative;
    display: block;
    margin: 0;
    padding: 0;
    list-style: none
}

.dd-list {
    display: block;
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none
}

.dd-list .dd-list {
    padding-left: 30px
}

.dd-collapsed .dd-list {
    display: none
}

.dd-empty,
.dd-item,
.dd-placeholder {
    display: block;
    position: relative;
    margin: 0;
    padding: 0;
    min-height: 20px;
    line-height: 19px
}

.dd-handle {
    display: block;
    height: 30px;
    margin: 5px 0;
    padding: 5px 10px;
    text-decoration: none;
    font-weight: 600;
    border: 1px solid #d8e2e7;
    background: #fff;
    -webkit-border-radius: .25rem;
    border-radius: .25rem;
    cursor: move
}

.dd-handle:hover {
    color: #fff;
    background: #00a8ff;
    border-color: #00a8ff
}

.dd-item>button {
    display: block;
    position: relative;
    cursor: pointer;
    float: left;
    width: 25px;
    height: 20px;
    margin: 5px 0;
    padding: 0;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    border: 0;
    background: 0 0;
    line-height: 1;
    text-align: center;
    font-weight: 600;
    -webkit-transition: none;
    transition: none
}

.dd-item>button:before {
    content: '+';
    display: block;
    position: absolute;
    width: 100%;
    text-align: center;
    text-indent: 0
}

.dd-item>button[data-action=collapse]:before {
    content: '-'
}

.dd-item>button.hover {
    color: #fff
}

.dd-empty,
.dd-placeholder {
    margin: 5px 0;
    padding: 0;
    min-height: 30px;
    background: #e4f6fe;
    border: 1px dashed #00a8ff;
    -webkit-border-radius: .25rem;
    border-radius: .25rem
}

.dd-empty {
    border: 1px dashed #bbb;
    min-height: 100px;
    background-color: #e5e5e5
}

.dd-dragel {
    position: absolute;
    pointer-events: none;
    z-index: 9999;
    opacity: .8;
    list-style: none
}

.dd-dragel li {
    list-style: none
}

.dd-dragel>.dd-item .dd-handle {
    margin-top: 0
}

.dd-dragel .dd-item>button {
    display: none
}

.dd-dragel .dd-handle {
    line-height: 20px;
    min-height: 30px;
    padding: 5px 10px
}

.dd-green .dd-handle:hover {
    background-color: #46c35f;
    border-color: #46c35f
}

.dd-green .dd-empty,
.dd-green .dd-placeholder {
    background-color: #edf9ee;
    border-color: #46c35f
}

.dd-purple .dd-handle {
    border-color: #eee2fc;
    background-color: #eee2fc
}

.dd-purple .dd-handle:hover {
    background-color: #ac6bec;
    border-color: #ac6bec
}

.dd-purple .dd-empty,
.dd-purple .dd-placeholder {
    background-color: transparent;
    border-color: #ac6bec
}

.dd-grey .dd-handle {
    background-color: #d8e2e7
}

.dd-grey .dd-handle:hover {
    background-color: #919fa9;
    border-color: #919fa9
}

.dd-red .dd-handle {
    border-color: #ffd8d9;
    background-color: #ffd8d9
}

.dd-red .dd-handle:hover {
    background-color: #fa424a;
    border-color: #fa424a
}

.dd-red .dd-empty,
.dd-red .dd-placeholder {
    background-color: transparent;
    border-color: #fa424a
}

.dd-aquamarine .dd-handle {
    border-color: #d1f0eb;
    background-color: #d1f0eb
}

.dd-aquamarine .dd-handle:hover {
    background-color: #21a788;
    border-color: #21a788
}

.dd-aquamarine .dd-empty,
.dd-aquamarine .dd-placeholder {
    background-color: transparent;
    border-color: #21a788
}

.dd3-content {
    display: block;
    height: 30px;
    margin: 5px 0;
    padding: 5px 10px 5px 40px;
    text-decoration: none;
    font-weight: 600;
    border: 1px solid #d8e2e7;
    background: #fff;
    -webkit-border-radius: .25rem;
    border-radius: .25rem
}

.dd-dragel>.dd3-item>.dd3-content {
    margin: 0
}

.dd3-item>button {
    margin-left: 30px
}

.dd3-item>button.hover {
    color: #343434
}

.dd3-handle {
    position: absolute;
    margin: 0;
    left: 0;
    top: 0;
    cursor: move;
    width: 30px;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    border: 1px solid #d8e2e7;
    background: #d8e2e7;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    color: #6c7a86
}

.dd3-handle:before {
    font-family: startui!important;
    font-style: normal!important;
    font-weight: 400!important;
    font-variant: normal!important;
    text-transform: none!important;
    speak: none;
    line-height: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    vertical-align: middle;
    position: relative;
    top: -.15em;
    content: "\64";
    display: block;
    position: absolute;
    left: 0;
    top: -1px;
    width: 100%;
    text-align: center;
    text-indent: 0;
    font-size: 1rem;
    line-height: 30px
}

.dd3-handle:hover {
    background: #00a8ff
}

.dd3-handle:hover+.dd3-content {
    border-color: #00a8ff
}

.dd3-blue .dd3-content {
    border-color: #cbeefe
}

.dd3-blue .dd3-handle {
    background-color: #cbeefe;
    border-color: #cbeefe;
    color: #00a8ff
}

.dd3-blue .dd3-handle:hover {
    background-color: #00a8ff;
    border-color: #00a8ff;
    color: #fff
}

.dd3-blue .dd-empty,
.dd3-blue .dd-placeholder {
    background-color: transparent
}

.dd3-orange .dd3-content {
    border-color: #f29824
}

.dd3-orange .dd3-handle {
    background-color: #f29824;
    border-color: #f29824;
    color: #fff
}

.dd3-orange .dd3-handle:hover {
    background-color: #fa424a;
    border-color: #fa424a
}

.dd3-orange .dd3-handle:hover+.dd3-content {
    border-color: #fa424a
}

.dd3-orange .dd-empty,
.dd3-orange .dd-placeholder {
    background-color: transparent;
    border-color: #fa424a
}

.horizontal-navigation .page-content {
    padding-top: 166px
}

.horizontal-navigation .main-nav {
    position: fixed;
    z-index: 10;
    width: 100%;
    top: 80px;
    left: 0;
    height: 56px;
    padding: 0 15px;
    background: #fff;
    border-bottom: solid 1px #c5d6de
}

.horizontal-navigation .main-nav .nav-link {
    position: relative;
    line-height: 40px;
    color: #999;
    font-weight: 600
}

.horizontal-navigation .main-nav .dropdown.open>.nav-link,
.horizontal-navigation .main-nav .nav-link.active,
.horizontal-navigation .main-nav .nav-link:hover {
    color: #343434
}

.horizontal-navigation .main-nav .dropdown.open:before,
.horizontal-navigation .main-nav .nav-link.active:before,
.horizontal-navigation .main-nav .nav-link:hover:before {
    content: '';
    height: 4px;
    background: #00a8ff;
    position: absolute;
    bottom: 1px;
    width: 100%;
    display: block;
    left: 0
}

@media (max-width:1056px) {
    .horizontal-navigation .page-content {
        padding-top: 112px
    }
    .horizontal-navigation .main-nav {
        left: -240px;
        height: 100%;
        width: 240px;
        padding: 0
    }
    .horizontal-navigation .main-nav .nav-item {
        display: block;
        margin-left: 0
    }
    .horizontal-navigation .main-nav .nav-link {
        display: block;
        padding-left: 20px;
        padding-right: 20px;
        line-height: 46px
    }
    .horizontal-navigation .main-nav .dropdown.open:before,
    .horizontal-navigation .main-nav .nav-link.active:before,
    .horizontal-navigation .main-nav .nav-link:hover:before {
        content: '';
        width: 4px;
        background: #00a8ff;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 1px;
        height: 100%;
        display: block
    }
    .horizontal-navigation .main-nav .dropdown.open .dropdown-menu {
        position: relative;
        width: 100%;
        -webkit-border-radius: 0;
        border-radius: 0
    }
    .horizontal-navigation .main-nav .dropdown.open .dropdown-divider {
        display: none
    }
    .horizontal-navigation.menu-left-opened .main-nav {
        -webkit-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out;
        z-index: 60;
        left: 0
    }
    .horizontal-navigation.menu-left-opened .mobile-menu-left-overlay {
        display: block
    }
}

.pie_progress {
    text-align: center;
    position: relative;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d
}

.pie_progress svg {
    margin: 0 auto
}

.pie_progress__content,
.pie_progress__icon,
.pie_progress__label,
.pie_progress__number {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%)
}

.pie_progress__number {
    font-size: 15px
}

.pie_progress__label {
    margin-top: 32px;
    font-size: 12px
}

.pie_progress__svg {
    display: inline-block;
    position: relative;
    width: 100%;
    padding-bottom: 100%;
    vertical-align: middle;
    overflow: hidden
}

.pie_progress__svg svg {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0
}

.statistic-box {
    -webkit-border-radius: 4px;
    border-radius: 4px;
    text-align: center;
    color: #fff;
    background: no-repeat 50% 50%;
    background-size: cover;
    margin: 0 0 30px
}

.statistic-box.red {
    background-color: #fa424a;
    background-image: url(../img/statistic-box-red.png)
}

.statistic-box.purple {
    background-color: #ac6bec;
    background-image: url(../img/statistic-box-purple.png)
}

.statistic-box.yellow {
    background-color: #fdad2a;
    background-image: url(../img/statistic-box-yellow.png)
}

.statistic-box.green {
    background-color: #46c35f;
    background-image: url(../img/statistic-box-green.png)
}

.statistic-box>div {
    -webkit-border-radius: inherit;
    border-radius: inherit;
    height: 142px;
    background: url(../img/statistic-box-grid.png) 50% 0;
    background-size: 21px 20px;
    position: relative
}

.statistic-box .number {
    font-size: 3.125rem;
    line-height: 1;
    padding: 32px 0 0
}
.statistic-box .number2 {
    font-size: 2.125rem;
    line-height: 1;
    padding: 32px 0 0
}
.statistic-box .number3 {
    font-size: 1.5rem;
    line-height: 1;
    padding: 32px 0 0;
}

.statistic-box .number4 {
    font-size: 1.5rem;
    line-height: 1;
    padding: 12px 0 0;
}

.statistic-box .caption {
    font-size: 1.0625rem;
    font-weight: 600;
    position: relative;
    min-height: 35px;
    line-height: 1
}

.statistic-box .caption>div {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 0 10px
}

.statistic-box .percent {
    position: absolute;
    right: 10px;
    bottom: 8px;
    text-align: center;
    font-size: .8125rem;
    font-weight: 600;
    line-height: 1
}

.statistic-box .arrow {
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 7.5px 8px 7.5px;
    border-color: transparent transparent #fff transparent;
    margin: 0 0 2px
}

.statistic-box .arrow.up {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg)
}

.statistic-box .arrow.down {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg)
}

.chart-statistic-box {
    zoom: 1;
    margin: 0 0 30px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    overflow: hidden
}

.chart-statistic-box:after,
.chart-statistic-box:before {
    content: " ";
    display: table
}

.chart-statistic-box:after {
    clear: both
}

.chart-statistic-box .chart-txt {
    float: left;
    width: 200px;
    height: 314px;
    padding: 15px 20px;
    background: #304b58;
    -webkit-border-radius: 4px 0 0 4px;
    border-radius: 4px 0 0 4px;
    color: #fff;
    position: relative;
    z-index: 5
}

.chart-statistic-box .chart-txt p {
    margin: 0
}

.chart-statistic-box .chart-txt .chart-txt-top {
    text-align: center;
    margin: 0 0 10px
}

.chart-statistic-box .chart-txt .chart-txt-top .number,
.chart-statistic-box .chart-txt .chart-txt-top .unit {
    vertical-align: middle
}

.chart-statistic-box .chart-txt .chart-txt-top .unit {
    font-weight: 300;
    font-size: 1.25rem;
    color: #929faa;
    margin: 0 .3em 0 0;
    position: relative;
    top: -.2em
}

.chart-statistic-box .chart-txt .chart-txt-top .number {
    font-size: 2.125rem
}

.chart-statistic-box .chart-txt .chart-txt-top .caption {
    font-weight: 600
}

.chart-statistic-box .chart-txt .color-purple {
    color: #b982ef!important
}

.chart-statistic-box .chart-txt .tbl-data {
    width: 100%;
    position: absolute;
    bottom: 15px
}

.chart-statistic-box .chart-txt .tbl-data td {
    vertical-align: top;
    padding: 5px 0
}

.chart-statistic-box .chart-txt .tbl-data .price {
    white-space: nowrap;
    width: 20px;
    padding-right: 10px;
    font-weight: 600
}

.chart-statistic-box .chart-container {
    float: right;
    width: 100%;
    position: relative;
    overflow: hidden;
    -webkit-border-radius: 0 4px 4px 0;
    border-radius: 0 4px 4px 0;
    background: #00a8ff;
    color: #fff;
    margin-left: -200px
}

.chart-statistic-box .chart-container .chart-container-in {
    margin-left: 200px;
    position: relative
}

.chart-statistic-box .chart-container .chart-container-title {
    position: absolute;
    top: auto;
    bottom: 20px;
    left: 0;
    width: 100%;
    text-align: center;
    font-weight: 600;
    font-size: 1.0625rem;
    z-index: 20;
    color: #fff
}

.chart-statistic-box .chart-container .chart-container-x,
.chart-statistic-box .chart-container .chart-container-y {
    font-size: .6875rem;
    text-transform: uppercase;
    font-weight: 700;
    position: absolute;
    z-index: 1
}

.chart-statistic-box .chart-container .chart-container-x {
    left: 0;
    top: 19px;
    width: 100%;
    text-align: center;
    zoom: 1
}

.chart-statistic-box .chart-container .chart-container-x:after,
.chart-statistic-box .chart-container .chart-container-x:before {
    content: " ";
    display: table
}

.chart-statistic-box .chart-container .chart-container-x:after {
    clear: both
}

.chart-statistic-box .chart-container .chart-container-x .item {
    float: left;
    width: 12.5%;
    min-height: 5px
}

.chart-statistic-box .chart-container .chart-container-x .item:first-child,
.chart-statistic-box .chart-container .chart-container-x .item:last-child {
    width: 6%
}

.chart-statistic-box .chart-container .chart-container-y {
    right: 22px;
    bottom: 0;
    text-align: right;
    line-height: 22px
}

.chart-statistic-box .chart-container .chart-container-y .item {
    height: 22.4px
}

.chart-statistic-box .google-visualization-tooltip {
    -webkit-box-shadow: none!important;
    box-shadow: none!important;
    background: 0 0!important;
    color: #fff!important;
    border: none!important;
    font-weight: 700!important;
    margin-left: -35px
}

@media (max-width:1367px) {
    .chart-statistic-box .chart-txt {
        width: 170px
    }
    .chart-statistic-box .chart-container {
        margin-left: -170px
    }
    .chart-statistic-box .chart-container .chart-container-in {
        margin-left: 170px
    }
}

@media (max-width:767px) {
    .chart-statistic-box .chart-txt {
        float: none;
        width: auto;
        height: auto;
        margin: 0 0 15px;
        -webkit-border-radius: 4px;
        border-radius: 4px
    }
    .chart-statistic-box .chart-txt .tbl-data {
        position: static
    }
    .chart-statistic-box .chart-container {
        float: none;
        width: auto;
        -webkit-border-radius: 4px;
        border-radius: 4px;
        margin: 0
    }
    .chart-statistic-box .chart-container .chart-container-in {
        margin: 0
    }
}

.box-typical {
    -webkit-border-radius: 4px;
    border-radius: 4px;
    background: #fff;
    border: solid 1px #d8e2e7;
    margin: 0 0 20px
}

.box-typical .box-typical-header {
    display: table;
    width: 100%
}

.box-typical .box-typical-header.box-typical-header-bordered {
    border-bottom: solid 1px #d8e2e7
}

.box-typical .box-typical-header .tbl-cell {
    vertical-align: middle;
    padding: 15px
}

.box-typical .box-typical-header .tbl-cell.tbl-cell-title {
    padding-right: 0
}

.box-typical .box-typical-header .tbl-cell.tbl-cell-title h1,
.box-typical .box-typical-header .tbl-cell.tbl-cell-title h2,
.box-typical .box-typical-header .tbl-cell.tbl-cell-title h3,
.box-typical .box-typical-header .tbl-cell.tbl-cell-title h4,
.box-typical .box-typical-header .tbl-cell.tbl-cell-title h5,
.box-typical .box-typical-header .tbl-cell.tbl-cell-title h6 {
    margin: 0;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.4;
    padding: 1px 0
}

.box-typical .box-typical-header .tbl-cell.tbl-cell-actions {
    text-align: right;
    white-space: nowrap;
    padding-top: 18px;
    padding-left: 0
}

.box-typical .box-typical-header .tbl-cell.tbl-cell-action-bordered {
    border-left: solid 1px #d8e2e7;
    text-align: center;
    width: 54px;
    padding-top: 17px
}

.box-typical .box-typical-header .tbl-cell.tbl-cell-action-bordered .action-btn {
    margin: 0
}

.box-typical .box-typical-header .action-btn {
    display: inline-block;
    vertical-align: top;
    color: #adb7be;
    border: none;
    background: 0 0;
    font-size: 1rem;
    margin: 0 0 0 10px;
    line-height: 18px
}

.box-typical .box-typical-header .action-btn .font-icon {
    vertical-align: middle
}

.box-typical .box-typical-header .action-btn .font-icon.font-icon-minus {
    position: relative;
    top: 1px
}

.box-typical .box-typical-header .action-btn:hover {
    color: #00a8ff
}

.box-typical .box-typical-header-sm {
    padding: 12px 15px;
    font-weight: 600;
    position: relative
}

.box-typical .box-typical-header-sm.bordered {
    border-bottom: solid 1px #d8e2e7
}

.box-typical .box-typical-header-sm .slider-arrs {
    position: absolute;
    right: 12px;
    top: 50%;
    margin-top: -12px;
    zoom: 1
}

.box-typical .box-typical-header-sm .slider-arrs:after,
.box-typical .box-typical-header-sm .slider-arrs:before {
    content: " ";
    display: table
}

.box-typical .box-typical-header-sm .slider-arrs:after {
    clear: both
}

.box-typical .box-typical-header-sm .slider-arrs button {
    float: left;
    font-size: 1rem;
    margin: 0 0 0 15px;
    border: none;
    background: 0 0;
    color: #c5d6de;
    height: 24px;
    line-height: 24px;
    padding: 0
}

.box-typical .box-typical-header-sm .slider-arrs button:hover {
    color: #00a8ff
}

.box-typical .box-typical-header-sm .slider-arrs button .font-icon {
    vertical-align: middle;
    line-height: inherit
}

.box-typical .box-typical-footer {
    background: #fbfcfd;
    padding: 12px 15px;
    border-top: solid 1px #d8e2e7
}

.box-typical .box-typical-footer:last-child {
    -webkit-border-radius: 0 0 4px 4px;
    border-radius: 0 0 4px 4px
}

.box-typical .box-typical-footer .tbl-cell {
    vertical-align: middle
}

.box-typical .box-typical-footer .tbl-cell-action {
    white-space: nowrap;
    width: 20px;
    padding-left: 15px
}

.box-typical .box-typical-footer .btn-icon {
    border: none;
    background: 0 0;
    color: #919fa9;
    margin: 0 12px 0 0;
    position: relative;
    top: 2px
}

.box-typical .box-typical-footer .btn-icon:hover {
    color: #00a8ff
}

.box-typical .box-typical-section {
    border-top: solid 1px #d8e2e7;
    padding: 15px
}

.box-typical .box-typical-section:first-child {
    border-top: none
}

.box-typical .box-typical-section .box-typical-header-sm {
    padding: 5px 0 15px
}

.box-typical.box-typical-padding {
    padding: 20px 15px
}

.box-typical.box-typical-max-280 .box-typical-header {
    border-bottom: solid 1px #d8e2e7;
    margin-bottom: -1px
}

.box-typical.box-typical-max-280 .box-typical-body {
    overflow: auto;
    height: 280px
}

.box-typical.box-typical-dashboard {
    margin: 0 0 30px;
    overflow: hidden
}

.box-typical.box-typical-dashboard .box-typical-header {
    border-bottom: solid 1px #d8e2e7
}

.box-typical.box-typical-dashboard .box-typical-body {
    overflow: auto;
    height: 100% !important
}
.box-typical.box-typical-dashboard .truck-height
{
 overflow: auto;
 height: 200px !important;
}

.box-typical.box-typical-dashboard .box-typical-body .tbl-typical th {
    border-top: none
}

.box-typical.box-typical-full-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    border: none;
    -webkit-border-radius: 0;
    border-radius: 0;

    margin: 0 0 20px
}

.box-typical.box-typical-full-screen .box-typical-header {
    position: relative;
    z-index: 110
}

.box-typical.box-typical-full-screen .box-typical-header .tbl-cell.tbl-cell-title h1,
.box-typical.box-typical-full-screen .box-typical-header .tbl-cell.tbl-cell-title h2,
.box-typical.box-typical-full-screen .box-typical-header .tbl-cell.tbl-cell-title h3,
.box-typical.box-typical-full-screen .box-typical-header .tbl-cell.tbl-cell-title h4,
.box-typical.box-typical-full-screen .box-typical-header .tbl-cell.tbl-cell-title h5,
.box-typical.box-typical-full-screen .box-typical-header .tbl-cell.tbl-cell-title h6 {
    font-size: 1.5rem
}

.box-typical.box-typical-full-screen .box-typical-header .action-btn .font-icon-expand:before {
    content: "\73"
}

.box-typical.box-typical-full-screen .tbl-typical td,
.box-typical.box-typical-full-screen .tbl-typical th {
    font-size: .9375rem
}

.box-typical.box-typical-collapsed {
    height: auto
}

.box-typical.box-typical-collapsed .box-typical-header {
    border-bottom-color: transparent
}

.box-typical.box-typical-collapsed .box-typical-body {
    display: none
}

.box-typical.box-typical-collapsed .box-typical-header .action-btn .font-icon-minus:before {
    content: "\e07a"
}

.box-typical .panel-heading {
    padding: 17px 20px
}

.box-typical .panel-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.7;
    padding: 0
}

.box-panel .box-typical-header {
    border-bottom: 1px solid #d8e2e7
}
.box-panel .truck-height
{
 overflow:auto;
 height: 280px !important;
}

.box-panel .box-typical-body {
    padding: 16px!important
}

.dashboard-sortable .box-typical-header {
    cursor: move
}

.comment-rows-container {
    border: solid 1px #d8e2e7;
    border-left: none;
    border-right: none;
    max-height: 570px;
    position: relative
}

.comment-rows-container:after {
    content: '';
    display: block;
    width: 100%;
    height: 5px;
    background: rgba(255, 255, 255, .9);
    position: absolute;
    left: 0;
    top: 0
}

.comment-row-item {
    position: relative;
    padding: 12px 15px 12px 61px;
    min-height: 32px
}

.comment-row-item p {
    margin: 0
}

.comment-row-item .avatar-preview {
    position: absolute;
    left: 15px;
    top: 12px
}

.comment-row-item .comment-row-item-header {
    line-height: 18px;
    margin: 0 0 6px
}

.comment-row-item .comment-row-item-header .tbl-cell {
    vertical-align: top
}

.comment-row-item .comment-row-item-header .tbl-cell-name {
    font-weight: 600
}

.comment-row-item .comment-row-item-header .tbl-cell-date {
    text-align: right;
    width: 30px;
    padding-left: 10px;
    white-space: nowrap;
    font-size: .875rem;
    color: #919fa9
}

.comment-row-item .comment-row-item-content {
    padding: 0 45px 6px 0;
    position: relative
}

.comment-row-item .comment-row-item-action {
    border: none;
    background: 0 0;
    color: #919fa9;
    position: absolute;
    top: 2px
}

.comment-row-item .comment-row-item-action:hover {
    color: #00a8ff
}

.comment-row-item .comment-row-item-action.edit {
    right: 25px
}

.comment-row-item .comment-row-item-action.del {
    right: 0
}

.comment-row-item .comment-row-item-action.del:hover {
    color: #fa424a
}

.comment-row-item .comment-row-item-reply {
    display: inline-block;
    vertical-align: top;
    margin: 0 20px 0 0;
    font-size: 1rem;
    text-decoration: none;
    color: #919fa9;
    border: none
}

.comment-row-item .comment-row-item-reply:hover {
    color: #00a8ff
}

.comment-row-item .comment-row-item-rating {
    display: inline-block;
    vertical-align: top;
    color: #919fa9;
    font-size: .875rem;
    position: relative;
    top: 2px
}

.comment-row-item .comment-row-item-rating .number {
    font-weight: 700;
    margin: 0 6px
}

.comment-row-item .comment-row-item-rating .font-icon,
.comment-row-item .comment-row-item-rating .glyphicon {
    cursor: pointer;
    position: relative;
    top: 2px
}

.comment-row-item .comment-row-item-rating .font-icon:hover,
.comment-row-item .comment-row-item-rating .glyphicon:hover {
    color: #00a8ff
}

.comment-row-item .comment-row-item-rating .font-icon.glyphicon-menu-down,
.comment-row-item .comment-row-item-rating .glyphicon.glyphicon-menu-down {
    top: 1px
}

.comment-row-item.selected {
    background-color: #ecf2f5
}

.hover-action .comment-row-item .comment-row-item-action {
    display: none
}

.hover-action .comment-row-item:hover {
    background-color: #ecf2f5
}

.hover-action .comment-row-item:hover .comment-row-item-action {
    display: block
}

.comment-row-item.quote {
    border-left: solid 4px #d8e2e7;
    padding-left: 44px;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
    min-height: 24px;
    margin: 12px -45px 0 0
}

.comment-row-item.quote .avatar-preview {
    width: 24px;
    height: 24px;
    left: 10px;
    top: 0
}

.comment-row-item.quote .comment-row-item-header {
    margin-bottom: 2px
}

.comment-row-item.quote .comment-row-item-content {
    padding-bottom: 0
}

.leave-comment-block {
    position: relative;
    padding: 15px 15px 15px 61px
}

.leave-comment-block .avatar-preview {
    position: absolute;
    left: 15px;
    top: 15px
}

.leave-comment-block .chat-area-bottom {
    padding: 0;
    border: none
}

.leave-comment-block .viewable-access {
    color: #919fa9;
    margin: 0 0 12px
}

.leave-comment-block .viewable-access .choose,
.leave-comment-block .viewable-access .lbl {
    display: inline-block;
    vertical-align: top
}

.leave-comment-block .viewable-access .choose {
    position: relative;
    top: 2px
}

.leave-comment-block .viewable-access .choose>button {
    border: none;
    background: 0 0;
    color: #919fa9;
    font-size: .875rem
}

.leave-comment-block .viewable-access .choose>button:after {
    position: relative;
    top: -3px;
    margin-left: 0
}

.leave-comment-block .viewable-access .choose .dropdown-menu {
    margin-top: 2px
}

* {
    outline: 0!important
}

.site-header-search {
    width: 100%;
    height: 30px;
    position: relative;
    padding: 0 35px 0 0;
    border: solid 1px #c5d6de;
    -webkit-border-radius: 25rem;
    border-radius: 25rem;
    background: #fff;
    overflow: hidden;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out
}

.site-header-search input[type=text] {
    width: 100%;
    padding: 4px 0 0 14px;
    border: none;
    background: 0 0;
    font-size: .875rem;
    font-weight: 600;
    color: #343434;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out
}

.site-header-search button {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 35px;
    height: 100%;
    line-height: 30px;
    text-align: center;
    color: #adb7be;
    border: none;
    background: 0 0;
    font-size: .9375rem
}

.site-header-search button:hover {
    color: #00a8ff
}

.site-header-search .overlay {
    display: none
}

.site-header-search.closed {
    width: 37px;
    border-color: transparent
}

.site-header-search.closed .overlay {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    cursor: pointer
}

.site-header-search.closed input[type=text] {
    opacity: 0
}

.site-header-search.closed:hover button {
    color: #00a8ff
}

.site-header {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    background: #fff;
    border-bottom: solid 1px #c5d6de;
    padding: 20px 15px 0 0;
    z-index: 80;
    -webkit-box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.4);
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.4);
}

.site-header .btn-nav {
    margin-left: 20px;
    margin-top: -5px;
    float: left
}

.site-header img.hidden-lg-down {
    display: none
}

.site-header .site-logo {
    display: block;
    float: left;
    height: 40px;
    zoom: 1;
    color: #343434;
    line-height: 40px
}

.site-header .site-logo:after,
.site-header .site-logo:before {
    content: " ";
    display: table
}

.site-header .site-logo:after {
    clear: both
}

.site-header .site-logo img {
    float: left;
    height: 50px;
    position: relative;
    top: -5px
}

@media (max-width:767px) {
    .site-header .site-logo img {
        height: auto;
        width: 35px;
        top: 6px
    }
}

.site-header .site-logo .site-logo-txt {
    float: left;
    font-size: 1.5rem;
    font-weight: 300
}

.site-header .site-logo .site-logo-txt strong {
    font-weight: 600
}

.site-header .site-header-content {
    float: right;
    height: 40px;
    padding: 5px 0;
    width: 100%;
    margin-left: -210px
}

.site-header .site-header-content .site-header-content-in {
    zoom: 1;
    margin-left: 210px
}

.site-header .site-header-content .site-header-content-in:after,
.site-header .site-header-content .site-header-content-in:before {
    content: " ";
    display: table
}

.site-header .site-header-content .site-header-content-in:after {
    clear: both
}

@media (max-width:767px) {
    .site-header .site-header-content {
        margin-left: -80px
    }
    .site-header .site-header-content .site-header-content-in {
        margin-left: 80px
    }
}

.site-header .site-header-shown {
    float: right;
    zoom: 1
}

.site-header .site-header-shown:after,
.site-header .site-header-shown:before {
    content: " ";
    display: table
}

.site-header .site-header-shown:after {
    clear: both
}

.site-header .header-alarm {
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    position: relative;
    display: inline-block;
    color: #adb7be;
    font-size: 1.125rem
}

.site-header .header-alarm:hover {
    color: #00a8ff
}

.site-header .header-alarm i {
    vertical-align: middle
}

.site-header .header-alarm.active:after {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    border: solid 1px #fff;
    background: #fa424a;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 6px;
    margin-left: 1px
}

.site-header .dropdown.dropdown-notification {
    float: left
}

.site-header .dropdown.dropdown-notification.open .header-alarm {
    color: #00a8ff
}

.site-header .dropdown.dropdown-notification.messages {

    margin-right: 11px;
    margin-left: 11px;

}

.site-header .dropdown.dropdown-notification.messages .header-alarm:after {
    margin-left: 4px
}

.site-header .dropdown.dropdown-notification .dropdown-menu-notif-more {
    border-top: solid 1px #d8e2e7;
    text-align: center;
    padding: 10px 15px;
    font-size: 1rem
}

.site-header .dropdown.dropdown-notification .dropdown-menu-notif-more a {
    text-decoration: none;
    color: #0082c6;
    border-bottom: solid 1px transparent
}

.site-header .dropdown.dropdown-notification .dropdown-menu-notif-more a:hover {
    border-bottom-color: rgba(0, 130, 198, .5)
}

.site-header .dropdown-menu-notif {
    width: 290px;
    padding: 0;
    line-height: normal;
    font-size: .9375rem
}

.site-header .dropdown-menu-notif a {
    text-decoration: none;
    color: #0082c6;
    border-bottom: solid 1px transparent
}

.site-header .dropdown-menu-notif a:hover {
    border-bottom-color: rgba(0, 130, 198, .5)
}

.site-header .dropdown-menu-notif .dropdown-menu-notif-header {
    padding: 12px 15px;
    border-bottom: solid 1px #d8e2e7;
    font-weight: 600
}

.site-header .dropdown-menu-notif .dropdown-menu-notif-header .label {
    font-size: .875rem;
    font-weight: 400;
    padding-left: .5em;
    padding-right: .5em
}

.site-header .dropdown-menu-notif .dropdown-menu-notif-list {
    height: 192px;
    overflow: auto
}

.site-header .dropdown-menu-notif .dropdown-menu-notif-item {
    padding: 8px 15px 8px 57px;
    position: relative
}

.site-header .dropdown-menu-notif .dropdown-menu-notif-item:nth-child(even) {
    background: #fbfcfd
}

.site-header .dropdown-menu-notif .dropdown-menu-notif-item .photo {
    width: 32px;
    height: 32px;
    position: absolute;
    left: 15px;
    top: 50%;
    margin-top: -16px
}

.site-header .dropdown-menu-notif .dropdown-menu-notif-item .photo img {
    -webkit-border-radius: 50%;
    border-radius: 50%;
    display: block;
    width: 100%
}

.site-header .dropdown-menu-notif .dropdown-menu-notif-item .dot {
    display: inline-block;
    vertical-align: middle;
    width: 6px;
    height: 6px;
    margin: 0 0 6px;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background: #fa424a;
    position: relative;
    top: 2px
}

.site-header .dropdown-menu-messages {
    width: 290px;
    padding-bottom: 0
}

.site-header .dropdown-menu-messages .dropdown-menu-messages-header {
    border-bottom: solid 1px #d8e2e7;
    padding: 7px 15px 12px;
    zoom: 1
}

.site-header .dropdown-menu-messages .dropdown-menu-messages-header:after,
.site-header .dropdown-menu-messages .dropdown-menu-messages-header:before {
    content: " ";
    display: table
}

.site-header .dropdown-menu-messages .dropdown-menu-messages-header:after {
    clear: both
}

.site-header .dropdown-menu-messages .dropdown-menu-messages-header .nav {
    float: left;
    zoom: 1
}

.site-header .dropdown-menu-messages .dropdown-menu-messages-header .nav:after,
.site-header .dropdown-menu-messages .dropdown-menu-messages-header .nav:before {
    content: " ";
    display: table
}

.site-header .dropdown-menu-messages .dropdown-menu-messages-header .nav:after {
    clear: both
}

.site-header .dropdown-menu-messages .dropdown-menu-messages-header .nav-item {
    float: left;
    margin: 0 15px 0 0
}

.site-header .dropdown-menu-messages .dropdown-menu-messages-header .nav-link {
    font-size: 1rem;
    padding: 0;
    font-weight: 600;
    color: #919fa9;
    -webkit-transition: none;
    transition: none
}

.site-header .dropdown-menu-messages .dropdown-menu-messages-header .nav-link.active {
    color: #343434
}

.site-header .dropdown-menu-messages .dropdown-menu-messages-header .nav-link .label {
    font-size: .875rem;
    padding-left: .5em;
    padding-right: .5em;
    font-weight: 400
}

.site-header .dropdown-menu-messages .dropdown-menu-messages-header .create {
    float: right;
    height: 18px;
    font-size: 1rem;
    border: none;
    background: 0 0;
    color: #919fa9;
    position: relative;
    top: -1px
}

.site-header .dropdown-menu-messages .dropdown-menu-messages-header .create:hover {
    color: #00a8ff
}

.site-header .dropdown-menu-messages .mess-item {
    display: block;
    color: #343434;
    padding: 14px 15px 14px 57px;
    position: relative;
    line-height: 18px
}

.site-header .dropdown-menu-messages .mess-item:nth-child(odd) {
    background-color: #fbfcfd
}

.site-header .dropdown-menu-messages .mess-item:hover {
    background-color: #ecf2f5
}

.site-header .dropdown-menu-messages .mess-item span {
    display: block
}

.site-header .dropdown-menu-messages .mess-item .avatar-preview {
    position: absolute;
    left: 15px;
    top: 15px
}

.site-header .dropdown-menu-messages .mess-item .mess-item-name,
.site-header .dropdown-menu-messages .mess-item .mess-item-txt {
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: .9375rem
}

.site-header .dropdown-menu-messages .mess-item .mess-item-name {
    font-weight: 600
}

.site-header .dropdown-menu-messages .mess-item .mess-item-txt {
    color: #919fa9
}

.site-header .dropdown-menu-messages .tab-pane {
    height: 192px;
    overflow: auto
}

.site-header .help-dropdown {
    float: left;
    position: relative;
    height: 30px;
    margin: 0 0 0 10px
}

.site-header .help-dropdown>button {

    height: 30px;
    line-height: 30px;
    border: none;
    background: 0 0;
    color: #adb7be
}

.site-header .help-dropdown.opened>button,
.site-header .help-dropdown>button:hover {
    color: #00a8ff
}

.site-header .help-dropdown .help-dropdown-popup {
    display: none;
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -400px;
    margin-top: 7px;
    background: #fff;
    border: solid 1px #d8e2e7;
    -webkit-border-radius: .25rem;
    border-radius: .25rem;
    zoom: 1;
    width: 800px
}

.site-header .help-dropdown .help-dropdown-popup:after,
.site-header .help-dropdown .help-dropdown-popup:before {
    content: " ";
    display: table
}

.site-header .help-dropdown .help-dropdown-popup:after {
    clear: both
}

.site-header .help-dropdown .help-dropdown-popup:after,
.site-header .help-dropdown .help-dropdown-popup:before {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    width: 0;
    height: 0;
    border-style: solid
}

.site-header .help-dropdown .help-dropdown-popup:before {
    top: -10px;
    margin-left: -8px;
    border-width: 0 8px 10px 8px;
    border-color: transparent transparent #d8e2e7 transparent
}

.site-header .help-dropdown .help-dropdown-popup:after {
    top: -9px;
    margin-left: -7px;
    border-width: 0 7px 9px 7px;
    border-color: transparent transparent #fff transparent
}

.site-header .help-dropdown .help-dropdown-popup-side {
    display: table-cell;
    vertical-align: top;
    width: 230px;
    background: #f6f8fa;
    -webkit-border-radius: .25rem 0 0 .25rem;
    border-radius: .25rem 0 0 .25rem;
    padding: 30px;
    border-right: solid 1px #d8e2e7;
    position: relative;
    z-index: 2;
    font-weight: 600
}

.site-header .help-dropdown .help-dropdown-popup-side a {
    color: #343434
}

.site-header .help-dropdown .help-dropdown-popup-side a:hover {
    color: #00a8ff
}

.site-header .help-dropdown .help-dropdown-popup-side a.active {
    color: #00a8ff
}

.site-header .help-dropdown .help-dropdown-popup-side li {
    margin: 0 0 .5rem
}

.site-header .help-dropdown .help-dropdown-popup-cont {
    display: table-cell;
    vertical-align: top
}

.site-header .help-dropdown .help-dropdown-popup-cont-in {
    padding: 30px
}

.site-header .help-dropdown .help-dropdown-popup-item {
    border-top: solid 1px #d8e2e7;
    padding: 10px 0;
    display: block;
    color: #343434
}

.site-header .help-dropdown .help-dropdown-popup-item:hover {
    color: #00a8ff
}

.site-header .help-dropdown .help-dropdown-popup-item:first-child {
    border-top: none;
    padding-top: 0
}

.site-header .help-dropdown .help-dropdown-popup-item .describe {
    display: block;
    color: #919fa9;
    font-size: .875rem
}

.site-header .help-dropdown .jscroll {
    max-height: 265px;
    overflow: auto
}

.site-header .help-dropdown.opened .help-dropdown-popup {
    display: table;
    width: 800px
}

.site-header .user-menu.dropdown {
    float: left;

    height: 30px;
    line-height: 30px
}

.site-header .user-menu.dropdown .dropdown-item {
    padding-top: 0;
    padding-bottom: 0
}

.site-header .user-menu.dropdown .dropdown-toggle {
    display: inline-block;
    color: #adb7be;
    height: 30px;
    border: none;
    background: 0 0;
    line-height: 30px;
    margin-left:5px
}

.site-header .user-menu.dropdown .dropdown-toggle:after {
    border-top: 5px solid;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent
}

.site-header .user-menu.dropdown .dropdown-toggle img {
    float: left;
    display: block;
    height: 32px;
    width: 32px;
    margin: -1px 3px -1px 0;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    border: solid 1px transparent;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out
}

.site-header .user-menu.dropdown .dropdown-toggle:hover,
.site-header .user-menu.dropdown.open .dropdown-toggle {
    color: #00a8ff
}

.site-header .dropdown.dropdown-lang {
    float: left;
    margin-right: 11px;
}

.site-header .dropdown.dropdown-lang .flag-icon {
    -webkit-box-shadow: 0 0 1px #c5d6de, 0 0 1px #c5d6de, 0 0 1px #c5d6de;
    box-shadow: 0 0 1px #c5d6de, 0 0 1px #c5d6de, 0 0 1px #c5d6de
}

.site-header .dropdown.dropdown-lang .dropdown-toggle {
    height: 30px;
    width: 45px;
    text-align: center;
    padding: 0 5px 0 0;
    background: 0 0;
    border: none;
    color: #adb7be
}

.site-header .dropdown.dropdown-lang .dropdown-toggle:after {
    display: none
}

.site-header .dropdown.dropdown-lang .dropdown-toggle:hover {
    color: #00a8ff
}

.site-header .dropdown.dropdown-lang .dropdown-menu {
    width: 250px
}

.site-header .dropdown.dropdown-lang .dropdown-menu .flag-icon {
    margin: 0 5px 0 0;
    position: relative;
    top: -1px
}

.site-header .dropdown.dropdown-lang.open .dropdown-toggle {
    color: #00a8ff
}

.site-header .burger-right {
    display: none;
    float: right;
    height: 30px;
    margin: 0 0 0 10px;
    line-height: 30px;
    cursor: pointer;
    color: #adb7be;
    border: none;
    background: 0 0;
    font-size: 1.375rem;
    position: relative;
    top: 1px
}

.site-header .burger-right:hover {
    color: #00a8ff
}

.site-header .burger-right i {
    vertical-align: middle
}

.site-header .dropdown {
    float: right
}

.site-header .dropdown.dropdown-typical {
    float: left;
    margin-right: -10px
}

.site-header .dropdown.dropdown-typical .lbl {
    font-size: 15px;
    line-height: 16px
}

.site-header .dropdown a.dropdown-toggle {
    height: 30px;
    line-height: 30px
}

.site-header .dropdown a.dropdown-toggle.no-arr:after {
    display: none
}

.site-header .dropdown a.dropdown-toggle .label {
    padding: 3px 6px 2px;
    margin-left: 3px;
    font-size: .8125rem;
    position: relative;
    top: -2px
}

.site-header .dropdown .dropdown-item {
    font-size: 15px
}

.site-header .dropdown .btn.dropdown-toggle {
    height: 30px;
    padding: 0 12px;
    font-size: .8125rem;
    line-height: 28px;
    background-color: #00a8ff;
    border-color: #00a8ff;
    color: #fff
}

.site-header .dropdown .btn.dropdown-toggle:after {
    border-top: 5px solid;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent
}

.site-header .dropdown.open .btn.dropdown-toggle {
    background: #fff;
    color: #00a8ff
}

.site-header .site-header-search-container {
    float: right;
    width: 158px;
    margin: 0 10px 0 0;
    zoom: 1
}

.site-header .site-header-search-container:after,
.site-header .site-header-search-container:before {
    content: " ";
    display: table
}

.site-header .site-header-search-container:after {
    clear: both
}

.site-header .site-header-search-container .site-header-search {
    float: right
}

.site-header .site-header-collapsed {
    float: left;
    width: 100%;
    margin-right: -199px
}

.site-header .site-header-collapsed .site-header-collapsed-in {

    zoom: 1
}

.site-header .site-header-collapsed .site-header-collapsed-in:after,
.site-header .site-header-collapsed .site-header-collapsed-in:before {
    content: " ";
    display: table
}

.site-header .site-header-collapsed .site-header-collapsed-in:after {
    clear: both
}

.site-header .mobile-menu-right-overlay {
    display: none
}

.site-header .hamburger {
    float: left;
    position: relative;
    top: 5px;
    margin: 0 0 0 12px
}

@media (max-width:1530px) and (min-width:1055px) {
    .site-header .dropdown.dropdown-typical a.dropdown-toggle .font-icon {
        margin-right: 0
    }
    .site-header .dropdown.dropdown-typical a.dropdown-toggle .lbl {
        display: none
    }
}

@media (max-width:1199px) {
    .site-header .site-header-search-container {
        width: 110px
    }
}

@media (max-width:1056px) {
    .site-header {
        padding-right: 0
    }
    .site-header .site-logo-text {
        display: none
    }
    .site-header .help-dropdown {
        display: none
    }
    /* .site-header .burger-right {
        display: block
    } */
    .site-header img.hidden-lg-down {
        display: inline-block
    }
    .site-header img.hidden-md-down {
        display: none
    }
    .site-header .site-header-collapsed {
        position: fixed;
        right: -270px;
        top: 0;
        z-index: 90;
        height: 100%;
        width: 270px;
        background: #fff;
        border-left: solid 1px #c5d6de;
        padding: 15px;
        overflow: auto;
        -webkit-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out;
        float: none;
        margin: 0
    }
    .site-header .site-header-collapsed .site-header-collapsed-in {
        margin: 0
    }
    .site-header .site-header-collapsed .site-header-search.closed {
        width: 100%;
        border-color: #c5d6de
    }
    .site-header .site-header-collapsed .site-header-search.closed .overlay {
        display: none
    }
    .site-header .site-header-collapsed .site-header-search.closed input[type=text] {
        opacity: 1
    }
    .site-header .site-header-collapsed .site-header-search.closed:hover button {
        color: #c5d6de
    }
    .site-header .site-header-collapsed .site-header-search-container {
        width: 100%;
        padding: 10px 0;
        float: none
    }
    .site-header .site-header-collapsed .btn-nav {
        margin-left: 0;
        margin-top: 5px;
        width: 100%
    }
    .site-header .site-header-collapsed .dropdown {
        float: none;
        margin: 0;
        zoom: 1
    }
    .site-header .site-header-collapsed .dropdown:after,
    .site-header .site-header-collapsed .dropdown:before {
        content: " ";
        display: table
    }
    .site-header .site-header-collapsed .dropdown:after {
        clear: both
    }
    .site-header .site-header-collapsed .dropdown .btn.dropdown-toggle {
        width: 100%;
        margin-bottom: 5px;
        margin-top: 8px
    }
    .site-header .site-header-collapsed .dropdown .dropdown-menu {
        display: none;
        position: static;
        width: 100%;
        margin-top: 0
    }
    .site-header .site-header-collapsed .dropdown.open a.dropdown-toggle {
        color: #343434
    }
    .site-header .site-header-collapsed .dropdown.open a.dropdown-toggle:after {
        top: 2px
    }
    .site-header .site-header-collapsed .dropdown.open a.dropdown-toggle .font-icon,
    .site-header .site-header-collapsed .dropdown.open a.dropdown-toggle:after {
        color: #adb7be
    }
    .site-header .site-header-collapsed .dropdown.open .btn.dropdown-toggle {
        color: #fff;
        background-color: #00a8ff
    }
    .site-header .site-header-collapsed .dropdown.mobile-opened .dropdown-menu {
        display: block
    }
    .site-header .site-header-collapsed .dropdown.mobile-opened .dropdown-toggle:after {
        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg)
    }
    .site-header .site-header-collapsed .dropdown.mobile-opened a.dropdown-toggle {
        color: #00a8ff
    }
    .site-header .site-header-collapsed .dropdown.mobile-opened a.dropdown-toggle .font-icon,
    .site-header .site-header-collapsed .dropdown.mobile-opened a.dropdown-toggle:after {
        color: #00a8ff
    }
    .site-header .site-header-collapsed .dropdown.mobile-opened .btn.dropdown-toggle {
        color: #00a8ff;
        background-color: #fff
    }
    .site-header .site-header-collapsed .dropdown.dropdown-typical .dropdown-more .dropdown-more-caption:before {
        -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg)
    }
    .site-header .site-header-collapsed .dropdown.dropdown-typical .dropdown-more .dropdown-more-sub {
        display: none;
        position: static;
        left: 0;
        padding: 0;
        margin: 0
    }
    .site-header .site-header-collapsed .dropdown.dropdown-typical .dropdown-more .dropdown-more-sub-in {
        border-right: none;
        -webkit-border-radius: 0;
        border-radius: 0
    }
    .site-header .site-header-collapsed .dropdown.dropdown-typical .dropdown-more:hover .dropdown-more-sub {
        display: none
    }
    .site-header .site-header-collapsed .dropdown.dropdown-typical .dropdown-more.opened .dropdown-more-caption {
        color: #00a8ff
    }
    .site-header .site-header-collapsed .dropdown.dropdown-typical .dropdown-more.opened .dropdown-more-caption:before {
        -webkit-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        transform: rotate(-90deg);
        border-left-color: #00a8ff
    }
    .site-header .site-header-collapsed .dropdown.dropdown-typical .dropdown-more.opened .dropdown-more-sub {
        display: block
    }
    .menu-right-opened .site-header .mobile-menu-right-overlay {
        display: block;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 85;
        background: rgba(48, 75, 88, .5)
    }
    .menu-right-opened .site-header .site-header-collapsed {
        right: 0
    }
}

@media (max-width:767px) {
    .site-header .burger-right {
        margin-left: 7px
    }
    .site-header .dropdown.dropdown-notification.messages {
        margin-left: 8px
    }
    .site-header .dropdown.dropdown-lang .dropdown-toggle {
        width: 42px
    }
    .site-header .dropdown.dropdown-lang .dropdown-menu {
        margin-right: -52px
    }
    .site-header .user-menu.dropdown {
        margin-left: 12px
    }
    .site-header .dropdown-menu-notif {
        margin-right: -172px
    }
    .site-header .dropdown-menu-messages {
        margin-right: -134px
    }
}

.hamburger {
    display: none;
    position: relative;
    overflow: hidden;
    margin: 0;
    padding: 0;
    width: 30px;
    height: 30px;
    font-size: 0;
    text-indent: -9999px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-border-radius: 0;
    border-radius: 0;
    border: none;
    cursor: pointer;
    -webkit-transition: background .3s;
    transition: background .3s;
    background: 0 0
}

.hamburger span {
    display: block;
    margin: 0 4px;
    position: absolute;
    top: 50%;
    margin-top: -1px;
    left: 0;
    right: 0;
    height: 3px;
    background: #adb7be;
    -webkit-transition: -webkit-transform .3s;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s
}

.hamburger span:after,
.hamburger span:before {
    position: absolute;
    display: block;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #adb7be;
    content: ""
}

.hamburger span:before {
    top: -7px;
    -webkit-transform-origin: top right;
    -ms-transform-origin: top right;
    transform-origin: top right;
    -webkit-transition: width .3s, top .3s, -webkit-transform .3s;
    transition: width .3s, top .3s, -webkit-transform .3s;
    transition: transform .3s, width .3s, top .3s;
    transition: transform .3s, width .3s, top .3s, -webkit-transform .3s
}

.hamburger span:after {
    bottom: -7px;
    -webkit-transform-origin: bottom right;
    -ms-transform-origin: bottom right;
    transform-origin: bottom right;
    -webkit-transition: width .3s, bottom .3s, -webkit-transform .3s;
    transition: width .3s, bottom .3s, -webkit-transform .3s;
    transition: transform .3s, width .3s, bottom .3s;
    transition: transform .3s, width .3s, bottom .3s, -webkit-transform .3s
}

.hamburger.is-active {
    background: 0 0
}

.hamburger.is-active span {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg)
}

.hamburger.is-active span:after,
.hamburger.is-active span:before {
    width: 50%
}

.hamburger.is-active span:before {
    top: 0;
    -webkit-transform: translateX(12px) translateY(1px) rotate(45deg);
    -ms-transform: translateX(12px) translateY(1px) rotate(45deg);
    transform: translateX(12px) translateY(1px) rotate(45deg)
}

.hamburger.is-active span:after {
    bottom: 0;
    -webkit-transform: translateX(12px) translateY(-1px) rotate(-45deg);
    -ms-transform: translateX(12px) translateY(-1px) rotate(-45deg);
    transform: translateX(12px) translateY(-1px) rotate(-45deg)
}

.hamburger:hover span,
.hamburger:hover span:after,
.hamburger:hover span:before {
    background-color: #00a8ff
}

@media (max-width:1056px) {
    .hamburger {
        display: block
    }
}

.wet-aspalt-theme .site-header {
    background-color: #304b58;
    border-bottom-color: #304b58
}

.wet-aspalt-theme .site-header .dropdown.dropdown-lang .flag-icon {
    opacity: .9;
    -webkit-box-shadow: none;
    box-shadow: none
}

.wet-aspalt-theme .site-header .dropdown.dropdown-lang .dropdown-toggle {
    color: #fff
}

.wet-aspalt-theme .site-header .dropdown.dropdown-lang .dropdown-toggle:hover,
.wet-aspalt-theme .site-header .dropdown.dropdown-lang.open .dropdown-toggle {
    color: #c5d6de
}

.wet-aspalt-theme .site-header .header-alarm {
    color: #fff
}

.wet-aspalt-theme .site-header .header-alarm.active:after {
    border-color: #304b58
}

.wet-aspalt-theme .site-header .dropdown.dropdown-notification.open .header-alarm,
.wet-aspalt-theme .site-header .header-alarm:hover {
    color: #c5d6de
}

.wet-aspalt-theme .site-header .user-menu.dropdown .dropdown-toggle {
    color: #fff
}

.wet-aspalt-theme .site-header .user-menu.dropdown .dropdown-toggle:hover,
.wet-aspalt-theme .site-header .user-menu.dropdown.open .dropdown-toggle {
    color: #c5d6de
}

.wet-aspalt-theme .site-header .hamburger span,
.wet-aspalt-theme .site-header .hamburger span:after,
.wet-aspalt-theme .site-header .hamburger span:before {
    background-color: #fff
}

.wet-aspalt-theme .site-header .burger-right {
    color: #fff
}

@media (min-width:1055px) {
    .wet-aspalt-theme .site-header .dropdown.dropdown-typical a.dropdown-toggle,
    .wet-aspalt-theme .site-header .dropdown.dropdown-typical a.dropdown-toggle .font-icon,
    .wet-aspalt-theme .site-header .dropdown.dropdown-typical a.dropdown-toggle:after {
        color: #fff
    }
    .wet-aspalt-theme .site-header .dropdown.dropdown-typical a.dropdown-toggle:hover,
    .wet-aspalt-theme .site-header .dropdown.dropdown-typical.open a.dropdown-toggle {
        color: #c5d6de
    }
    .wet-aspalt-theme .site-header .dropdown.dropdown-typical a.dropdown-toggle:hover .font-icon,
    .wet-aspalt-theme .site-header .dropdown.dropdown-typical a.dropdown-toggle:hover:after,
    .wet-aspalt-theme .site-header .dropdown.dropdown-typical.open a.dropdown-toggle .font-icon,
    .wet-aspalt-theme .site-header .dropdown.dropdown-typical.open a.dropdown-toggle:after {
        color: #c5d6de
    }
    .wet-aspalt-theme .site-header .dropdown .btn.dropdown-toggle {
        border-color: #fff;
        background-color: #fff;
        color: #304b58
    }
    .wet-aspalt-theme .site-header .help-dropdown button {
        color: #fff
    }
    .wet-aspalt-theme .site-header .help-dropdown button:hover,
    .wet-aspalt-theme .site-header .help-dropdown.opened button {
        color: #c5d6de
    }
    .wet-aspalt-theme .site-header .site-header-search {
        background: 0 0;
        border-color: #c5d6de
    }
    .wet-aspalt-theme .site-header .site-header-search.closed {
        border-color: transparent
    }
    .wet-aspalt-theme .site-header .site-header-search [placeholder],
    .wet-aspalt-theme .site-header .site-header-search input[placeholder] {
        color: #fff
    }
    .wet-aspalt-theme .site-header .site-header-search::-webkit-input-placeholder {
        color: #fff!important
    }
    .wet-aspalt-theme .site-header .site-header-search::-moz-placeholder {
        color: #fff!important;
        opacity: 1!important
    }
    .wet-aspalt-theme .site-header .site-header-search:-moz-placeholder {
        color: #fff!important;
        opacity: 1!important
    }
    .wet-aspalt-theme .site-header .site-header-search::-moz-placeholder {
        color: #fff!important
    }
    .wet-aspalt-theme .site-header .site-header-search:-ms-input-placeholder {
        color: #fff!important
    }
    .wet-aspalt-theme .site-header .site-header-search button {
        color: #fff
    }
    .wet-aspalt-theme .site-header .site-header-search button:hover,
    .wet-aspalt-theme .site-header .site-header-search.closed:hover button {
        color: #c5d6de
    }
}

.site-header .site-logo-text {
    float: left;
    width: 240px;
    height: 50px;
    line-height: 50px;
    color: #fff;
    font-size: 1.25rem;
    font-weight: 600;
    background: #212b30;
    margin: 0 -1px 0 -.9375rem;
    position: relative;
    padding: 0;
    text-align: center
}

.with-side-menu-compact .site-header .site-logo-text {
    width: 100px
}

.with-side-menu-compact.dark-theme .site-header .site-header-content {
    margin-left: -100px
}

.with-side-menu-compact.dark-theme .site-header .site-header-content .site-header-content-in {
    margin-left: 100px
}

@media (max-width:767px) {
    .with-side-menu-compact.dark-theme .site-header .site-header-content {
        margin-left: -110px
    }
    .with-side-menu-compact.dark-theme .site-header .site-header-content .site-header-content-in {
        margin-left: 110px
    }
}

.dark-theme .site-header {
    background-color: #fff;
    height: 50px;
    padding-top: 0;
    border-bottom: none
}

.dark-theme .site-header .site-header-content {
    padding: 0;
    margin-left: -240px
}

.dark-theme .site-header .site-header-content .site-header-content-in {
    margin-left: 240px
}

@media (max-width:767px) {
    .dark-theme .site-header .site-header-content {
        margin-left: -130px
    }
    .dark-theme .site-header .site-header-content .site-header-content-in {
        margin-left: 130px
    }
}

.dark-theme .site-header .site-header-shown {
    padding-top: 10px
}

.dark-theme .site-header .header-alarm.active:after {
    border-color: #343434
}

.dark-theme .site-header .hamburger {
    top: 8px
}

.dark-theme .site-header.site-header-light {
    background: #fff
}

.dark-theme .site-header.site-header-light .header-alarm.active:after {
    border-color: #fff
}

@media (min-width:1055px) {
    .dark-theme .site-header .site-header-collapsed .site-header-collapsed-in {
        margin-right: 112px
    }
    .dark-theme .site-header .dropdown .btn.dropdown-toggle {
        position: relative;
        top: 10px;
        border-color: #919fa9;
        background-color: #919fa9;
        color: #fff
    }
    .dark-theme .site-header .dropdown .dropdown-menu {
        margin-top: 14px
    }
    .dark-theme .site-header .dropdown.dropdown-typical {
        margin: 0 1px 0 0
    }
    .dark-theme .site-header .dropdown.dropdown-typical a.dropdown-toggle {
        color: #fff;
        height: 50px;
        line-height: 50px;
        display: block
    }
    .dark-theme .site-header .dropdown.dropdown-typical a.dropdown-toggle .font-icon,
    .dark-theme .site-header .dropdown.dropdown-typical a.dropdown-toggle:after {
        color: #919fa9
    }
    .dark-theme .site-header .dropdown.dropdown-typical a.dropdown-toggle:hover,
    .dark-theme .site-header .dropdown.dropdown-typical.open a.dropdown-toggle {
        background-color: #919fa9
    }
    .dark-theme .site-header .dropdown.dropdown-typical a.dropdown-toggle:hover .font-icon,
    .dark-theme .site-header .dropdown.dropdown-typical a.dropdown-toggle:hover:after,
    .dark-theme .site-header .dropdown.dropdown-typical.open a.dropdown-toggle .font-icon,
    .dark-theme .site-header .dropdown.dropdown-typical.open a.dropdown-toggle:after {
        color: #fff
    }
    .dark-theme .site-header .dropdown.dropdown-typical .dropdown-menu {
        margin-top: 8px
    }
    .dark-theme .site-header .dropdown.dropdown-typical .dropdown-menu .font-icon {
        color: #919fa9
    }
    .dark-theme .site-header .dropdown.dropdown-typical .dropdown-menu .dropdown-item:hover .font-icon {
        color: #00a8ff
    }
    .dark-theme .site-header .site-header-search-container {
        padding-top: 10px
    }
    .dark-theme .site-header .site-header-search {
        background: #fff;
        border-color: #919fa9
    }
    .dark-theme .site-header .site-header-search.closed {
        border-color: transparent;
        background: 0 0
    }
    .dark-theme .site-header.site-header-light .dropdown.dropdown-typical a.dropdown-toggle {
        color: #919fa9
    }
    .dark-theme .site-header.site-header-light .dropdown.dropdown-typical a.dropdown-toggle:hover,
    .dark-theme .site-header.site-header-light .dropdown.dropdown-typical.open a.dropdown-toggle {
        color: #fff
    }
}

@media (min-width:1055px) {
    .dark-theme.dark-theme-blue .site-header .dropdown.dropdown-typical a.dropdown-toggle:hover,
    .dark-theme.dark-theme-blue .site-header .dropdown.dropdown-typical.open a.dropdown-toggle {
        background-color: #00a8ff
    }
    .dark-theme.dark-theme-blue .site-header .dropdown.dropdown-typical.open a.dropdown-toggle:before {
        border-top-color: #00a8ff
    }
    .dark-theme.dark-theme-blue .site-header .dropdown .dropdown-menu .dropdown-item:hover,
    .dark-theme.dark-theme-blue .site-header .dropdown .dropdown-menu .dropdown-item:hover .font-icon,
    .dark-theme.dark-theme-blue .site-header .dropdown .dropdown-menu .dropdown-more:hover .dropdown-more-caption {
        color: #00a8ff
    }
    .dark-theme.dark-theme-blue .site-header .dropdown .dropdown-menu .dropdown-more:hover .dropdown-more-caption:before {
        border-left-color: #00a8ff
    }
}

@media (min-width:1055px) {
    .dark-theme.dark-theme-green .site-header .dropdown.dropdown-typical a.dropdown-toggle:hover,
    .dark-theme.dark-theme-green .site-header .dropdown.dropdown-typical.open a.dropdown-toggle {
        background-color: #46c35f
    }
    .dark-theme.dark-theme-green .site-header .dropdown.dropdown-typical.open a.dropdown-toggle:before {
        border-top-color: #46c35f
    }
    .dark-theme.dark-theme-green .site-header .dropdown .dropdown-menu .dropdown-item:hover,
    .dark-theme.dark-theme-green .site-header .dropdown .dropdown-menu .dropdown-item:hover .font-icon,
    .dark-theme.dark-theme-green .site-header .dropdown .dropdown-menu .dropdown-more:hover .dropdown-more-caption {
        color: #46c35f
    }
    .dark-theme.dark-theme-green .site-header .dropdown .dropdown-menu .dropdown-more:hover .dropdown-more-caption:before {
        border-left-color: #46c35f
    }
    .dark-theme.dark-theme-green .site-header .dropdown.dropdown-notification.open .header-alarm,
    .dark-theme.dark-theme-green .site-header .header-alarm:hover,
    .dark-theme.dark-theme-green .site-header .site-header-search button:hover,
    .dark-theme.dark-theme-green .site-header .site-header-search.closed:hover button {
        color: #46c35f
    }
    .dark-theme.dark-theme-green .site-header .user-menu.dropdown .dropdown-toggle:hover,
    .dark-theme.dark-theme-green .site-header .user-menu.dropdown.open .dropdown-toggle {
        color: #46c35f
    }
}

@media (min-width:1055px) {
    .dark-theme.dark-theme-ultramarine .dropdown.dropdown-typical a.dropdown-toggle:hover,
    .dark-theme.dark-theme-ultramarine .dropdown.dropdown-typical.open a.dropdown-toggle {
        background-color: #1a5bc3
    }
    .dark-theme.dark-theme-ultramarine .dropdown.dropdown-typical.open a.dropdown-toggle:before {
        border-top-color: #1a5bc3
    }
    .dark-theme.dark-theme-ultramarine .dropdown .dropdown-menu .dropdown-item:hover,
    .dark-theme.dark-theme-ultramarine .dropdown .dropdown-menu .dropdown-item:hover .font-icon,
    .dark-theme.dark-theme-ultramarine .dropdown .dropdown-menu .dropdown-more:hover .dropdown-more-caption {
        color: #1a5bc3
    }
    .dark-theme.dark-theme-ultramarine .dropdown .dropdown-menu .dropdown-more:hover .dropdown-more-caption:before {
        border-left-color: #1a5bc3
    }
    .dark-theme.dark-theme-ultramarine .dropdown.dropdown-notification.open .header-alarm,
    .dark-theme.dark-theme-ultramarine .header-alarm:hover,
    .dark-theme.dark-theme-ultramarine .site-header-search button:hover,
    .dark-theme.dark-theme-ultramarine .site-header-search.closed:hover button {
        color: #1a5bc3
    }
    .dark-theme.dark-theme-ultramarine .user-menu.dropdown .dropdown-toggle:hover,
    .dark-theme.dark-theme-ultramarine .user-menu.dropdown.open .dropdown-toggle {
        color: #1a5bc3
    }
}

@media (min-width:1055px) {
    .theme-picton-blue .site-header .site-logo,
    .theme-picton-blue-white-ebony .site-header .site-logo,
    .theme-rebecca-purple .site-header .site-logo,
    .theme-side-caesium-dark-caribbean .site-header .site-logo,
    .theme-side-ebony-clay .site-header .site-logo,
    .theme-side-litmus-blue .site-header .site-logo,
    .theme-side-madison-caribbean .site-header .site-logo,
    .theme-side-tin .site-header .site-logo {
        height: 80px;
        width: 240px;
        padding: 20px 0 0;
        text-align: center;
        margin: -20px 0 0 -15px
    }
    .theme-picton-blue .site-header .site-logo img,
    .theme-picton-blue-white-ebony .site-header .site-logo img,
    .theme-rebecca-purple .site-header .site-logo img,
    .theme-side-caesium-dark-caribbean .site-header .site-logo img,
    .theme-side-ebony-clay .site-header .site-logo img,
    .theme-side-litmus-blue .site-header .site-logo img,
    .theme-side-madison-caribbean .site-header .site-logo img,
    .theme-side-tin .site-header .site-logo img {
        float: none
    }
    .theme-picton-blue .site-header .site-header-content,
    .theme-picton-blue-white-ebony .site-header .site-header-content,
    .theme-rebecca-purple .site-header .site-header-content,
    .theme-side-caesium-dark-caribbean .site-header .site-header-content,
    .theme-side-ebony-clay .site-header .site-header-content,
    .theme-side-litmus-blue .site-header .site-header-content,
    .theme-side-madison-caribbean .site-header .site-header-content,
    .theme-side-tin .site-header .site-header-content {
        margin-left: -240px
    }
    .theme-picton-blue .site-header .site-header-content-in,
    .theme-picton-blue-white-ebony .site-header .site-header-content-in,
    .theme-rebecca-purple .site-header .site-header-content-in,
    .theme-side-caesium-dark-caribbean .site-header .site-header-content-in,
    .theme-side-ebony-clay .site-header .site-header-content-in,
    .theme-side-litmus-blue .site-header .site-header-content-in,
    .theme-side-madison-caribbean .site-header .site-header-content-in,
    .theme-side-tin .site-header .site-header-content-in {
        margin-left: 240px
    }
    .theme-side-ebony-clay .site-header .site-logo {
        background-color: #212b30
    }
    .theme-side-madison-caribbean .site-header .site-logo {
        background-color: #263543
    }
    .theme-side-caesium-dark-caribbean .site-header .site-logo {
        background-color: #22222f
    }
    .theme-side-tin .site-header .site-logo {
        background-color: #383838
    }
    .theme-side-litmus-blue .site-header .site-logo {
        background-color: #282c38
    }
}

.theme-rebecca-purple .site-header {
    background-color: #3e4eb2;
    border-bottom-color: #3e4eb2
}

.theme-rebecca-purple .site-header .site-header-search.closed {
    background: 0 0
}

.theme-rebecca-purple .site-header .site-header-search.closed button {
    color: #fff
}

.theme-rebecca-purple .site-header .site-header-search.closed:hover button {
    color: #00a8ff
}

.theme-rebecca-purple .site-header .header-alarm.active:after {
    border-color: #3e4eb2;
    background-color: #fed832
}

.theme-rebecca-purple .site-header .header-alarm i {
    color: #fff
}

.theme-rebecca-purple .site-header .dropdown.user-menu .dropdown-toggle:after {
    color: #9fa7d8
}

@media (min-width:1055px) {
    .theme-rebecca-purple .site-header .dropdown.dropdown-typical .lbl {
        color: #fff
    }
    .theme-rebecca-purple .site-header .dropdown.dropdown-typical a.dropdown-toggle:after {
        color: #9fa7d8
    }
    .theme-rebecca-purple .site-header .dropdown.dropdown-typical a.dropdown-toggle .font-icon {
        color: #fff
    }
    .theme-rebecca-purple .site-header .help-dropdown>button {
        color: #fff
    }
}

.theme-picton-blue .site-header {
    background-color: #5fa7e7;
    border-bottom-color: #5fa7e7
}

.theme-picton-blue .site-header .site-header-search.closed {
    background: 0 0
}

.theme-picton-blue .site-header .site-header-search.closed button {
    color: #fff
}

.theme-picton-blue .site-header .site-header-search.closed:hover button {
    color: #fed832
}

.theme-picton-blue .site-header .header-alarm.active:after {
    border-color: #5fa7e7;
    background-color: #fed832
}

.theme-picton-blue .site-header .header-alarm i {
    color: #fff
}

.theme-picton-blue .site-header .dropdown.user-menu .dropdown-toggle:after {
    color: #fff
}

@media (min-width:1055px) {
    .theme-picton-blue .site-header .dropdown.dropdown-typical .lbl {
        color: #fff
    }
    .theme-picton-blue .site-header .dropdown.dropdown-typical a.dropdown-toggle .font-icon,
    .theme-picton-blue .site-header .dropdown.dropdown-typical a.dropdown-toggle:after {
        color: #fff
    }
    .theme-picton-blue .site-header .help-dropdown>button {
        color: #fff
    }
    .theme-picton-blue .site-header .dropdown>.btn {
        border-color: #fff!important;
        background-color: #fff;
        color: #00a8ff
    }
}

.theme-picton-blue .site-header .hamburger span {
    background-color: #fff
}

.theme-picton-blue .site-header .hamburger span:after,
.theme-picton-blue .site-header .hamburger span:before {
    background-color: #fff
}

.theme-picton-blue .site-header .burger-right {
    color: #fff
}

.theme-picton-blue-white-ebony .site-header .site-logo {
    background-color: #5fa7e7
}

.theme-picton-blue-white-ebony .site-header .dropdown.user-menu .dropdown-toggle:after,
.theme-picton-blue-white-ebony .site-header .header-alarm i {
    color: #76838e
}

@media (min-width:1055px) {
    .theme-picton-blue-white-ebony .site-header .dropdown.dropdown-typical .lbl {
        color: #76838e
    }
    .theme-picton-blue-white-ebony .site-header .dropdown.dropdown-typical a.dropdown-toggle .font-icon,
    .theme-picton-blue-white-ebony .site-header .dropdown.dropdown-typical a.dropdown-toggle:after {
        color: #76838e
    }
    .theme-picton-blue-white-ebony .site-header .help-dropdown>button {
        color: #76838e
    }
}

.theme-picton-blue-white-ebony .site-header .hamburger span {
    background-color: #76838e
}

.theme-picton-blue-white-ebony .site-header .hamburger span:after,
.theme-picton-blue-white-ebony .site-header .hamburger span:before {
    background-color: #76838e
}

.theme-picton-blue-white-ebony .site-header .burger-right {
    color: #76838e
}

html {
    position: relative;
    min-height: 100%
}

.side-menu-list {
    margin: 0 0 25px
}

.side-menu-list .lbl {
    font-weight: 600;
    color: #818181;
    font-size: 1rem;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out
}

.side-menu-list ul {
    display: none
}

.side-menu-list ul a {
    padding-left: 18px
}

.side-menu-list .label {
    margin: 0 0 0 .5em;
    font-weight: 400;
    font-size: .75rem;
    padding: .2em .45em .04em;
    position: relative;
    top: -1px
}

.side-menu-list>li {
    line-height: 16px
}

.side-menu-list>li.divider {
    border-top: solid 1px #c5d6de
}

.side-menu-list>li.divider:first-child {
    border-top: none
}

.side-menu-list a,
.side-menu-list li>span {
    display: block;
    padding: 11px 10px;
    position: relative;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    cursor: pointer;
    border-left: solid 4px transparent
}

.side-menu-list a:hover,
.side-menu-list li>span:hover {
    background-color: #ecf2f5
}

.side-menu-list a:hover .lbl,
.side-menu-list li>span:hover .lbl {
    color: #343434
}

.side-menu-list a.label-right,
.side-menu-list li>span.label-right {
    padding-right: 38px
}

.side-menu-list a.label-right .label,
.side-menu-list li>span.label-right .label {
    position: absolute;
    right: 21px;
    top: 50%;
    margin-top: -8px
}

.side-menu-list li.with-sub {
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out
}

.side-menu-list li.with-sub>span {
    padding-right: 25px
}

.side-menu-list li.with-sub>span:before {
    content: '';
    position: absolute;
    right: 21px;
    top: 50%;
    margin-top: -2px;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 4px 0 4px;
    border-color: #adb7be transparent transparent transparent;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg)
}

.side-menu-list li.with-sub li.with-sub>span {
    padding-left: 19px
}

.side-menu-list li.with-sub li.with-sub li>a,
.side-menu-list li.with-sub li.with-sub li>span {
    padding-left: 30px
}

.side-menu-list li.with-sub li.with-sub li.with-sub>span {
    padding-left: 30px
}

.side-menu-list li.with-sub li.with-sub li.with-sub li>a,
.side-menu-list li.with-sub li.with-sub li.with-sub li>span {
    padding-left: 40px
}

.side-menu-list li.with-sub li.with-sub li.with-sub li.with-sub>span {
    padding-left: 40px
}

.side-menu-list li.with-sub li.with-sub li.with-sub li.with-sub li>a,
.side-menu-list li.with-sub li.with-sub li.with-sub li.with-sub li>span {
    padding-left: 50px
}

.side-menu-list li.opened {
    background-color: #f6f8fa
}

.side-menu-list li.opened>span:before {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0)
}

.side-menu-list li.opened>span .lbl {
    color: #343434
}

.side-menu-list li.opened>ul {
    display: block!important
}

.side-menu-list .fa,
.side-menu-list .font-icon,
.side-menu-list .glyphicon,
.side-menu-list .tag-color {
    position: intial;
    left: 0px;
    top: 13px;
    line-height: 16px;
    font-size: 1.0625rem
}

.side-menu-list .fa.active:after,
.side-menu-list .font-icon.active:after,
.side-menu-list .glyphicon.active:after,
.side-menu-list .tag-color.active:after {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    position: absolute;
    top: -4px;
    right: -4px;
    background: #fa424a;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    border: solid 1px #fff;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out
}

.side-menu-list .fa {
    margin-top: -2px
}

.side-menu-list .glyphicon {
    top: 0px;
    margin-top: -2px

}
.side-menu-list .glyphicon customicon {
    top: 0 !important;
    position: inherit !important;
    left: 0 !important;
}

.side-menu-list .tag-color {
    display: block;
    width: 16px;
    height: 16px;
    background: #000;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    top: 11px
}

.side-menu-list .tag-color.green {
    background-color: #46c35f
}

.side-menu-list .tag-color.grey-blue {
    background-color: #9fc4e2
}

.side-menu-list .tag-color.red {
    background-color: #fa424a
}

.side-menu-list .tag-color.pink {
    background-color: #e84f9a
}

.side-menu-list .tag-color.orange {
    background-color: #ff7721
}

.side-menu-big-icon .side-menu-list {
    text-align: center;
    margin-top: -10px
}

.side-menu-big-icon .side-menu-list .lbl {
    display: block;
    color: #343434
}

.side-menu-big-icon .side-menu-list ul a {
    padding: 10px;
    background-color: #aeb8bf
}

.side-menu-big-icon .side-menu-list ul a:hover {
    background-color: #919fa9
}

.side-menu-big-icon .side-menu-list a,
.side-menu-big-icon .side-menu-list>li>span {
    padding: 15px 10px;
    border-left: none
}

.side-menu-big-icon .side-menu-list>li.with-sub>span {
    padding-right: 10px
}

.side-menu-big-icon .side-menu-list>li.with-sub>span:before {
    display: none
}

.side-menu-big-icon .side-menu-list>li.with-sub>span:after {
    content: '\52';
    font-family: startui!important;
    font-style: normal!important;
    font-weight: 400!important;
    font-variant: normal!important;
    text-transform: none!important;
    speak: none;
    line-height: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    vertical-align: middle;
    position: relative;
    top: -.15em;
    color: #919fa9;
    opacity: .5;
    position: absolute;
    right: 10px;
    top: 19px;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out
}

.side-menu-big-icon .side-menu-list>li.opened {
    color: #fff;
    background-color: #919fa9
}

.side-menu-big-icon .side-menu-list>li.opened>a,
.side-menu-big-icon .side-menu-list>li.opened>span {
    background-color: #919fa9
}

.side-menu-big-icon .side-menu-list>li.opened .fa,
.side-menu-big-icon .side-menu-list>li.opened .font-icon,
.side-menu-big-icon .side-menu-list>li.opened .glyphicon,
.side-menu-big-icon .side-menu-list>li.opened .lbl {
    color: #fff
}

.side-menu-big-icon .side-menu-list>li.opened .fa.active:after,
.side-menu-big-icon .side-menu-list>li.opened .font-icon.active:after,
.side-menu-big-icon .side-menu-list>li.opened .glyphicon.active:after {
    border-color: #919fa9
}

.side-menu-big-icon .side-menu-list>li.opened.with-sub>span:after {
    color: #fff;
    opacity: 1;
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg)
}

.side-menu-big-icon .side-menu-list .fa,
.side-menu-big-icon .side-menu-list .font-icon,
.side-menu-big-icon .side-menu-list .glyphicon {
    position: relative;
    top: 0;
    left: 0;
    display: inline-block;
    color: #919fa9;
    line-height: 1;
    font-size: 2rem;
    margin: 0 0 5px
}

.side-menu-big-icon .side-menu-list .fa.active:after,
.side-menu-big-icon .side-menu-list .font-icon.active:after,
.side-menu-big-icon .side-menu-list .glyphicon.active:after {
    width: 14px;
    height: 14px;
    top: -8px;
    right: -7px;
    border-width: 2px
}

.side-menu-list>li.brown .fa,
.side-menu-list>li.brown .font-icon,
.side-menu-list>li.brown .glyphicon {
    color: #cd6724
}

.side-menu-list>li.brown .with-sub>span,
.side-menu-list>li.brown.opened a,
.side-menu-list>li.brown.opened.with-sub>span,
.side-menu-list>li.brown.opened>span {
    border-left-color: #cd6724
}

.side-menu-list>li.green .fa,
.side-menu-list>li.green .font-icon,
.side-menu-list>li.green .glyphicon {
    color: #46c35f
}

.side-menu-list>li.green .with-sub>span,
.side-menu-list>li.green.opened a,
.side-menu-list>li.green.opened>span {
    border-left-color: #46c35f
}

.side-menu-list>li.gold .fa,
.side-menu-list>li.gold .font-icon,
.side-menu-list>li.gold .glyphicon {
    color: #f29824
}

.side-menu-list>li.gold .with-sub>span,
.side-menu-list>li.gold.opened a,
.side-menu-list>li.gold.opened>span {
    border-left-color: #f29824
}

.side-menu-list>li.blue .fa,
.side-menu-list>li.blue .font-icon,
.side-menu-list>li.blue .glyphicon {
    color: #00a8ff
}

.side-menu-list>li.blue .with-sub>span,
.side-menu-list>li.blue.opened a,
.side-menu-list>li.blue.opened>span {
    border-left-color: #00a8ff
}

.side-menu-list>li.purple .fa,
.side-menu-list>li.purple .font-icon,
.side-menu-list>li.purple .glyphicon {
    color: #ac6bec
}

.side-menu-list>li.purple .with-sub>span,
.side-menu-list>li.purple.opened a,
.side-menu-list>li.purple.opened>span {
    border-left-color: #ac6bec
}

.side-menu-list>li.orange-red .fa,
.side-menu-list>li.orange-red .font-icon,
.side-menu-list>li.orange-red .glyphicon {
    color: #ff561c
}

.side-menu-list>li.orange-red .with-sub>span,
.side-menu-list>li.orange-red.opened a,
.side-menu-list>li.orange-red.opened>span {
    border-left-color: #ff561c
}

.side-menu-list>li.grey .fa,
.side-menu-list>li.grey .font-icon,
.side-menu-list>li.grey .glyphicon {
    color: #adb7be
}

.side-menu-list>li.grey .with-sub>span,
.side-menu-list>li.grey.opened a,
.side-menu-list>li.grey.opened>span {
    border-left-color: #adb7be
}

.side-menu-list>li.red .fa,
.side-menu-list>li.red .font-icon,
.side-menu-list>li.red .glyphicon {
    color: #fa424a
}

.side-menu-list>li.red .with-sub>span,
.side-menu-list>li.red.opened a,
.side-menu-list>li.red.opened>span {
    border-left-color: #fa424a
}

.side-menu-list>li.aquamarine .fa,
.side-menu-list>li.aquamarine .font-icon,
.side-menu-list>li.aquamarine .glyphicon {
    color: #21a788
}

.side-menu-list>li.aquamarine .with-sub>span,
.side-menu-list>li.aquamarine.opened a,
.side-menu-list>li.aquamarine.opened>span {
    border-left-color: #21a788
}

.side-menu-list>li.magenta .fa,
.side-menu-list>li.magenta .font-icon,
.side-menu-list>li.magenta .glyphicon {
    color: #b348ae
}

.side-menu-list>li.magenta .with-sub>span,
.side-menu-list>li.magenta.opened a,
.side-menu-list>li.magenta.opened>span {
    border-left-color: #b348ae
}

.side-menu-list>li.blue-dirty .fa,
.side-menu-list>li.blue-dirty .font-icon,
.side-menu-list>li.blue-dirty .glyphicon {
    color: #1b99cf
}

.side-menu-list>li.blue-dirty .with-sub>span,
.side-menu-list>li.blue-dirty.opened a,
.side-menu-list>li.blue-dirty.opened>span {
    border-left-color: #1b99cf
}

.side-menu-list>li.coral .fa,
.side-menu-list>li.coral .font-icon,
.side-menu-list>li.coral .glyphicon {
    color: #fe664c
}

.side-menu-list>li.coral .with-sub>span,
.side-menu-list>li.coral.opened a,
.side-menu-list>li.coral.opened>span {
    border-left-color: #fe664c
}

.side-menu-list>li.pink-red .fa,
.side-menu-list>li.pink-red .font-icon,
.side-menu-list>li.pink-red .glyphicon {
    color: #f5465e
}

.side-menu-list>li.pink-red .with-sub>span,
.side-menu-list>li.pink-red.opened a,
.side-menu-list>li.pink-red.opened>span {
    border-left-color: #f5465e
}

.side-menu-list>li.pink .fa,
.side-menu-list>li.pink .font-icon,
.side-menu-list>li.pink .glyphicon {
    color: #e84f9a
}

.side-menu-list>li.pink .with-sub>span,
.side-menu-list>li.pink.opened a,
.side-menu-list>li.pink.opened>span {
    border-left-color: #e84f9a
}

.side-menu-list>li.blue-darker .fa,
.side-menu-list>li.blue-darker .font-icon,
.side-menu-list>li.blue-darker .glyphicon {
    color: #1280d0
}

.side-menu-list>li.blue-darker .with-sub>span,
.side-menu-list>li.blue-darker.opened a,
.side-menu-list>li.blue-darker.opened>span {
    border-left-color: #1280d0
}

.side-menu-list>li.blue-sky .fa,
.side-menu-list>li.blue-sky .font-icon,
.side-menu-list>li.blue-sky .glyphicon {
    color: #23b9e2
}

.side-menu-list>li.blue-sky .with-sub>span,
.side-menu-list>li.blue-sky.opened a,
.side-menu-list>li.blue-sky.opened>span {
    border-left-color: #23b9e2
}

.side-menu {
    width: 240px;
    position: fixed;
    top: 0;
    left: 0;
    background: #fff;
    height: 100%;
    border-right: solid 1px #c5d6de;
    overflow: auto;
    z-index: 60;
    padding: 90px 0 110px
}

.dark-theme .side-menu {
    padding-top: 60px
}

.side-menu .jspPane {
    padding-top: 90px!important
}

.dark-theme .side-menu .jspPane {
    padding-top: 60px!important
}

.side-menu .side-menu-title {
    font-weight: 600;
    text-transform: uppercase;
    color: #6c7a86;
    padding: 0 22px 8px;
    font-size: .875rem
}

.side-menu .side-menu-avatar {
    padding: 28px 0;
    background: #f6f8fa;
    margin: -10px 0 1px
}

.side-menu .side-menu-avatar .avatar-preview {
    display: block;
    margin: 0 auto
}

.side-menu.side-menu-compact {
    width: 100px;
    padding-bottom: 0;
    padding-top: 80px
}

.side-menu.side-menu-compact .jspPane {
    padding-top: 80px!important
}

.side-menu.side-menu-compact .side-menu-avatar {
    padding: 18px 0;
    margin-top: 0
}

.side-menu.side-menu-compact .side-menu-list {
    text-align: center
}

.side-menu.side-menu-compact .side-menu-list>li.opened .lbl {
    color: #343434
}

.side-menu.side-menu-compact .side-menu-list a {
    padding: 20px 9px 20px 5px
}

.side-menu.side-menu-compact .side-menu-list .fa,
.side-menu.side-menu-compact .side-menu-list .font-icon,
.side-menu.side-menu-compact .side-menu-list .glyphicon {
    position: relative;
    left: auto;
    top: 0;
    margin: 0;
    display: inline-block;
    font-size: 2.125rem;
    line-height: normal;
    min-height: 40px
}

.side-menu.side-menu-compact .side-menu-list .fa.active:after,
.side-menu.side-menu-compact .side-menu-list .font-icon.active:after,
.side-menu.side-menu-compact .side-menu-list .glyphicon.active:after {
    width: 16px;
    height: 16px;
    border-width: 2px;
    top: -6px;
    right: -6px
}

.side-menu.side-menu-compact .side-menu-list .lbl {
    display: block;
    font-size: .875rem
}

@media (max-width:1056px) {
    .side-menu {
        left: -240px;
        -webkit-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out
    }
    .menu-left-opened .side-menu {
        left: 0!important
    }
    .with-side-menu-addl .side-menu.side-menu-compact {
        left: -270px
    }
}

.side-menu-addl {
    position: fixed;
    left: 100px;
    top: 0;
    width: 220px;
    height: 100%;
    background: #fff;
    border-right: solid 1px #c5d6de;
    overflow: auto;
    z-index: 60;
    padding: 100px 0 0;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out
}

.side-menu-addl .jspPane {
    padding-top: 100px!important
}

.side-menu-addl .side-menu-addl-title {
    margin: 0 0 15px;
    padding: 8px 45px 8px 18px;
    position: relative
}

.side-menu-addl .side-menu-addl-title .caption {
    font-size: .875rem;
    font-weight: 600;
    color: #818181
}

.side-menu-addl .side-menu-addl-title .fa,
.side-menu-addl .side-menu-addl-title .font-icon,
.side-menu-addl .side-menu-addl-title .glyphicon {
    color: #adb7be;
    position: absolute;
    top: 8px;
    right: 20px;
    font-size: 1rem;
    line-height: 18px
}

.side-menu-addl .side-menu-addl-top {
    padding: 0 18px 15px;
    border-bottom: solid 1px #c5d6de;
    margin: -5px 0 0
}

.side-menu-addl .side-menu-addl-list a {
    display: table;
    width: 100%;
    font-size: .875rem;
    font-weight: 600;
    color: #818181
}

.side-menu-addl .side-menu-addl-list a .tbl-cell {
    padding: 8px 0;
    vertical-align: middle
}

.side-menu-addl .side-menu-addl-list a .tbl-cell.tbl-cell-caption {
    padding-left: 18px;
    padding-right: 20px
}

.side-menu-addl .side-menu-addl-list a .tbl-cell.tbl-cell-num {
    padding-right: 20px;
    text-align: right;
    white-space: nowrap;
    width: 10px
}

.side-menu-addl .side-menu-addl-list a:hover {
    background: #ecf2f5
}

.side-menu-addl .side-menu-addl-list li.header a {
    color: #343434
}

.side-menu-addl .side-menu-addl-list .divider {
    margin: 10px 0;
    height: 0;
    overflow: hidden;
    border-top: solid 1px #c5d6de
}

.side-menu-addl .side-menu-list a,
.side-menu-addl .side-menu-list>li>span {
    border-left: none
}

.with-side-menu-addl-full .side-menu-addl {
    left: 240px
}

@media (max-width:1199px) {
    .side-menu-addl {
        padding-top: 85px
    }
    .side-menu-addl .jspPane {
        padding-top: 85px!important
    }
    .side-menu-addl .side-menu-addl-top {
        margin-top: 0;
        padding-top: 10px
    }
}

@media (max-width:1056px) {
    .side-menu-addl {
        left: -220px
    }
    .with-side-menu-addl-full .side-menu-addl {
        left: -220px
    }
    .menu-left-opened .side-menu-addl {
        left: 100px
    }
    .with-side-menu-addl-full.menu-left-opened .side-menu-addl {
        left: 240px
    }
}

.mobile-menu-left-overlay {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 59;
    background: rgba(48, 75, 88, .5)
}

@media (max-width:1056px) {
    .menu-left-opened .mobile-menu-left-overlay {
        display: block
    }
}

.side-table-of-contents {
    padding: .5rem 22px 15px;
    font-size: 1rem;
    font-weight: 600
}

.side-table-of-contents a {
    color: #343434
}

.side-table-of-contents a:hover {
    color: #00a8ff
}

.side-table-of-contents a.active {
    font-weight: 700;
    color: #343434!important
}

.side-table-of-contents li {
    padding: 0 0 .8em
}

.side-table-of-contents ul {
    font-size: .875rem;
    padding-left: 1rem;
    padding-top: .7rem
}

.side-table-of-contents ul a {
    text-decoration: none;
    color: #919fa9;
    border: none
}

.side-table-of-contents ul a:hover {
    color: #00a8ff
}

.dark-theme .side-menu {
    background-color: #343434;
    border-right-color: #343434
}

.dark-theme .side-menu .jspDrag {
    border-color: #343434;
    background-color: #535353
}

.dark-theme .side-menu .side-menu-avatar {
    background-color: #343434;
    border-bottom: solid 1px #272727
}

.dark-theme .side-menu .side-menu-list .lbl {
    color: #fff
}

.dark-theme .side-menu .side-menu-list a:hover,
.dark-theme .side-menu .side-menu-list>li>span:hover {
    background-color: #919fa9
}

.dark-theme .side-menu .side-menu-list a:hover .lbl,
.dark-theme .side-menu .side-menu-list>li>span:hover .lbl {
    color: #fff
}

.dark-theme .side-menu .side-menu-list a:hover .fa,
.dark-theme .side-menu .side-menu-list a:hover .font-icon,
.dark-theme .side-menu .side-menu-list a:hover .glyphicon,
.dark-theme .side-menu .side-menu-list>li>span:hover .fa,
.dark-theme .side-menu .side-menu-list>li>span:hover .font-icon,
.dark-theme .side-menu .side-menu-list>li>span:hover .glyphicon {
    color: #fff
}

.dark-theme .side-menu .side-menu-list a:hover .fa.active:after,
.dark-theme .side-menu .side-menu-list a:hover .font-icon.active:after,
.dark-theme .side-menu .side-menu-list a:hover .glyphicon.active:after,
.dark-theme .side-menu .side-menu-list>li>span:hover .fa.active:after,
.dark-theme .side-menu .side-menu-list>li>span:hover .font-icon.active:after,
.dark-theme .side-menu .side-menu-list>li>span:hover .glyphicon.active:after {
    border-color: #919fa9!important
}

.dark-theme .side-menu .side-menu-list>li.with-sub>span:before {
    border-top-color: #919fa9
}

.dark-theme .side-menu .side-menu-list>li.with-sub>span:hover:before {
    border-top-color: #fff
}

.dark-theme .side-menu .side-menu-list>li.with-sub ul>li.with-sub a,
.dark-theme .side-menu .side-menu-list>li.with-sub ul>li.with-sub>span {
    border-left-color: transparent
}

.dark-theme .side-menu .side-menu-list>li.with-sub ul>li.with-sub a:hover,
.dark-theme .side-menu .side-menu-list>li.with-sub ul>li.with-sub>span:hover {
    background-color: #fff!important
}

.dark-theme .side-menu .side-menu-list>li.with-sub ul>li.with-sub a:hover .lbl,
.dark-theme .side-menu .side-menu-list>li.with-sub ul>li.with-sub>span:hover .lbl {
    color: #343434
}

.dark-theme .side-menu .side-menu-list>li.with-sub ul>li.with-sub a:hover .fa,
.dark-theme .side-menu .side-menu-list>li.with-sub ul>li.with-sub a:hover .font-icon,
.dark-theme .side-menu .side-menu-list>li.with-sub ul>li.with-sub a:hover .glyphicon,
.dark-theme .side-menu .side-menu-list>li.with-sub ul>li.with-sub>span:hover .fa,
.dark-theme .side-menu .side-menu-list>li.with-sub ul>li.with-sub>span:hover .font-icon,
.dark-theme .side-menu .side-menu-list>li.with-sub ul>li.with-sub>span:hover .glyphicon {
    color: #919fa9
}

.dark-theme .side-menu .side-menu-list>li.with-sub ul>li.with-sub a:hover .fa.active:after,
.dark-theme .side-menu .side-menu-list>li.with-sub ul>li.with-sub a:hover .font-icon.active:after,
.dark-theme .side-menu .side-menu-list>li.with-sub ul>li.with-sub a:hover .glyphicon.active:after,
.dark-theme .side-menu .side-menu-list>li.with-sub ul>li.with-sub>span:hover .fa.active:after,
.dark-theme .side-menu .side-menu-list>li.with-sub ul>li.with-sub>span:hover .font-icon.active:after,
.dark-theme .side-menu .side-menu-list>li.with-sub ul>li.with-sub>span:hover .glyphicon.active:after {
    border-color: #fff!important
}

.dark-theme .side-menu .side-menu-list>li.opened {
    background-color: #919fa9
}

.dark-theme .side-menu .side-menu-list>li.opened>span:before {
    border-top-color: #fff
}

.dark-theme .side-menu .side-menu-list>li.opened>span .lbl {
    color: #fff
}

.dark-theme .side-menu .side-menu-list>li.opened .fa,
.dark-theme .side-menu .side-menu-list>li.opened .font-icon,
.dark-theme .side-menu .side-menu-list>li.opened .glyphicon {
    color: #fff
}

.dark-theme .side-menu .side-menu-list>li.opened .fa.active:after,
.dark-theme .side-menu .side-menu-list>li.opened .font-icon.active:after,
.dark-theme .side-menu .side-menu-list>li.opened .glyphicon.active:after {
    border-color: #919fa9
}

.dark-theme .side-menu .side-menu-list>li.opened a,
.dark-theme .side-menu .side-menu-list>li.opened>span {
    border-left-color: transparent
}

.dark-theme .side-menu .side-menu-list>li.opened.with-sub a,
.dark-theme .side-menu .side-menu-list>li.opened.with-sub>span {
    border-left-color: transparent
}

.dark-theme .side-menu .side-menu-list>li.opened.with-sub a:hover,
.dark-theme .side-menu .side-menu-list>li.opened.with-sub>span:hover {
    background-color: #fff!important
}

.dark-theme .side-menu .side-menu-list>li.opened.with-sub a:hover .lbl,
.dark-theme .side-menu .side-menu-list>li.opened.with-sub>span:hover .lbl {
    color: #343434
}

.dark-theme .side-menu .side-menu-list>li.opened.with-sub a:hover .fa,
.dark-theme .side-menu .side-menu-list>li.opened.with-sub a:hover .font-icon,
.dark-theme .side-menu .side-menu-list>li.opened.with-sub a:hover .glyphicon,
.dark-theme .side-menu .side-menu-list>li.opened.with-sub>span:hover .fa,
.dark-theme .side-menu .side-menu-list>li.opened.with-sub>span:hover .font-icon,
.dark-theme .side-menu .side-menu-list>li.opened.with-sub>span:hover .glyphicon {
    color: #919fa9
}

.dark-theme .side-menu .side-menu-list>li.opened.with-sub a:hover .fa.active:after,
.dark-theme .side-menu .side-menu-list>li.opened.with-sub a:hover .font-icon.active:after,
.dark-theme .side-menu .side-menu-list>li.opened.with-sub a:hover .glyphicon.active:after,
.dark-theme .side-menu .side-menu-list>li.opened.with-sub>span:hover .fa.active:after,
.dark-theme .side-menu .side-menu-list>li.opened.with-sub>span:hover .font-icon.active:after,
.dark-theme .side-menu .side-menu-list>li.opened.with-sub>span:hover .glyphicon.active:after {
    border-color: #fff!important
}

.dark-theme .side-menu .side-menu-list .fa,
.dark-theme .side-menu .side-menu-list .font-icon,
.dark-theme .side-menu .side-menu-list .glyphicon {
    color: #919fa9;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out
}

.dark-theme .side-menu .side-menu-list .fa.active:after,
.dark-theme .side-menu .side-menu-list .font-icon.active:after,
.dark-theme .side-menu .side-menu-list .glyphicon.active:after {
    border-color: #343434
}

.dark-theme .side-menu.side-menu-compact .side-menu-avatar {
    margin-top: -30px
}

.dark-theme .side-menu.side-menu-compact .side-menu-list {
    text-align: center
}

.dark-theme .side-menu.side-menu-compact .side-menu-list>li.opened .lbl {
    color: #fff
}

.dark-theme.dark-theme-blue .side-menu-list a:hover,
.dark-theme.dark-theme-blue .side-menu-list>li>span:hover {
    background-color: #00a8ff
}

.dark-theme.dark-theme-blue .side-menu-list a:hover .fa.active:after,
.dark-theme.dark-theme-blue .side-menu-list a:hover .font-icon.active:after,
.dark-theme.dark-theme-blue .side-menu-list a:hover .glyphicon.active:after,
.dark-theme.dark-theme-blue .side-menu-list>li>span:hover .fa.active:after,
.dark-theme.dark-theme-blue .side-menu-list>li>span:hover .font-icon.active:after,
.dark-theme.dark-theme-blue .side-menu-list>li>span:hover .glyphicon.active:after {
    border-color: #00a8ff!important
}

.dark-theme.dark-theme-blue .side-menu-list>li.opened {
    background-color: #00a8ff
}

.dark-theme.dark-theme-blue .side-menu-list>li.opened .fa.active:after,
.dark-theme.dark-theme-blue .side-menu-list>li.opened .font-icon.active:after,
.dark-theme.dark-theme-blue .side-menu-list>li.opened .glyphicon.active:after {
    border-color: #00a8ff
}

.dark-theme.dark-theme-blue .side-menu-list>li.opened.with-sub a:hover .fa,
.dark-theme.dark-theme-blue .side-menu-list>li.opened.with-sub a:hover .font-icon,
.dark-theme.dark-theme-blue .side-menu-list>li.opened.with-sub a:hover .glyphicon,
.dark-theme.dark-theme-blue .side-menu-list>li.opened.with-sub>span:hover .fa,
.dark-theme.dark-theme-blue .side-menu-list>li.opened.with-sub>span:hover .font-icon,
.dark-theme.dark-theme-blue .side-menu-list>li.opened.with-sub>span:hover .glyphicon {
    color: #00a8ff
}

.dark-theme.dark-theme-blue .side-menu-list>li.opened.with-sub a:hover .fa.active:after,
.dark-theme.dark-theme-blue .side-menu-list>li.opened.with-sub a:hover .font-icon.active:after,
.dark-theme.dark-theme-blue .side-menu-list>li.opened.with-sub a:hover .glyphicon.active:after,
.dark-theme.dark-theme-blue .side-menu-list>li.opened.with-sub>span:hover .fa.active:after,
.dark-theme.dark-theme-blue .side-menu-list>li.opened.with-sub>span:hover .font-icon.active:after,
.dark-theme.dark-theme-blue .side-menu-list>li.opened.with-sub>span:hover .glyphicon.active:after {
    border-color: #fff!important
}

.dark-theme.dark-theme-green .side-menu-list a:hover,
.dark-theme.dark-theme-green .side-menu-list>li>span:hover {
    background-color: #46c35f
}

.dark-theme.dark-theme-green .side-menu-list a:hover .fa.active:after,
.dark-theme.dark-theme-green .side-menu-list a:hover .font-icon.active:after,
.dark-theme.dark-theme-green .side-menu-list a:hover .glyphicon.active:after,
.dark-theme.dark-theme-green .side-menu-list>li>span:hover .fa.active:after,
.dark-theme.dark-theme-green .side-menu-list>li>span:hover .font-icon.active:after,
.dark-theme.dark-theme-green .side-menu-list>li>span:hover .glyphicon.active:after {
    border-color: #46c35f!important
}

.dark-theme.dark-theme-green .side-menu-list>li.opened {
    background-color: #46c35f
}

.dark-theme.dark-theme-green .side-menu-list>li.opened .fa.active:after,
.dark-theme.dark-theme-green .side-menu-list>li.opened .font-icon.active:after,
.dark-theme.dark-theme-green .side-menu-list>li.opened .glyphicon.active:after {
    border-color: #46c35f
}

.dark-theme.dark-theme-green .side-menu-list>li.opened.with-sub a:hover .fa,
.dark-theme.dark-theme-green .side-menu-list>li.opened.with-sub a:hover .font-icon,
.dark-theme.dark-theme-green .side-menu-list>li.opened.with-sub a:hover .glyphicon,
.dark-theme.dark-theme-green .side-menu-list>li.opened.with-sub>span:hover .fa,
.dark-theme.dark-theme-green .side-menu-list>li.opened.with-sub>span:hover .font-icon,
.dark-theme.dark-theme-green .side-menu-list>li.opened.with-sub>span:hover .glyphicon {
    color: #46c35f
}

.dark-theme.dark-theme-green .side-menu-list>li.opened.with-sub a:hover .fa.active:after,
.dark-theme.dark-theme-green .side-menu-list>li.opened.with-sub a:hover .font-icon.active:after,
.dark-theme.dark-theme-green .side-menu-list>li.opened.with-sub a:hover .glyphicon.active:after,
.dark-theme.dark-theme-green .side-menu-list>li.opened.with-sub>span:hover .fa.active:after,
.dark-theme.dark-theme-green .side-menu-list>li.opened.with-sub>span:hover .font-icon.active:after,
.dark-theme.dark-theme-green .side-menu-list>li.opened.with-sub>span:hover .glyphicon.active:after {
    border-color: #fff!important
}

.dark-theme.dark-theme-ultramarine .side-menu-list a:hover,
.dark-theme.dark-theme-ultramarine .side-menu-list>li>span:hover {
    background-color: #1a5bc3
}

.dark-theme.dark-theme-ultramarine .side-menu-list a:hover .fa.active:after,
.dark-theme.dark-theme-ultramarine .side-menu-list a:hover .font-icon.active:after,
.dark-theme.dark-theme-ultramarine .side-menu-list a:hover .glyphicon.active:after,
.dark-theme.dark-theme-ultramarine .side-menu-list>li>span:hover .fa.active:after,
.dark-theme.dark-theme-ultramarine .side-menu-list>li>span:hover .font-icon.active:after,
.dark-theme.dark-theme-ultramarine .side-menu-list>li>span:hover .glyphicon.active:after {
    border-color: #1a5bc3!important
}

.dark-theme.dark-theme-ultramarine .side-menu-list>li.opened {
    background-color: #1a5bc3
}

.dark-theme.dark-theme-ultramarine .side-menu-list>li.opened .fa.active:after,
.dark-theme.dark-theme-ultramarine .side-menu-list>li.opened .font-icon.active:after,
.dark-theme.dark-theme-ultramarine .side-menu-list>li.opened .glyphicon.active:after {
    border-color: #1a5bc3
}

.dark-theme.dark-theme-ultramarine .side-menu-list>li.opened.with-sub a:hover .fa,
.dark-theme.dark-theme-ultramarine .side-menu-list>li.opened.with-sub a:hover .font-icon,
.dark-theme.dark-theme-ultramarine .side-menu-list>li.opened.with-sub a:hover .glyphicon,
.dark-theme.dark-theme-ultramarine .side-menu-list>li.opened.with-sub>span:hover .fa,
.dark-theme.dark-theme-ultramarine .side-menu-list>li.opened.with-sub>span:hover .font-icon,
.dark-theme.dark-theme-ultramarine .side-menu-list>li.opened.with-sub>span:hover .glyphicon {
    color: #1a5bc3
}

.dark-theme.dark-theme-ultramarine .side-menu-list>li.opened.with-sub a:hover .fa.active:after,
.dark-theme.dark-theme-ultramarine .side-menu-list>li.opened.with-sub a:hover .font-icon.active:after,
.dark-theme.dark-theme-ultramarine .side-menu-list>li.opened.with-sub a:hover .glyphicon.active:after,
.dark-theme.dark-theme-ultramarine .side-menu-list>li.opened.with-sub>span:hover .fa.active:after,
.dark-theme.dark-theme-ultramarine .side-menu-list>li.opened.with-sub>span:hover .font-icon.active:after,
.dark-theme.dark-theme-ultramarine .side-menu-list>li.opened.with-sub>span:hover .glyphicon.active:after {
    border-color: #fff!important
}

.theme-side-ebony-clay .side-menu {
    background: #263238;
    border-right-color: transparent
}

.theme-side-ebony-clay .side-menu .jspDrag {
    border-color: #263238;
    background-color: #374248
}

.theme-side-ebony-clay .side-menu .side-menu-list .lbl {
    color: #fff
}

.theme-side-ebony-clay .side-menu .side-menu-list a:hover,
.theme-side-ebony-clay .side-menu .side-menu-list>li>span:hover {
    background-color: #374248
}

.theme-side-ebony-clay .side-menu .side-menu-list a:hover .fa.active:after,
.theme-side-ebony-clay .side-menu .side-menu-list a:hover .font-icon.active:after,
.theme-side-ebony-clay .side-menu .side-menu-list a:hover .glyphicon.active:after,
.theme-side-ebony-clay .side-menu .side-menu-list a:hover .tag-color.active:after,
.theme-side-ebony-clay .side-menu .side-menu-list>li>span:hover .fa.active:after,
.theme-side-ebony-clay .side-menu .side-menu-list>li>span:hover .font-icon.active:after,
.theme-side-ebony-clay .side-menu .side-menu-list>li>span:hover .glyphicon.active:after,
.theme-side-ebony-clay .side-menu .side-menu-list>li>span:hover .tag-color.active:after {
    border-color: #374248!important
}

.theme-side-ebony-clay .side-menu .side-menu-list>li.with-sub>span:before {
    border-top-color: #9ba1a4
}

.theme-side-ebony-clay .side-menu .side-menu-list>li.opened {
    background-color: #374248
}

.theme-side-ebony-clay .side-menu .side-menu-list>li.opened>span:before {
    border-top-color: #9ba1a4
}

.theme-side-ebony-clay .side-menu .side-menu-list>li.opened>span .lbl {
    color: #fff
}

.theme-side-ebony-clay .side-menu .side-menu-list>li.opened .fa,
.theme-side-ebony-clay .side-menu .side-menu-list>li.opened .font-icon,
.theme-side-ebony-clay .side-menu .side-menu-list>li  *,
.theme-side-ebony-clay .side-menu .side-menu-list>li.opened .glyphicon {
    color: #fff
}

.theme-side-ebony-clay .side-menu .side-menu-list>li.opened .fa.active:after,
.theme-side-ebony-clay .side-menu .side-menu-list>li.opened .font-icon.active:after,
.theme-side-ebony-clay .side-menu .side-menu-list>li.opened .glyphicon.active:after,
.theme-side-ebony-clay .side-menu .side-menu-list>li.opened .tag-color.active:after {
    border-color: #374248
}

.theme-side-ebony-clay .side-menu .side-menu-list>li.opened a,
.theme-side-ebony-clay .side-menu .side-menu-list>li.opened>span {
    border-left-color: transparent
}

.theme-side-ebony-clay .side-menu .side-menu-list>li.opened.with-sub a,
.theme-side-ebony-clay .side-menu .side-menu-list>li.opened.with-sub>span {
    border-left-color: transparent
}

.theme-side-ebony-clay .side-menu .side-menu-list>li.opened.with-sub a:hover,
.theme-side-ebony-clay .side-menu .side-menu-list>li.opened.with-sub>span:hover {
    background-color: #2d383e!important
}

.theme-side-ebony-clay .side-menu .side-menu-list>li.opened.with-sub a:hover .fa.active:after,
.theme-side-ebony-clay .side-menu .side-menu-list>li.opened.with-sub a:hover .font-icon.active:after,
.theme-side-ebony-clay .side-menu .side-menu-list>li.opened.with-sub a:hover .glyphicon.active:after,
.theme-side-ebony-clay .side-menu .side-menu-list>li.opened.with-sub>span:hover .fa.active:after,
.theme-side-ebony-clay .side-menu .side-menu-list>li.opened.with-sub>span:hover .font-icon.active:after,
.theme-side-ebony-clay .side-menu .side-menu-list>li.opened.with-sub>span:hover .glyphicon.active:after {
    border-color: #2d383e!important
}

.theme-side-ebony-clay .side-menu .side-menu-list .fa.active:after,
.theme-side-ebony-clay .side-menu .side-menu-list .font-icon.active:after,
.theme-side-ebony-clay .side-menu .side-menu-list .glyphicon.active:after,
.theme-side-ebony-clay .side-menu .side-menu-list .tag-color.active:after {
    border-color: #263238
}

.theme-side-ebony-clay .side-menu .side-menu-list .fa,
.theme-side-ebony-clay .side-menu .side-menu-list .font-icon,
.theme-side-ebony-clay .side-menu .side-menu-list .glyphicon {
    color: #fff;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out
}

.theme-side-madison-caribbean .side-menu {
    background: #2d3e4d;
    border-right-color: transparent
}

.theme-side-madison-caribbean .side-menu .side-menu-title {
    color: #fff
}

.theme-side-madison-caribbean .side-menu .jspDrag {
    border-color: #2d3e4d;
    background-color: #263543
}

.theme-side-madison-caribbean .side-menu .side-menu-list .lbl {
    color: #fff
}

.theme-side-madison-caribbean .side-menu .side-menu-list a:hover,
.theme-side-madison-caribbean .side-menu .side-menu-list>li>span:hover {
    background-color: #263543
}

.theme-side-madison-caribbean .side-menu .side-menu-list a:hover .fa.active:after,
.theme-side-madison-caribbean .side-menu .side-menu-list a:hover .font-icon.active:after,
.theme-side-madison-caribbean .side-menu .side-menu-list a:hover .glyphicon.active:after,
.theme-side-madison-caribbean .side-menu .side-menu-list>li>span:hover .fa.active:after,
.theme-side-madison-caribbean .side-menu .side-menu-list>li>span:hover .font-icon.active:after,
.theme-side-madison-caribbean .side-menu .side-menu-list>li>span:hover .glyphicon.active:after {
    border-color: #263543!important
}

.theme-side-madison-caribbean .side-menu .side-menu-list>li.with-sub>span:before {
    border-top-color: #9ba1a4
}

.theme-side-madison-caribbean .side-menu .side-menu-list>li.opened {
    background-color: #263543
}

.theme-side-madison-caribbean .side-menu .side-menu-list>li.opened>span:before {
    border-top-color: #9ba1a4
}

.theme-side-madison-caribbean .side-menu .side-menu-list>li.opened>span .lbl {
    color: #fff
}

.theme-side-madison-caribbean .side-menu .side-menu-list>li.opened .fa.active:after,
.theme-side-madison-caribbean .side-menu .side-menu-list>li.opened .font-icon.active:after,
.theme-side-madison-caribbean .side-menu .side-menu-list>li.opened .glyphicon.active:after {
    border-color: #263543
}

.theme-side-madison-caribbean .side-menu .side-menu-list>li.opened a,
.theme-side-madison-caribbean .side-menu .side-menu-list>li.opened>span {
    border-left-color: transparent
}

.theme-side-madison-caribbean .side-menu .side-menu-list>li.opened.with-sub a,
.theme-side-madison-caribbean .side-menu .side-menu-list>li.opened.with-sub>span {
    border-left-color: transparent
}

.theme-side-madison-caribbean .side-menu .side-menu-list>li.opened.with-sub a:hover,
.theme-side-madison-caribbean .side-menu .side-menu-list>li.opened.with-sub>span:hover {
    background-color: #1f2b36!important
}

.theme-side-madison-caribbean .side-menu .side-menu-list>li.opened.with-sub a:hover .fa.active:after,
.theme-side-madison-caribbean .side-menu .side-menu-list>li.opened.with-sub a:hover .font-icon.active:after,
.theme-side-madison-caribbean .side-menu .side-menu-list>li.opened.with-sub a:hover .glyphicon.active:after,
.theme-side-madison-caribbean .side-menu .side-menu-list>li.opened.with-sub>span:hover .fa.active:after,
.theme-side-madison-caribbean .side-menu .side-menu-list>li.opened.with-sub>span:hover .font-icon.active:after,
.theme-side-madison-caribbean .side-menu .side-menu-list>li.opened.with-sub>span:hover .glyphicon.active:after {
    border-color: #1f2b36!important
}

.theme-side-madison-caribbean .side-menu .side-menu-list .fa,
.theme-side-madison-caribbean .side-menu .side-menu-list .font-icon,
.theme-side-madison-caribbean .side-menu .side-menu-list .glyphicon {
    color: #00c3aa;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out
}

.theme-side-madison-caribbean .side-menu .side-menu-list .fa.active:after,
.theme-side-madison-caribbean .side-menu .side-menu-list .font-icon.active:after,
.theme-side-madison-caribbean .side-menu .side-menu-list .glyphicon.active:after {
    border-color: #263543
}

.theme-side-caesium-dark-caribbean .side-menu {
    background: #2a2b3b;
    border-right-color: transparent
}

.theme-side-caesium-dark-caribbean .side-menu .side-menu-title {
    color: #acadb4
}

.theme-side-caesium-dark-caribbean .side-menu .jspDrag {
    border-color: #2a2b3b;
    background-color: #1f202c
}

.theme-side-caesium-dark-caribbean .side-menu .side-menu-list .lbl {
    color: #acadb4
}

.theme-side-caesium-dark-caribbean .side-menu .side-menu-list a:hover,
.theme-side-caesium-dark-caribbean .side-menu .side-menu-list>li>span:hover {
    background-color: #00c3aa
}

.theme-side-caesium-dark-caribbean .side-menu .side-menu-list a:hover .fa,
.theme-side-caesium-dark-caribbean .side-menu .side-menu-list a:hover .font-icon,
.theme-side-caesium-dark-caribbean .side-menu .side-menu-list a:hover .glyphicon,
.theme-side-caesium-dark-caribbean .side-menu .side-menu-list>li>span:hover .fa,
.theme-side-caesium-dark-caribbean .side-menu .side-menu-list>li>span:hover .font-icon,
.theme-side-caesium-dark-caribbean .side-menu .side-menu-list>li>span:hover .glyphicon {
    color: #fff
}

.theme-side-caesium-dark-caribbean .side-menu .side-menu-list a:hover .fa.active:after,
.theme-side-caesium-dark-caribbean .side-menu .side-menu-list a:hover .font-icon.active:after,
.theme-side-caesium-dark-caribbean .side-menu .side-menu-list a:hover .glyphicon.active:after,
.theme-side-caesium-dark-caribbean .side-menu .side-menu-list>li>span:hover .fa.active:after,
.theme-side-caesium-dark-caribbean .side-menu .side-menu-list>li>span:hover .font-icon.active:after,
.theme-side-caesium-dark-caribbean .side-menu .side-menu-list>li>span:hover .glyphicon.active:after {
    border-color: #00c3aa!important
}

.theme-side-caesium-dark-caribbean .side-menu .side-menu-list a:hover .lbl,
.theme-side-caesium-dark-caribbean .side-menu .side-menu-list>li>span:hover .lbl {
    color: #fff
}

.theme-side-caesium-dark-caribbean .side-menu .side-menu-list>li.with-sub>span:before {
    border-top-color: #919299
}

.theme-side-caesium-dark-caribbean .side-menu .side-menu-list>li.with-sub>span:hover:before {
    border-top-color: #fff
}

.theme-side-caesium-dark-caribbean .side-menu .side-menu-list>li.opened {
    background-color: #00c3aa
}

.theme-side-caesium-dark-caribbean .side-menu .side-menu-list>li.opened>span:before {
    border-top-color: #919299
}

.theme-side-caesium-dark-caribbean .side-menu .side-menu-list>li.opened .fa,
.theme-side-caesium-dark-caribbean .side-menu .side-menu-list>li.opened .font-icon,
.theme-side-caesium-dark-caribbean .side-menu .side-menu-list>li.opened .glyphicon {
    color: #fff
}

.theme-side-caesium-dark-caribbean .side-menu .side-menu-list>li.opened .fa.active:after,
.theme-side-caesium-dark-caribbean .side-menu .side-menu-list>li.opened .font-icon.active:after,
.theme-side-caesium-dark-caribbean .side-menu .side-menu-list>li.opened .glyphicon.active:after {
    border-color: #22222f
}

.theme-side-caesium-dark-caribbean .side-menu .side-menu-list>li.opened a,
.theme-side-caesium-dark-caribbean .side-menu .side-menu-list>li.opened>span {
    border-left-color: transparent
}

.theme-side-caesium-dark-caribbean .side-menu .side-menu-list>li.opened a .lbl,
.theme-side-caesium-dark-caribbean .side-menu .side-menu-list>li.opened>span .lbl {
    color: #fff
}

.theme-side-caesium-dark-caribbean .side-menu .side-menu-list>li.opened.with-sub {
    background-color: #22222f
}

.theme-side-caesium-dark-caribbean .side-menu .side-menu-list>li.opened.with-sub a,
.theme-side-caesium-dark-caribbean .side-menu .side-menu-list>li.opened.with-sub>span {
    border-left-color: transparent
}

.theme-side-caesium-dark-caribbean .side-menu .side-menu-list>li.opened.with-sub a:hover,
.theme-side-caesium-dark-caribbean .side-menu .side-menu-list>li.opened.with-sub>span:hover {
    background-color: #00c3aa!important
}

.theme-side-caesium-dark-caribbean .side-menu .side-menu-list>li.opened.with-sub a:hover .fa.active:after,
.theme-side-caesium-dark-caribbean .side-menu .side-menu-list>li.opened.with-sub a:hover .font-icon.active:after,
.theme-side-caesium-dark-caribbean .side-menu .side-menu-list>li.opened.with-sub a:hover .glyphicon.active:after,
.theme-side-caesium-dark-caribbean .side-menu .side-menu-list>li.opened.with-sub>span:hover .fa.active:after,
.theme-side-caesium-dark-caribbean .side-menu .side-menu-list>li.opened.with-sub>span:hover .font-icon.active:after,
.theme-side-caesium-dark-caribbean .side-menu .side-menu-list>li.opened.with-sub>span:hover .glyphicon.active:after {
    border-color: #00c3aa!important
}

.theme-side-caesium-dark-caribbean .side-menu .side-menu-list>li.opened.with-sub a:hover .lbl,
.theme-side-caesium-dark-caribbean .side-menu .side-menu-list>li.opened.with-sub>span:hover .lbl {
    color: #fff
}

.theme-side-caesium-dark-caribbean .side-menu .side-menu-list .fa,
.theme-side-caesium-dark-caribbean .side-menu .side-menu-list .font-icon,
.theme-side-caesium-dark-caribbean .side-menu .side-menu-list .glyphicon {
    color: #e3e3e5;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out
}

.theme-side-caesium-dark-caribbean .side-menu .side-menu-list .fa.active:after,
.theme-side-caesium-dark-caribbean .side-menu .side-menu-list .font-icon.active:after,
.theme-side-caesium-dark-caribbean .side-menu .side-menu-list .glyphicon.active:after {
    border-color: #2a2b3b
}

.theme-side-tin .side-menu {
    background: #424242;
    border-right-color: transparent
}

.theme-side-tin .side-menu .side-menu-title {
    color: #b4b5bb
}

.theme-side-tin .side-menu .jspDrag {
    border-color: #424242;
    background-color: #333
}

.theme-side-tin .side-menu .side-menu-list .lbl {
    color: #b4b5bb
}

.theme-side-tin .side-menu .side-menu-list a:hover,
.theme-side-tin .side-menu .side-menu-list>li>span:hover {
    background-color: #383838
}

.theme-side-tin .side-menu .side-menu-list a:hover .fa.active:after,
.theme-side-tin .side-menu .side-menu-list a:hover .font-icon.active:after,
.theme-side-tin .side-menu .side-menu-list a:hover .glyphicon.active:after,
.theme-side-tin .side-menu .side-menu-list>li>span:hover .fa.active:after,
.theme-side-tin .side-menu .side-menu-list>li>span:hover .font-icon.active:after,
.theme-side-tin .side-menu .side-menu-list>li>span:hover .glyphicon.active:after {
    border-color: #383838!important
}

.theme-side-tin .side-menu .side-menu-list>li.with-sub>span:before {
    border-top-color: #9c9c9c
}

.theme-side-tin .side-menu .side-menu-list>li.opened {
    background-color: #383838
}

.theme-side-tin .side-menu .side-menu-list>li.opened .fa.active:after,
.theme-side-tin .side-menu .side-menu-list>li.opened .font-icon.active:after,
.theme-side-tin .side-menu .side-menu-list>li.opened .glyphicon.active:after {
    border-color: #383838
}

.theme-side-tin .side-menu .side-menu-list>li.opened a,
.theme-side-tin .side-menu .side-menu-list>li.opened>span {
    border-left-color: transparent
}

.theme-side-tin .side-menu .side-menu-list>li.opened.with-sub a,
.theme-side-tin .side-menu .side-menu-list>li.opened.with-sub>span {
    border-left-color: transparent
}

.theme-side-tin .side-menu .side-menu-list>li.opened.with-sub a:hover,
.theme-side-tin .side-menu .side-menu-list>li.opened.with-sub>span:hover {
    background-color: #2e2e2e!important
}

.theme-side-tin .side-menu .side-menu-list>li.opened.with-sub a:hover .fa.active:after,
.theme-side-tin .side-menu .side-menu-list>li.opened.with-sub a:hover .font-icon.active:after,
.theme-side-tin .side-menu .side-menu-list>li.opened.with-sub a:hover .glyphicon.active:after,
.theme-side-tin .side-menu .side-menu-list>li.opened.with-sub>span:hover .fa.active:after,
.theme-side-tin .side-menu .side-menu-list>li.opened.with-sub>span:hover .font-icon.active:after,
.theme-side-tin .side-menu .side-menu-list>li.opened.with-sub>span:hover .glyphicon.active:after {
    border-color: #2e2e2e!important
}

.theme-side-tin .side-menu .side-menu-list .fa,
.theme-side-tin .side-menu .side-menu-list .font-icon,
.theme-side-tin .side-menu .side-menu-list .glyphicon {
    color: #e6e6e6;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out
}

.theme-side-tin .side-menu .side-menu-list .fa.active:after,
.theme-side-tin .side-menu .side-menu-list .font-icon.active:after,
.theme-side-tin .side-menu .side-menu-list .glyphicon.active:after {
    border-color: #424242
}

.theme-side-litmus-blue .side-menu {
    background: #2c313e;
    border-right-color: transparent
}

.theme-side-litmus-blue .side-menu .side-menu-title {
    color: #fff
}

.theme-side-litmus-blue .side-menu .jspDrag {
    border-color: #2c313e;
    background-color: #242732
}

.theme-side-litmus-blue .side-menu .side-menu-list .lbl {
    color: #fff
}

.theme-side-litmus-blue .side-menu .side-menu-list a:hover,
.theme-side-litmus-blue .side-menu .side-menu-list>li>span:hover {
    background-color: #00a8ff
}

.theme-side-litmus-blue .side-menu .side-menu-list a:hover .fa.active:after,
.theme-side-litmus-blue .side-menu .side-menu-list a:hover .font-icon.active:after,
.theme-side-litmus-blue .side-menu .side-menu-list a:hover .glyphicon.active:after,
.theme-side-litmus-blue .side-menu .side-menu-list>li>span:hover .fa.active:after,
.theme-side-litmus-blue .side-menu .side-menu-list>li>span:hover .font-icon.active:after,
.theme-side-litmus-blue .side-menu .side-menu-list>li>span:hover .glyphicon.active:after {
    border-color: #00a8ff!important
}

.theme-side-litmus-blue .side-menu .side-menu-list>li.with-sub>span:before {
    border-top-color: #9c9c9c
}

.theme-side-litmus-blue .side-menu .side-menu-list>li.with-sub>span:hover:before {
    border-top-color: #fff
}

.theme-side-litmus-blue .side-menu .side-menu-list>li.opened {
    background: #00a8ff
}

.theme-side-litmus-blue .side-menu .side-menu-list>li.opened .fa.active:after,
.theme-side-litmus-blue .side-menu .side-menu-list>li.opened .font-icon.active:after,
.theme-side-litmus-blue .side-menu .side-menu-list>li.opened .glyphicon.active:after {
    border-color: #282c38
}

.theme-side-litmus-blue .side-menu .side-menu-list>li.opened a,
.theme-side-litmus-blue .side-menu .side-menu-list>li.opened>span {
    border-left-color: transparent
}

.theme-side-litmus-blue .side-menu .side-menu-list>li.opened.with-sub {
    background-color: #282c38
}

.theme-side-litmus-blue .side-menu .side-menu-list>li.opened.with-sub a,
.theme-side-litmus-blue .side-menu .side-menu-list>li.opened.with-sub>span {
    border-left-color: transparent
}

.theme-side-litmus-blue .side-menu .side-menu-list>li.opened.with-sub a:hover,
.theme-side-litmus-blue .side-menu .side-menu-list>li.opened.with-sub>span:hover {
    background-color: #00a8ff!important
}

.theme-side-litmus-blue .side-menu .side-menu-list>li.opened.with-sub a:hover .fa.active:after,
.theme-side-litmus-blue .side-menu .side-menu-list>li.opened.with-sub a:hover .font-icon.active:after,
.theme-side-litmus-blue .side-menu .side-menu-list>li.opened.with-sub a:hover .glyphicon.active:after,
.theme-side-litmus-blue .side-menu .side-menu-list>li.opened.with-sub>span:hover .fa.active:after,
.theme-side-litmus-blue .side-menu .side-menu-list>li.opened.with-sub>span:hover .font-icon.active:after,
.theme-side-litmus-blue .side-menu .side-menu-list>li.opened.with-sub>span:hover .glyphicon.active:after {
    border-color: #00a8ff!important
}

.theme-side-litmus-blue .side-menu .side-menu-list .fa,
.theme-side-litmus-blue .side-menu .side-menu-list .font-icon,
.theme-side-litmus-blue .side-menu .side-menu-list .glyphicon {
    color: #fff;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out
}

.theme-side-litmus-blue .side-menu .side-menu-list .fa.active:after,
.theme-side-litmus-blue .side-menu .side-menu-list .font-icon.active:after,
.theme-side-litmus-blue .side-menu .side-menu-list .glyphicon.active:after {
    border-color: #2c313e
}

.theme-rebecca-purple .side-menu .side-menu-list .lbl {
    color: #838383
}

.theme-rebecca-purple .side-menu .side-menu-list a:hover,
.theme-rebecca-purple .side-menu .side-menu-list>li>span:hover {
    background-color: #f4f4f4
}

.theme-rebecca-purple .side-menu .side-menu-list a:hover .fa.active:after,
.theme-rebecca-purple .side-menu .side-menu-list a:hover .font-icon.active:after,
.theme-rebecca-purple .side-menu .side-menu-list a:hover .glyphicon.active:after,
.theme-rebecca-purple .side-menu .side-menu-list>li>span:hover .fa.active:after,
.theme-rebecca-purple .side-menu .side-menu-list>li>span:hover .font-icon.active:after,
.theme-rebecca-purple .side-menu .side-menu-list>li>span:hover .glyphicon.active:after {
    border-color: #f4f4f4!important
}

.theme-rebecca-purple .side-menu .side-menu-list>li.with-sub>span:before {
    border-top-color: #bbb
}

.theme-rebecca-purple .side-menu .side-menu-list>li.with-sub>span:hover:before {
    border-top-color: #999
}

.theme-rebecca-purple .side-menu .side-menu-list>li.opened {
    background: #f4f4f4
}

.theme-rebecca-purple .side-menu .side-menu-list>li.opened .fa.active:after,
.theme-rebecca-purple .side-menu .side-menu-list>li.opened .font-icon.active:after,
.theme-rebecca-purple .side-menu .side-menu-list>li.opened .glyphicon.active:after {
    border-color: #f4f4f4
}

.theme-rebecca-purple .side-menu .side-menu-list>li.opened a,
.theme-rebecca-purple .side-menu .side-menu-list>li.opened>span {
    border-left-color: transparent
}

.theme-rebecca-purple .side-menu .side-menu-list>li.opened.with-sub {
    background-color: #f4f4f4
}

.theme-rebecca-purple .side-menu .side-menu-list>li.opened.with-sub a,
.theme-rebecca-purple .side-menu .side-menu-list>li.opened.with-sub>span {
    border-left-color: transparent
}

.theme-rebecca-purple .side-menu .side-menu-list>li.opened.with-sub a:hover,
.theme-rebecca-purple .side-menu .side-menu-list>li.opened.with-sub>span:hover {
    background-color: #cbcdd0!important
}

.theme-rebecca-purple .side-menu .side-menu-list>li.opened.with-sub a:hover .fa,
.theme-rebecca-purple .side-menu .side-menu-list>li.opened.with-sub a:hover .font-icon,
.theme-rebecca-purple .side-menu .side-menu-list>li.opened.with-sub a:hover .glyphicon,
.theme-rebecca-purple .side-menu .side-menu-list>li.opened.with-sub>span:hover .fa,
.theme-rebecca-purple .side-menu .side-menu-list>li.opened.with-sub>span:hover .font-icon,
.theme-rebecca-purple .side-menu .side-menu-list>li.opened.with-sub>span:hover .glyphicon {
    color: #454545
}

.theme-rebecca-purple .side-menu .side-menu-list>li.opened.with-sub a:hover .fa.active:after,
.theme-rebecca-purple .side-menu .side-menu-list>li.opened.with-sub a:hover .font-icon.active:after,
.theme-rebecca-purple .side-menu .side-menu-list>li.opened.with-sub a:hover .glyphicon.active:after,
.theme-rebecca-purple .side-menu .side-menu-list>li.opened.with-sub>span:hover .fa.active:after,
.theme-rebecca-purple .side-menu .side-menu-list>li.opened.with-sub>span:hover .font-icon.active:after,
.theme-rebecca-purple .side-menu .side-menu-list>li.opened.with-sub>span:hover .glyphicon.active:after {
    border-color: #cbcdd0!important
}

.theme-rebecca-purple .side-menu .side-menu-list>li.opened.with-sub a:hover .lbl,
.theme-rebecca-purple .side-menu .side-menu-list>li.opened.with-sub>span:hover .lbl {
    color: #454545
}

.theme-rebecca-purple .side-menu .side-menu-list .fa,
.theme-rebecca-purple .side-menu .side-menu-list .font-icon,
.theme-rebecca-purple .side-menu .side-menu-list .glyphicon {
    color: #838383;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out
}

.theme-picton-blue .side-menu .side-menu-list a:hover,
.theme-picton-blue .side-menu .side-menu-list>li>span:hover {
    background-color: #eff6fc
}

.theme-picton-blue .side-menu .side-menu-list a:hover .fa.active:after,
.theme-picton-blue .side-menu .side-menu-list a:hover .font-icon.active:after,
.theme-picton-blue .side-menu .side-menu-list a:hover .glyphicon.active:after,
.theme-picton-blue .side-menu .side-menu-list>li>span:hover .fa.active:after,
.theme-picton-blue .side-menu .side-menu-list>li>span:hover .font-icon.active:after,
.theme-picton-blue .side-menu .side-menu-list>li>span:hover .glyphicon.active:after {
    border-color: #eff6fc!important
}

.theme-picton-blue .side-menu .side-menu-list>li.with-sub>span:before {
    border-top-color: #5fa7e7
}

.theme-picton-blue .side-menu .side-menu-list>li.opened {
    background: #eff6fc
}

.theme-picton-blue .side-menu .side-menu-list>li.opened .fa.active:after,
.theme-picton-blue .side-menu .side-menu-list>li.opened .font-icon.active:after,
.theme-picton-blue .side-menu .side-menu-list>li.opened .glyphicon.active:after {
    border-color: #eff6fc
}

.theme-picton-blue .side-menu .side-menu-list>li.opened a,
.theme-picton-blue .side-menu .side-menu-list>li.opened>span {
    border-left-color: transparent
}

.theme-picton-blue .side-menu .side-menu-list>li.opened.with-sub a,
.theme-picton-blue .side-menu .side-menu-list>li.opened.with-sub>span {
    border-left-color: transparent
}

.theme-picton-blue .side-menu .side-menu-list>li.opened.with-sub a .lbl,
.theme-picton-blue .side-menu .side-menu-list>li.opened.with-sub>span .lbl {
    color: #818181
}

.theme-picton-blue .side-menu .side-menu-list>li.opened.with-sub a:hover,
.theme-picton-blue .side-menu .side-menu-list>li.opened.with-sub>span:hover {
    background-color: #d1e5f8!important
}

.theme-picton-blue .side-menu .side-menu-list>li.opened.with-sub a:hover .lbl,
.theme-picton-blue .side-menu .side-menu-list>li.opened.with-sub>span:hover .lbl {
    color: #454545
}

.theme-picton-blue .side-menu .side-menu-list .fa,
.theme-picton-blue .side-menu .side-menu-list .font-icon,
.theme-picton-blue .side-menu .side-menu-list .glyphicon {
    color: #5fa7e7;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out
}

.theme-picton-blue-white-ebony .side-menu {
    background: #263238;
    border-right-color: transparent
}

.theme-picton-blue-white-ebony .side-menu .jspDrag {
    border-color: #263238;
    background-color: #374248
}

.theme-picton-blue-white-ebony .side-menu .side-menu-list .lbl {
    color: #929fa6
}

.theme-picton-blue-white-ebony .side-menu .side-menu-list a:hover,
.theme-picton-blue-white-ebony .side-menu .side-menu-list>li>span:hover {
    background-color: #374248
}

.theme-picton-blue-white-ebony .side-menu .side-menu-list a:hover .lbl,
.theme-picton-blue-white-ebony .side-menu .side-menu-list>li>span:hover .lbl {
    color: #fff
}

.theme-picton-blue-white-ebony .side-menu .side-menu-list a:hover .fa,
.theme-picton-blue-white-ebony .side-menu .side-menu-list a:hover .font-icon,
.theme-picton-blue-white-ebony .side-menu .side-menu-list a:hover .glyphicon,
.theme-picton-blue-white-ebony .side-menu .side-menu-list>li>span:hover .fa,
.theme-picton-blue-white-ebony .side-menu .side-menu-list>li>span:hover .font-icon,
.theme-picton-blue-white-ebony .side-menu .side-menu-list>li>span:hover .glyphicon {
    color: #fff
}

.theme-picton-blue-white-ebony .side-menu .side-menu-list a:hover .fa.active:after,
.theme-picton-blue-white-ebony .side-menu .side-menu-list a:hover .font-icon.active:after,
.theme-picton-blue-white-ebony .side-menu .side-menu-list a:hover .glyphicon.active:after,
.theme-picton-blue-white-ebony .side-menu .side-menu-list>li>span:hover .fa.active:after,
.theme-picton-blue-white-ebony .side-menu .side-menu-list>li>span:hover .font-icon.active:after,
.theme-picton-blue-white-ebony .side-menu .side-menu-list>li>span:hover .glyphicon.active:after {
    border-color: #374248!important
}

.theme-picton-blue-white-ebony .side-menu .side-menu-list>li.with-sub>span:before {
    border-top-color: #9ba1a4
}

.theme-picton-blue-white-ebony .side-menu .side-menu-list>li.opened {
    background-color: #374248
}

.theme-picton-blue-white-ebony .side-menu .side-menu-list>li.opened .lbl {
    color: #fff
}

.theme-picton-blue-white-ebony .side-menu .side-menu-list>li.opened>span:before {
    border-top-color: #9ba1a4
}

.theme-picton-blue-white-ebony .side-menu .side-menu-list>li.opened .fa,
.theme-picton-blue-white-ebony .side-menu .side-menu-list>li.opened .font-icon,
.theme-picton-blue-white-ebony .side-menu .side-menu-list>li.opened .glyphicon {
    color: #fff
}

.theme-picton-blue-white-ebony .side-menu .side-menu-list>li.opened .fa.active:after,
.theme-picton-blue-white-ebony .side-menu .side-menu-list>li.opened .font-icon.active:after,
.theme-picton-blue-white-ebony .side-menu .side-menu-list>li.opened .glyphicon.active:after,
.theme-picton-blue-white-ebony .side-menu .side-menu-list>li.opened .tag-color.active:after {
    border-color: #374248
}

.theme-picton-blue-white-ebony .side-menu .side-menu-list>li.opened a,
.theme-picton-blue-white-ebony .side-menu .side-menu-list>li.opened>span {
    border-left-color: transparent
}

.theme-picton-blue-white-ebony .side-menu .side-menu-list>li.opened.with-sub a,
.theme-picton-blue-white-ebony .side-menu .side-menu-list>li.opened.with-sub>span {
    border-left-color: transparent
}

.theme-picton-blue-white-ebony .side-menu .side-menu-list>li.opened.with-sub a:hover,
.theme-picton-blue-white-ebony .side-menu .side-menu-list>li.opened.with-sub>span:hover {
    background-color: #2d383e!important
}

.theme-picton-blue-white-ebony .side-menu .side-menu-list>li.opened.with-sub a:hover .fa.active:after,
.theme-picton-blue-white-ebony .side-menu .side-menu-list>li.opened.with-sub a:hover .font-icon.active:after,
.theme-picton-blue-white-ebony .side-menu .side-menu-list>li.opened.with-sub a:hover .glyphicon.active:after,
.theme-picton-blue-white-ebony .side-menu .side-menu-list>li.opened.with-sub>span:hover .fa.active:after,
.theme-picton-blue-white-ebony .side-menu .side-menu-list>li.opened.with-sub>span:hover .font-icon.active:after,
.theme-picton-blue-white-ebony .side-menu .side-menu-list>li.opened.with-sub>span:hover .glyphicon.active:after {
    border-color: #2d383e!important
}

.theme-picton-blue-white-ebony .side-menu .side-menu-list .fa.active:after,
.theme-picton-blue-white-ebony .side-menu .side-menu-list .font-icon.active:after,
.theme-picton-blue-white-ebony .side-menu .side-menu-list .glyphicon.active:after,
.theme-picton-blue-white-ebony .side-menu .side-menu-list .tag-color.active:after {
    border-color: #263238
}

.theme-picton-blue-white-ebony .side-menu .side-menu-list .fa,
.theme-picton-blue-white-ebony .side-menu .side-menu-list .font-icon,
.theme-picton-blue-white-ebony .side-menu .side-menu-list .glyphicon {
    color: #929fa6;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out
}

.show-hide-sidebar {
    position: relative;
    top: 5px;
    overflow: hidden;
    margin: 0;
    padding: 0;
    width: 30px;
    height: 30px;
    font-size: 0;
    text-indent: -9999px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-border-radius: 0;
    border-radius: 0;
    border: none;
    cursor: pointer;
    -webkit-transition: background .3s;
    transition: background .3s;
    background: 0 0
}

.show-hide-sidebar span {
    margin: 0 4px;
    position: absolute;
    top: 50%;
    margin-top: -1px;
    left: 0;
    right: 0;
    height: 3px;
    background: #adb7be;
    -webkit-transition: -webkit-transform .3s;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s
}

.show-hide-sidebar span:after,
.show-hide-sidebar span:before {
    position: absolute;
    display: block;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #adb7be;
    content: ""
}

.show-hide-sidebar span:before {
    top: -7px;
    -webkit-transform-origin: top right;
    -ms-transform-origin: top right;
    transform-origin: top right;
    -webkit-transition: width .3s, top .3s, -webkit-transform .3s;
    transition: width .3s, top .3s, -webkit-transform .3s;
    transition: transform .3s, width .3s, top .3s;
    transition: transform .3s, width .3s, top .3s, -webkit-transform .3s
}

.show-hide-sidebar span:after {
    bottom: -7px;
    -webkit-transform-origin: bottom right;
    -ms-transform-origin: bottom right;
    transform-origin: bottom right;
    -webkit-transition: width .3s, bottom .3s, -webkit-transform .3s;
    transition: width .3s, bottom .3s, -webkit-transform .3s;
    transition: transform .3s, width .3s, bottom .3s;
    transition: transform .3s, width .3s, bottom .3s, -webkit-transform .3s
}

.show-hide-sidebar:hover span,
.show-hide-sidebar:hover span:after,
.show-hide-sidebar:hover span:before {
    background-color: #00a8ff
}

@media (max-width:1056px) {
    .show-hide-sidebar {
        display: block
    }
}

body.sidebar-hidden .side-menu {
    display: none
}

body.sidebar-hidden .page-content {
    padding-left: 20px
}

body.sidebar-hidden .side-menu-addl {
    left: 0
}

body.sidebar-hidden .side-menu-addl+.page-content {
    padding-left: 240px
}

@media screen and (max-width:1056px) {
    .show-hide-sidebar {
        display: none!important
    }
}

@media print {
    body {
        background: #fff!important
    }
    .side-menu,
    .site-header {
        display: none
    }
    .page-content {
        padding: 15px 0
    }
    .dark-theme .page-content {
        padding-top: 15px
    }
    .chrome-browser.with-side-menu .page-content {
        padding-left: 255px;
        margin-left: -255px
    }
    .chrome-browser.with-side-menu-compact .page-content {
        padding-left: 115px;
        margin-left: -115px
    }
    .chrome-browser.with-side-menu-addl .page-content {
        padding-left: 335px;
        margin-left: -335px
    }
    .box-typical {
        padding: 0!important;
        border: none!important
    }
    .row>div {
        width: 100%!important;
        float: none!important;
        margin: 0!important
    }
}

@media print and (max-width:1056px) {
    .chrome-browser.with-side-menu .page-content {
        padding-left: 0;
        padding-right: 0
    }
}

@media print and (max-width:1056px) {
    .chrome-browser.with-side-menu-compact .page-content {
        padding-left: 0;
        padding-right: 0
    }
}

@media print and (max-width:1056px) {
    .chrome-browser.with-side-menu-addl .page-content {
        padding-left: 0;
        padding-right: 0
    }
}

.bg-yellow1
{

 margin-left:20px;
}
.yellow1
{
color: yellowgreen !important;
}
html, body {
    height: 100%;
    overflow-x: hidden;
}
#root {
    height: 100%;
}
.wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}
.wrapper:before, .wrapper:after {
    content: " ";
    display: table;
}
.wrapper:after {
    clear: both;
}
.align-middle {
    vertical-align: middle;
}
.row-table {
    display: table;
    table-layout: fixed;
    height: 100%;
    width: 100%;
    margin: 0;
}
.row-table > [class*="col-"] {
    display: table-cell;
    float: none;
    table-layout: fixed;
    vertical-align: middle;
}
.panel-default {
    border-color: #ddd;
}
.panel{
    margin-bottom: 20px;
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgba(0,0,0,.05);
    box-shadow: 0 1px 1px rgba(0,0,0,.05);
    border: 1px solid #eee;
    border-bottom: 1px solid #d1d2d3;
}
.mb-lg {
    margin-bottom: 15px !important;
}
.panel-body {
    padding: 15px;
}
.mt0 {
    margin-top: 0 !important;
}
.c-checkbox, .c-radio {
    margin-right: 4px;
}
.checkbox label, .radio label {
    min-height: 20px;
    padding-left: 20px;
    margin-bottom: 0;
    font-weight: 400;
    cursor: pointer;
}
.c-checkbox span, .c-radio span {
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin-left: -20px;
    width: 20px;
    height: 20px;
    border-radius: 2px;
    border: 1px solid #ccc;
    margin-right: 5px;
}
.c-checkbox *, .c-radio * {
    cursor: pointer;
}
.c-checkbox span:before, .c-radio span:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    text-align: center !important;
    font-size: 12px;
    line-height: 18px;
    vertical-align: middle;
}
input[type=checkbox]:checked + span, input[type=radio]:checked + span {
    border-color: #3498db;
    background-color: #3498db;
}
input[type=checkbox]:checked + span:before, input[type=radio]:checked + span:before {
    color: #fff;
    opacity: 1;
    transition: color 0.3 ease-out;
}
.checkbox input[type=checkbox], .checkbox-inline input[type=checkbox], .radio input[type=radio], .radio-inline input[type=radio] {
    position: absolute;
    margin-top: 4px\9;
    margin-left: -20px;
}
.form-control-feedback {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    display: block;
    width: 34px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    pointer-events: none;
}
.has-feedback .form-control-feedback {
    top: 0;
}
.has-feedback .form-control {
    padding-right: 42.5px;
}
.form-control {
    box-shadow: 0 0 0 #000 !important;
}
.form-control, .input-group-addon {
    border-color: #dbd9d9;
}
.form-control {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}
.has-feedback .form-control-feedback {
    top: 0;
}
.has-feedback {
    position: relative;
}
.btn, .fc-button {
    border-radius: 3px;
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: 0 -1px 0px rgba(0, 0, 0, 0.15) inset;
    -webkit-appearance: none;
    outline: none !important;
    transition: all 0.1s;
}
.btn-block {
    display: block;
    width: 100%;
}
.btn-primary {
    color: #fff;
    background-color: #337ab7;
    border-color: #2e6da4;
}
.btn {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
}
/* To Dropdown navbar dropdown on hover */
.navbar-nav > li:hover > .dropdown-menu {
    display: block;
}
.dropdown-submenu {
    position: relative;
}

.dropdown-submenu>.dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -6px;
    margin-left: -1px;
    -webkit-border-radius: 0 6px 6px 6px;
    -moz-border-radius: 0 6px 6px;
    border-radius: 0 6px 6px 6px;
}

.dropdown-submenu:hover>.dropdown-menu {
    display: block;
}

.dropdown-submenu>a:after {
    display: block;
    content: " ";
    float: right;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 5px 0 5px 5px;
    border-left-color: #ccc;
    margin-top: 5px;
    margin-right: -10px;
}

.dropdown-submenu:hover>a:after {
    border-left-color: #fff;
}

.dropdown-submenu.pull-left {
    float: none;
}

.dropdown-submenu.pull-left>.dropdown-menu {
    left: -100%;
    margin-left: 10px;
    -webkit-border-radius: 6px 0 6px 6px;
    -moz-border-radius: 6px 0 6px 6px;
    border-radius: 6px 0 6px 6px;
}
#column_left {
	background-color: #263238;
}

.side-menu-nav-list ul a{
    padding-left: 20px !important;
    font-size: 13px;
}
.side-menu-nav-list ul li ul a{
    padding-left: 28px !important;
    background-color: #3a3a3a;
    font-size: 12px;
}
.side-menu .fa{
    text-align: center;
    width: 1.25em;
}
.side-menu .fa:hover{
    color: #fff;
    font-weight: 700;
}
.side-menu i{
    font-size: .85rem;
    margin-right: .25rem;
}
.site-header .user-menu.dropdown .dropdown-toggle:after {
    border-top: 5px solid;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}
.dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-left: .3em solid transparent;
}
.dropdown-toggle:after {
    top: 2px;
    position: relative;
}
.nav>li>a:focus {
    text-decoration: none;
    background-color: rgb(139, 147, 155);
    color: #fff;
}
footer.sticky-footer {
    padding: 1rem 0;
    bottom: 0;
    position: static;
    width: 100%;
    padding-left: 240px;
    margin-left: 0px;
}
.bg-white {
    background-color: #fff!important;
}
footer.sticky-footer .copyright {
    line-height: 1;
    font-size: .8rem;
}
.card {
    display: inline-block;
    position: relative;
    width: 100%;
    margin: 25px 0;
    -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
    box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.14);
    border-radius: 3px;
    color: rgba(0, 0, 0, 0.87);
    background: #fff;
    min-height: 166px;
}
.card-stats .card-header {
    float: left;
    text-align: center;
}
.card [data-background-color] {
    color: #FFFFFF;
}
.card [data-background-color="orange"] {
    background: -webkit-linear-gradient(30deg, #ffa726, #fb8c00);
    background: -o-linear-gradient(30deg, #ffa726, #fb8c00);
    background: linear-gradient(60deg, #ffa726, #fb8c00);
    -webkit-box-shadow: 0 12px 20px -10px rgba(255, 152, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 152, 0, 0.2);
    box-shadow: 0 12px 20px -10px rgba(255, 152, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 152, 0, 0.2);
}
.card [data-background-color="red"] {
    background: -webkit-linear-gradient(30deg, #ef5350, #e53935);
    background: -o-linear-gradient(30deg, #ef5350, #e53935);
    background: linear-gradient(60deg, #ef5350, #e53935);
    -webkit-box-shadow: 0 12px 20px -10px rgba(244, 67, 54, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(244, 67, 54, 0.2);
    box-shadow: 0 12px 20px -10px rgba(244, 67, 54, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(244, 67, 54, 0.2);
}
.card [data-background-color="blue"] {
    background: -webkit-linear-gradient(30deg, #26c6da, #00acc1);
    background: -o-linear-gradient(30deg, #26c6da, #00acc1);
    background: linear-gradient(60deg, #26c6da, #00acc1);
    -webkit-box-shadow: 0 12px 20px -10px rgba(0, 188, 212, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 188, 212, 0.2);
    box-shadow: 0 12px 20px -10px rgba(0, 188, 212, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 188, 212, 0.2);
}
.card [data-background-color="green"] {
    background: -webkit-linear-gradient(30deg, #66bb6a, #43a047);
    background: -o-linear-gradient(30deg, #66bb6a, #43a047);
    background: linear-gradient(60deg, #66bb6a, #43a047);
    -webkit-box-shadow: 0 12px 20px -10px rgba(76, 175, 80, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(76, 175, 80, 0.2);
    box-shadow: 0 12px 20px -10px rgba(76, 175, 80, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(76, 175, 80, 0.2);
}
.card [data-background-color="purple"] {
    background: -webkit-linear-gradient(30deg, #ab47bc, #8e24aa);
    background: -o-linear-gradient(30deg, #ab47bc, #8e24aa);
    background: linear-gradient(60deg, #ab47bc, #8e24aa);
    -webkit-box-shadow: 0 12px 20px -10px rgba(156, 39, 176, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(156, 39, 176, 0.2);
    box-shadow: 0 12px 20px -10px rgba(156, 39, 176, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(156, 39, 176, 0.2);
}
.card [data-background-color="maroon"] {
    background: #1a73b3 !important;
    -webkit-box-shadow: #1a73b3;
    box-shadow: #1a73b3;
}
.card [data-background-color="teal"] {
    background: #008080 !important;
    -webkit-box-shadow: #008080;
    box-shadow: #008080;
}
.card .card-header {
    -webkit-box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
    box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
    margin: -20px 15px 0;
    border-radius: 3px;
    padding: 12px;
    background-color: #999999;
}
.card-stats .card-content {
    text-align: right;
    padding-top: 10px;
}
.card .card-content {
    padding: 15px 10px;
}
.card .card-content .category {
    margin-bottom: 0;
}
.card .category:not([class*="text-"]) {
    color: #999999;
}
.card .card-footer {
    margin: 0 20px 10px;
    padding-top: 10px;
    border-top: 1px solid #eeeeee;
}
.card .card-footer .stats {
    line-height: 35px;
    color: #999999;
    font-size: 12px;
}
.card .card-footer div {
    display: inline-block;
}
.panel_toolbox {
    float: right;
    min-width: 70px;
}
.navbar-right {
    margin-right: 0;
}
.panel_toolbox>li>a {
    padding: 0px 5px;
    color: #C5C7CB;
    font-size: 14px;
}
.nav.top_menu>li>a, .nav>li>a {
    position: relative;
    display: block;
}
.card .dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0em;
    vertical-align: 0em;
    content: "";
    border-top: 0em solid;
    border-right: 0em solid transparent;
    border-left: 0em solid transparent;
}
.card .dropdown-menu>.active>a, .card .dropdown-menu>.active>a:focus, .card .dropdown-menu>.active>a:hover {
    color: #fff;
    text-decoration: none;
    background-color: #337ab7;
    outline: 0;
}
body {
    background: #eceff4;
    position: relative;
}
.admin-title .dropdown{
    padding : 5px 10px;
    cursor: pointer;
}
.admin-title .dropdown-menu{
    top:60%;
}
.circle-icon {
    background: #006400;
    color:#fff;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    text-align: center;
    line-height: 30px;
    vertical-align: middle;
    padding: 1px;
    cursor:pointer;
    position: absolute;
    top: 75px;
    right: 25px;
    z-index: 1;
}
.mr-15{
    margin-right: 15px;
}


@media (min-width: 1281px) {
  
    
  }
  
  /* 
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */
  
  @media (min-width: 1025px) and (max-width: 1280px) {
    
    
  }
  
  /* 
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */
  
  @media (min-width: 768px) and (max-width: 1024px) {
    footer.sticky-footer {
        padding: 1rem 0;
        bottom: 0;
        position: static;
        width: 100%;
        padding-left: 0px;
        margin-left: 0px;
    }
    canvas{

        width:auto !important;
        height:600px !important;
      
      }
      .tab-content .pull-right{
        float:left !important;
    }
    
  }
  
  /* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */
  
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    
    footer.sticky-footer {
        padding: 1rem 0;
        bottom: 0;
        position: static;
        width: 100%;
        padding-left: 0px;
        margin-left: 0px;
    }
    canvas{

        width:auto !important;
        height:600px !important;
      
      }
      .tab-content .pull-right{
        float:left !important;
    }
    
  }
  
  /* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */
  
  @media (min-width: 481px) and (max-width: 767px) {
    
    footer.sticky-footer {
        padding: 1rem 0;
        bottom: 0;
        position: static;
        width: 100%;
        padding-left: 0px;
        margin-left: 0px;
    }
    canvas{

        width:auto !important;
        height:600px !important;
      
      }
      .tab-content .pull-right{
        float:left !important;
    }
  }
  
  /* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */
  
  @media (min-width: 320px) and (max-width: 480px) {
    
    footer.sticky-footer {
        padding: 1rem 0;
        bottom: 0;
        position: static;
        width: 100%;
        padding-left: 0px;
        margin-left: 0px;
    }
    canvas{

        width:auto !important;
        height:600px !important;
      
      }
      .tab-content .pull-right{
          float:left !important;
      }
    
  }